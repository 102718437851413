import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./_css/orderCarCharac.module.css";
import clsx from "clsx";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { DoubleArrow, Save } from "@material-ui/icons";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { ORDER_STATUS, ORDER_TYPE, TOrderListingMdl, TUNING_OPTIONS, TUNING_STAGE, USED_GAS } from "./_models/OrderMdl";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { Input } from "_common/ui/forms/Input";
import { userStore } from "users/_stores/userStore";
import { useOrderStore } from "orders/OrderContext";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { getOrderTuningTypeByType } from "_common/_utils/orderUtils";

type Props = {
    onSave: () => void;
    onUpgrade: (tuningStage: TUNING_STAGE) => void;
    isSaving: boolean;
    isReadOnly?: boolean;
    order?: TOrderListingMdl;
    type: ORDER_TYPE;
};

export function OrderReprog(props: Props) {
    const form = useFormContext();
    const { t } = useTranslation();
    const orderStore = useOrderStore();
    const ecuOrTcuTuningKey = props.type === ORDER_TYPE.ECU ? "tuningType" : "tcu.tuningType";
    function hasEcuErrors() {
        return (
            form.watch("tuningType") === "" ||
            form.watch("tuningType") === undefined ||
            !form.watch("ecu") ||
            !form.watch("hw") ||
            !form.watch("sw") ||
            !form.watch("readMethod") ||
            !form.watch("originFile.url")
        );
    }
    function hasTcuErrors() {
        return (
            form.watch("tcu.tuningType") === "" ||
            form.watch("tcu.tuningType") === undefined ||
            !form.watch("tcu.tcu") ||
            !form.watch("tcu.hw") ||
            !form.watch("tcu.sw") ||
            !form.watch("tcu.readMethod") ||
            !form.watch("originFileTcu.url")
        );
    }

    const currentStage = orderStore.item ? getOrderTuningTypeByType(orderStore.item, props.type) : undefined;
    const anotherStageOnVehicle = orderStore.item
        ? orderStore.similarOrders.find((order) => !!getOrderTuningTypeByType(order, props.type))
        : undefined;
    const displayUpgradeBtn =
        form.watch(ecuOrTcuTuningKey) !== TUNING_STAGE.STAGE_2 &&
        !anotherStageOnVehicle &&
        props.order?.status === ORDER_STATUS.CLOSED;
    return (
        <div className={clsx(styles.container, "flex-1")}>
            <h2>
                {t("order.reprog.title")} ({props.type})
            </h2>
            <div>
                <div className={clsx("flex_row flexWrap_wrap", styles.row)}>
                    <div className="flex-1 flex_row_center justifyContent_spaceBetween mr_40">
                        <InputBlock label={t("order.reprog.tuningType")} required>
                            <Controller
                                inputProps={{
                                    readOnly: userStore.isAdmin || userStore.isEngineer ? false : !!props.isReadOnly,
                                }}
                                as={
                                    <UiSelect defaultValue={undefined}>
                                        <MenuItem />
                                        {Object.keys(TUNING_STAGE).map((type, index) => {
                                            return (
                                                <MenuItem key={index} value={type}>
                                                    <div className="flex_row_center">
                                                        {t("order.reprog.tuningTypes." + type)}
                                                    </div>
                                                </MenuItem>
                                            );
                                        })}
                                    </UiSelect>
                                }
                                name={(props.type === ORDER_TYPE.TCU ? "tcu." : "") + "tuningType"}
                                control={form.control}
                            />
                        </InputBlock>
                        {orderStore.item && displayUpgradeBtn && (
                            <UiButton
                                disabled={
                                    (hasEcuErrors() && hasTcuErrors()) ||
                                    Object.keys(form.errors).length > 0 ||
                                    props.isSaving
                                }
                                variant={"contained"}
                                color={"primary"}
                                onClick={() =>
                                    props.onUpgrade(currentStage ? TUNING_STAGE.STAGE_2 : TUNING_STAGE.STAGE_1)
                                }
                            >
                                {t("order.reprog.upgrade")} <DoubleArrow />
                            </UiButton>
                        )}
                        {orderStore.item &&
                            orderStore.getStages(props.type).map((order) => {
                                const tuningType = getOrderTuningTypeByType(order, props.type);
                                return (
                                    <div className={styles.link} key={order._id}>
                                        <Link to={URLS.order(order._id)}>
                                            {t("order.reprog.seeStages")} {t("order.reprog.tuningTypes." + tuningType)}
                                        </Link>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="flex-1">
                        <InputBlock label={t("order.charac.gas")}>
                            <Controller
                                inputProps={{
                                    readOnly: userStore.isAdmin || userStore.isEngineer ? false : !!props.isReadOnly,
                                }}
                                as={
                                    <UiSelect>
                                        {Object.keys(USED_GAS).map((gas, index) => {
                                            return (
                                                <MenuItem key={index} value={gas}>
                                                    <div className="flex_row_center">
                                                        {t("order.charac.usedGas." + gas)}
                                                    </div>
                                                </MenuItem>
                                            );
                                        })}
                                    </UiSelect>
                                }
                                name={"usedGasEcu"}
                                control={form.control}
                            />
                        </InputBlock>
                    </div>
                </div>
                <div className={clsx("flex_row mt_20", styles.row)}>
                    <div className={clsx("flex-1 mr_40", styles.select)}>
                        <InputBlock label={t("order.reprog.tuningOptions")}>
                            <Controller
                                inputProps={{
                                    readOnly: userStore.isAdmin || userStore.isEngineer ? false : !!props.isReadOnly,
                                }}
                                as={
                                    <UiSelect
                                        multiple
                                        renderValue={(options) => {
                                            return (options as TUNING_OPTIONS[])
                                                .map((option) => t("order.reprog.option." + option))
                                                .join(", ");
                                        }}
                                    >
                                        {Object.keys(TUNING_OPTIONS).map((option, index) => {
                                            return (
                                                <MenuItem key={index} value={option}>
                                                    <Checkbox
                                                        checked={form
                                                            .watch(
                                                                (props.type === ORDER_TYPE.TCU ? "tcu." : "") +
                                                                    "tuningOptions",
                                                            )
                                                            .includes(option)}
                                                    />
                                                    <ListItemText primary={t("order.reprog.option." + option)} />
                                                </MenuItem>
                                            );
                                        })}
                                    </UiSelect>
                                }
                                name={(props.type === ORDER_TYPE.TCU ? "tcu." : "") + "tuningOptions"}
                                control={form.control}
                            />
                        </InputBlock>
                    </div>
                    <div className="flex-1">
                        <InputBlock label={t("order.reprog.tuningOptionsMore")}>
                            <Controller
                                readOnly={!userStore.isAdmin && !userStore.isEngineer && props.isReadOnly}
                                as={Input}
                                name={(props.type === ORDER_TYPE.TCU ? "tcu." : "") + "tuningOptionsMore"}
                                control={form.control}
                            />
                        </InputBlock>
                    </div>
                </div>
            </div>
            {!props.isReadOnly && (
                <div className="flex_row mt_20">
                    <div className="flex-1" />
                    <UiButton
                        disabled={
                            (hasEcuErrors() && hasTcuErrors()) || Object.keys(form.errors).length > 0 || props.isSaving
                        }
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => props.onSave()}
                    >
                        {t("words.save")} <Save />
                    </UiButton>
                </div>
            )}
        </div>
    );
}
