import React from "react";
import { TInvoiceMdl } from "invoices/_models/InvoiceMdl";
import styles from "./_css/invoiceHistory.module.css";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type Props = {
    invoice: TInvoiceMdl;
};

export function InvoiceHistory({ invoice }: Props) {
    const { t } = useTranslation();
    if (!invoice.history) return null;

    const getReproOptionTranslationAndPriceFrom = (type: string, price: number) => {
        return `${t("order.reprog.option." + type)} était au prix de ${price}$`;
    };

    return (
        <div className={clsx(styles.container, "mt_20 p_10")}>
            {invoice.history.reverse().map((invoiceHistory, index) => {
                const difference = [];

                if (invoiceHistory.difference.stage) {
                    difference.push(
                        getReproOptionTranslationAndPriceFrom(
                            invoiceHistory.difference.stage.type,
                            invoiceHistory.difference.stage.price,
                        ),
                    );
                }

                if (invoiceHistory.difference.options.length > 0) {
                    invoiceHistory.difference.options.forEach((option) => {
                        difference.push(getReproOptionTranslationAndPriceFrom(option.type, option.price));
                    });
                }

                if (invoiceHistory.difference.stageTcu) {
                    difference.push(
                        getReproOptionTranslationAndPriceFrom(
                            invoiceHistory.difference.stageTcu.type,
                            invoiceHistory.difference.stageTcu.price,
                        ),
                    );
                }

                if (invoiceHistory.difference.optionsTcu.length > 0) {
                    invoiceHistory.difference.optionsTcu.forEach((option) => {
                        difference.push(getReproOptionTranslationAndPriceFrom(option.type, option.price));
                    });
                }

                return (
                    <div key={index} className={"flex_row_center"}>
                        <div className={"flex-1"}>
                            {invoiceHistory.username} a modifié le{" "}
                            {dayjs(invoiceHistory.createdAt).format(t("dates.formats.dayAndHour"))}
                        </div>
                        <div className={"flex-2"}>{difference.join(", ")}</div>
                    </div>
                );
            })}
        </div>
    );
}
