import React from "react";
import clsx from "clsx";
import styles from "./_css/messengerPanelOrderCard.module.css";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { ordersStore } from "orders/_stores/ordersStore";
import { MessengerNotification } from "messages/messenger/MessengerNotification";
import { URLS } from "_configs/URLS";
import { IconComponent } from "components/base/icon/IconComponent";
import { Link } from "react-router-dom";

type Props = {
    orderId: string;
    selectedOrderId: string;
    onClick: () => void;
};

export function MessengerPanelOrderCard(props: Props) {
    const { loading } = useLoadingFromPromise(ordersStore.getAsync(props.orderId));
    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, _error, order) => {
                if (!order) return null;
                return (
                    <div
                        className={clsx(
                            "flex_center_center p_20 cursor_pointer justifyContent_spaceBetween textAlign_center",
                            {
                                [styles.selected]: props.orderId === props.selectedOrderId,
                            },
                        )}
                    >
                        <div onClick={props.onClick}>{order.vin}</div>
                        <div className={"flex-1 flex_center_center m_20 pr_20 pl_20 justifyContent_spaceBetween"}>
                            <Link
                                className={clsx("flex_row_center_center ", styles.content)}
                                to={URLS.order(props.orderId)}
                            >
                                <div className={clsx("flex_row_center", styles.container)}>
                                    <IconComponent icon={"feed"} />
                                </div>
                            </Link>
                            <div className={"justifyContent_flexEnd"}>
                                <MessengerNotification orderId={props.orderId} />
                            </div>
                        </div>
                    </div>
                );
            }}
        />
    );
}
