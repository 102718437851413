import React from "react";
import { FilePicker } from "_common/ui/forms/images/FilePicker";
import { useOrderStore } from "orders/OrderContext";
import { FilePreview } from "_common/ui/forms/images/FilePreview";
import { toast } from "react-toastify";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { observer } from "mobx-react";
import { CloudDownload } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { userStore } from "users/_stores/userStore";
import { getErrorMessage } from "_common/errors/errorUtils";

type Props = {
    resourceKey: string;
    onSelectFile?: (file: string) => void;
    onSave?: () => void;
};

export const OrderFilesEditor = observer((props: Props) => {
    const orderStore = useOrderStore();
    const { setPromise } = useLoadingFromPromise();
    return (
        <FilePicker
            value={[]}
            onChange={(files) => {
                orderStore.addFiles(files, props.resourceKey);
                props.onSave();
            }}
            maxNbFiles={25}
        >
            <hr />
            <div className="flex_row">
                {orderStore.item[props.resourceKey] &&
                    orderStore.item[props.resourceKey].map((file, idx) => {
                        return (
                            <div key={idx}>
                                <FilePreview
                                    url={file.url}
                                    onDelete={
                                        userStore.isAdmin
                                            ? () => {
                                                  if (file.url.startsWith("/static")) {
                                                      const promise = orderStore.deleteFile(idx, props.resourceKey)
                                                          .promise;
                                                      promise?.catch((err) => toast.error(getErrorMessage(err)));
                                                      if (promise) setPromise(promise);
                                                  } else {
                                                      orderStore.deleteFileUploadingState(file.url);
                                                  }
                                                  orderStore.deleteFileUploadingState(file.url);
                                              }
                                            : undefined
                                    }
                                />
                                {props.onSelectFile && (
                                    <div className="flex-1 flex_center_center">
                                        <IconButton
                                            title="Ajouter à la demande en cours"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                props.onSelectFile?.(file.url);
                                                toast.success("A bien été ajouté à la demande en cours");
                                            }}
                                        >
                                            <CloudDownload />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
        </FilePicker>
    );
});
