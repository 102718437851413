import { action, autorun, computed, observable } from "mobx";
import { tokenStore } from "users/_stores/tokenStore";
import { IUserMdl, USER_ROLES } from "users/_models/UserMdl";
import { authStore } from "users/auth/_stores/authStore";
import { fetchUtils, TFilesData } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import _ from "lodash";

class UserStore {
    @observable user: IUserMdl | undefined = undefined;
    @observable isSignInModalOpened = false;
    @observable isModalDisplayed = false;

    private readonly _setDataFromToken = action((_token: string | undefined) => {
        const tokenData = tokenStore.getTokenData<IUserMdl>();
        if (!tokenData) {
            if (this.isLogged) authStore.signOut();
        } else {
            this.user = tokenData;
            analyticsUtils.setUserInfo(this.user.email);
        }
    });

    constructor() {
        autorun(() => {
            const token = tokenStore.token;
            this._setDataFromToken(token);
        });
    }

    @computed get isLogged() {
        return this.user !== undefined;
    }

    @computed get isAdmin() {
        return this.user?.roles.includes(USER_ROLES.ADMIN);
    }
    @computed get isEngineer() {
        return this.user?.roles.includes(USER_ROLES.ENGINEER);
    }

    @action openSignInModal() {
        this.isSignInModalOpened = true;
    }

    @action closeSignInModal() {
        this.isSignInModalOpened = false;
    }

    deleteUser() {
        return fetchUtils.delete(sharedConfig.apiUrl + "/users/" + this.user?._id).then(() => authStore.signOut());
    }

    save(user: IUserMdl, files?: TFilesData) {
        const body = files ? fetchUtils.createBodyWithFiles(user, files) : user;
        return fetchUtils
            .patch(sharedConfig.apiUrl + "/users/" + this.user?._id, _.omit(body, "roles"), !!files)
            .then(({ data }) => {
                tokenStore.refreshToken();
                this.user = data as IUserMdl;
            });
    }

    @action openSignInWithModal() {
        this.isModalDisplayed = true;
    }

    @action closeSignInWithModal() {
        this.isModalDisplayed = false;
    }
}

export const userStore = new UserStore();
