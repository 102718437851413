import React from "react";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { OpPicker, TOpItems } from "admin/_common/filters/OpPicker";
import { MenuItem, TextField } from "@material-ui/core";
import { Toggle } from "_common/ui/forms/Toggle";
import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType, TResourceFilterConfig } from "admin/_common/resources/ResourceFilterMdl";
import { AdminBrandInput } from "_common/filters/AdminBrandInput";
import { AdminModelInput } from "_common/filters/AdminModelInput";

type Props = {
    filter: TFilter;
    filtersConfig: TResourceFilterConfig[];
    onChange: (filter: TFilter) => void;
};

export function AdminFilter(props: Props) {
    const renderInput = () => {
        const filterConfig = props.filtersConfig.find((config) => config.path === props.filter.id);
        if (!filterConfig) return null;
        const value = props.filter.value;
        const basicOnChange = (newValue: any) => props.onChange({ ...props.filter, value: newValue });
        switch (filterConfig.type) {
            case TFilterType.STRING:
                return (
                    <TextField
                        value={value == null ? "" : value}
                        onChange={(event) =>
                            props.onChange({
                                ...props.filter,
                                value: event.target.value,
                            })
                        }
                    />
                );
            case TFilterType.ENUM:
                return (
                    <UiSelect
                        value={value}
                        onChange={(event) =>
                            props.onChange({
                                ...props.filter,
                                value: event.target.value,
                            })
                        }
                    >
                        {filterConfig.enum?.map((enumValue) => (
                            <MenuItem value={enumValue.value} key={enumValue.value}>
                                {enumValue.label ?? enumValue.value}
                            </MenuItem>
                        ))}
                    </UiSelect>
                );
            case TFilterType.DATE:
                return (
                    <>
                        <div className="mr_5">
                            <OpPicker
                                value={props.filter.op}
                                onChange={(newOp: TOpItems) =>
                                    props.onChange({
                                        ...props.filter,
                                        op: newOp,
                                    })
                                }
                            />
                        </div>
                        <div className="adminFilter-datePicker">
                            <TextField
                                id="date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={props.filter.value}
                                onChange={basicOnChange}
                            />
                        </div>
                    </>
                );
            case TFilterType.BOOLEAN:
                return <Toggle value={value} onChange={basicOnChange} />;
            case TFilterType.BRAND:
                return (
                    <AdminBrandInput
                        onChange={(brandId) => {
                            props.onChange({ ...props.filter, value: brandId });
                        }}
                        brandId={props.filter.value}
                    />
                );
            case TFilterType.MODEL:
                return (
                    <AdminModelInput
                        onChange={(modelId) => {
                            props.onChange({ ...props.filter, value: modelId });
                        }}
                        modelId={props.filter.value}
                    />
                );
            case TFilterType.NUMBER:
                return (
                    <>
                        <div className="mr_5">
                            <OpPicker
                                value={props.filter.op}
                                onChange={(newOp) =>
                                    props.onChange({
                                        ...props.filter,
                                        op: newOp,
                                    })
                                }
                            />
                        </div>
                        <TextField
                            type="number"
                            value={value == null ? "" : value + ""}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.filter,
                                    value: parseFloat(event.target.value),
                                })
                            }
                        />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex_row_center">
            <UiSelect
                value={props.filter.id}
                onChange={(event) => {
                    const path = event.target.value as string;
                    const filterConfig = props.filtersConfig.find((config) => config.path === path);
                    if (!filterConfig) return;
                    const newFilter: TFilter = {
                        id: filterConfig.path,
                        type: filterConfig.type,
                    };
                    if (newFilter.type === "date" || newFilter.type === "number") {
                        newFilter.op = "gt";
                    }
                    props.onChange(newFilter);
                }}
            >
                {props.filtersConfig.map((config) => (
                    <MenuItem value={config.path} key={config.path}>
                        {config.label}
                    </MenuItem>
                ))}
            </UiSelect>
            <div className="ml_5 flex_row_center">{renderInput()}</div>
        </div>
    );
}
