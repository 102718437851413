import { ADMIN_PATH } from "admin/_configs/ADMIN_URLS";

class AnalyticsUtils {
    readonly crispPromise: Promise<any> | undefined;

    constructor() {
        if (!__BROWSER__) {
            return;
        }
        // ReactGA.initialize(appConfig.google.analytics, { debug: !sharedConfig.isProd, testMode: !sharedConfig.isProd });
        // this.crispPromise = this.loadCrisp().catch((err) => console.error(err));
    }

    setUserInfo(email: string) {
        this.crispPromise?.then((crisp) => {
            crisp.push(["set", "user:email", email]);
        });
    }

    trackPage(url: string) {
        if (url.startsWith(ADMIN_PATH)) {
            return;
        }

        try {
            // ReactGA.ga("set", "page", url);
            // ReactGA.pageview(url);
        } catch (err) {
            console.error(err);
        }
    }

    private loadCrisp() {
        return new Promise((resolve) => {
            (window as any).$crisp = [];
            (window as any).CRISP_WEBSITE_ID = "800a38aa-a2ef-4812-9651-85ce42c62920";
            (function () {
                const scriptElement = document.createElement("script");
                scriptElement.src = "https://client.crisp.chat/l.js";
                scriptElement.async = true;
                scriptElement.onload = () => resolve((window as any).$crisp);
                document.getElementsByTagName("head")[0].appendChild(scriptElement);
            })();
        });
    }
}

const analyticsUtils = new AnalyticsUtils();
export { analyticsUtils };
