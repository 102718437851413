import React, { useState } from "react";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { InvoiceLine } from "invoices/InvoiceLine";
import { InvoiceBody } from "invoices/InvoiceBody";
import styles from "./_css/invoicesList.module.css";
import { observer } from "mobx-react";

export const InvoicesListPage = observer(() => {
    const invoicesForPeriod = ordersAdminStore.invoices;
    const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>();
    if (!invoicesForPeriod) return null;
    return (
        <div className={styles.content}>
            {invoicesForPeriod.map((invoice) => {
                if (!invoice.invoice) return null;
                return (
                    <div key={invoice._id} className={"mb_40"}>
                        <InvoiceLine
                            invoice={invoice}
                            onSelectInvoice={setSelectedInvoice}
                            selectedInvoice={selectedInvoice}
                        />
                        <InvoiceBody selectedInvoice={selectedInvoice} invoice={invoice} />
                    </div>
                );
            })}
        </div>
    );
});
