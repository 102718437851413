import React from "react";
import { messengerStore } from "messages/messenger/_stores/messengerStore";
import { MessengerPanelOrderCard } from "messages/messenger/MessengerPanelOrderCard";
import styles from "./_css/messengerPanel.module.css";
import clsx from "clsx";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import i18next from "i18next";

type Props = {
    onSelection: (orderId: string) => void;
    selectedOrderId: string;
};

export const MessengerPanel = (props: Props) => {
    const history = useHistory();
    return (
        <div className={clsx(styles.container, "flex-1")}>
            {Object.keys(messengerStore.conversations).map((orderId) => {
                /*const isNotArchive = messengerStore.conversations[orderId].find(
                    (message) => message.status != MESSAGE_STATUS.ARCHIVE,
                );
                if (!isNotArchive) return null;*/
                return (
                    <MessengerPanelOrderCard
                        selectedOrderId={props.selectedOrderId}
                        onClick={() => {
                            props.onSelection(orderId);
                            history.push(URLS.user.messenger(i18next.language, orderId));
                        }}
                        key={orderId}
                        orderId={orderId}
                    />
                );
            })}
        </div>
    );
};
