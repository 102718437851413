import React from "react";
import Header from "main/menu/Header";
import { Footer } from "main/footer/Footer";
import styles from "_css/appContent.module.css";
import clsx from "clsx";
import { PanelObserver } from "main/menu/panel/PanelObserver";
import { NoSsr } from "@material-ui/core";

export const PAGE_REF: { current: HTMLDivElement | null } = { current: null };

export function PageRegular(props: any) {
    return (
        <div ref={(ref) => (PAGE_REF.current = ref)} className={styles.container}>
            <NoSsr>
                <PanelObserver />
            </NoSsr>

            <div className={clsx("flex_column", styles.innerContainer)}>
                <Header />
                <div className={"flex-1"}>{props.children}</div>
                <Footer />
            </div>
        </div>
    );
}
