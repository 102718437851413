import { reaction, toJS } from "mobx";
import { userStore } from "users/_stores/userStore";
import { tokenStore } from "users/_stores/tokenStore";
import { TMessageMdl } from "messages/_models/MessageMdl";
import { messengerStore } from "messages/messenger/_stores/messengerStore";
import { createFilesData } from "_common/_utils/fileUtils";
import sharedConfig from "_configs/sharedConfig";
import { socketConfig } from "_configs/socketConfig";
import io from "socket.io-client";

class SocketStore {
    private socket: SocketIOClient.Socket;
    constructor() {
        this.socket = io(sharedConfig.appUrl, socketConfig);
        this.socket.on("connect", () => {
            reaction(
                () => userStore.isLogged,
                (isLogged) => {
                    if (isLogged) this.socket.emit("user:signIn", { token: tokenStore.token });
                    else this.socket.emit("user:signOut", { token: undefined });
                },
            );
            if (userStore.isLogged) this.socket.emit("user:signIn", { token: tokenStore.token });
        });
        this.init();
    }

    init() {
        this.socket.on("message:read", (payload: any) => this.readMessage(payload));
    }

    sendMessage(message: string, orderId: string, to?: string, files?: { urls: string[]; files: File[] }) {
        if (userStore.isLogged) {
            if (files && files.urls.length > 0) {
                createFilesData(toJS(files.urls), "files.*.url", 3)
                    .then((bodyFiles) => {
                        this.socket.emit("message:create", {
                            message,
                            to,
                            orderId,
                            token: tokenStore.token,
                            files: JSON.stringify(bodyFiles),
                            binaryFiles: toJS(files.files),
                        });
                    })
                    .catch((e) => console.error(e));
            } else {
                this.socket.emit("message:create", { message, to, orderId, token: tokenStore.token });
            }
        }
    }

    readMessage(message: TMessageMdl) {
        messengerStore.addMessage(message);
    }
}

const socketStore = new SocketStore();
export { socketStore };
