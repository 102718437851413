import React from "react";
import { useParams } from "react-router";
import { ordersStore } from "orders/_stores/ordersStore";
import { PageNotFound } from "pages/PageNotFound";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { OrderView } from "orders/OrderView";

export function OrderPage() {
    const { orderId } = useParams();
    const { loading, setPromise } = useLoadingFromPromise(ordersStore.getAsync(orderId, true));
    if (!orderId) return <PageNotFound />;
    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(status, error, value) => {
                if (error || !value) return null;
                return <OrderView onUpdate={setPromise} order={value} />;
            }}
        />
    );
}
