import React, { useEffect } from "react";
import { messengerStore } from "./_stores/messengerStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useParams } from "react-router";
import { MessengerView } from "messages/messenger/MessengerView";
import { userStore } from "users/_stores/userStore";

export function MessengerPage() {
    const { order } = useParams();
    useEffect(() => {
        messengerStore.list();
    }, []);

    if (userStore.isEngineer) return null;
    return (
        <LoadableFromLoading
            loading={messengerStore.conversationsState}
            renderer={() => <MessengerView orderId={order} />}
        />
    );
}
