import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { IUserMdl } from "users/_models/UserMdl";

class AdminUsersStore extends BaseResourceStore<IUserMdl> {
    constructor() {
        super("users");
        if (__BROWSER__) this.onInit();
    }
}

const adminUsersStore = new AdminUsersStore();
export { adminUsersStore };
