import { TDocument } from "_common/types/baseTypes";
import dayjs, { Dayjs } from "dayjs";
import { TBrandMdl, TModelMdl } from "admin/brands/_models/BrandMdl";
import { TInvoiceMdl } from "invoices/_models/InvoiceMdl";

export enum ORDER_TYPE {
    ECU = "ecu",
    TCU = "tcu",
}

export enum ORDER_STATUS {
    IN_PROGRESS = "IN_PROGRESS",
    CLOSED = "CLOSED",
    SAV = "SAV",
}

export enum GARAGE {
    AZM = "AZM",
    ESC = "ESC",
    ITA = "ITA",
    LGS = "LGS",
    TVP = "TVP",
    BGK = "BGK",
}

export enum CAR_SPEEDS {
    MANUAL_6 = "MANUAL_6",
    MANUAL_5 = "MANUAL_5",
    AUTO = "AUTO",
    BOT = "BOT",
}
export enum USED_GAS {
    G87 = "87",
    G89 = "89",
    G91 = "91",
    G93 = "93",
    G94 = "94",
    E100 = "100",
    E30 = "E30",
    E40 = "E40",
    E50 = "E50",
    E85 = "E85",
}

export enum TUNING_STAGE {
    STAGE_1 = "STAGE_1",
    STAGE_2 = "STAGE_2",
    STAGE_3 = "STAGE_3",
}

export enum TUNING_OPTIONS {
    MULTIMAP = "MULTIMAP",
    COLD_START = "COLD_START",
    POP_AND_BANG = "POP_AND_BANG",
    START_AND_STOP = "START_AND_STOP",
    FLAP_CONTROL = "FLAP_CONTROL",
    LAUNCH_CONTROL = "LAUNCH_CONTROL",
    NO_LIFT_SHIFT = "NO_LIFT_SHIFT",
    RUPTOR_HARD = "RUPTOR_HARD",
    ADBLUE = "ADBLUE",
    ADMISSION = "ADMISSION",
    DBM = "DBM",
    BUTTERFLY = "BUTTERFLY",
    SOUND_EFFECT = "SOUND_EFFECT",
    GEAR_BOX_STAGE1 = "GEAR_BOX_STAGE1",
    GEAR_BOX_STAGE2 = "GEAR_BOX_STAGE2",
    UPGRADE = "UPGRADE",
}

type TOrderBaseMdl = TOrderDetails & {
    _id: string;
    motor: string;
    power: string;
    speed: CAR_SPEEDS;
    year: number;

    ownerName: string;
    vin: string;
    ecu: string;
    odometre: string;
    usedGas: string;
    usedGasEcu: string;

    originFile: TDocument;
    azmOriginFile: TDocument;
    azmFiles: TDocument[];
    files: TDocument[];
    savFiles: TDocument[];

    originFileTcu: TDocument;
    azmOriginFileTcu: TDocument;
    azmFilesTcu: TDocument[];
    filesTcu: TDocument[];
    savFilesTcu: TDocument[];

    tcu?: TOrderDetails & { tcu?: string };

    maintenance: {
        maintenance: string;
        oilChange: string;
        ignition: string;
    };

    partner: string;
    garage: GARAGE;
    status: ORDER_STATUS;
    invoice: TInvoiceMdl;

    createdAt: Dayjs;
    updatedAt: Dayjs;
};

export type TOrderMdl = TOrderBaseMdl & {
    brand: string;
    model: string;
};
export type TOrderListingMdl = TOrderBaseMdl & {
    brand: string;
    model: string;
};

export type TOrderReformatMdl = TOrderBaseMdl & {
    brand?: TBrandMdl;
    model?: TModelMdl;
};

export type TOrderDetails = {
    hw?: string;
    sw?: string;

    readMethod?: string;

    savComment?: string;

    tuningType: TUNING_STAGE;
    tuningOptions?: TUNING_OPTIONS[];
    tuningOptionsMore?: string;
};

export const createDefaultOrder = (partnerId?: string): Omit<TOrderListingMdl, "updatedAt" | "createdAt"> => {
    return ({
        brand: "",
        model: "",
        motor: "",
        power: "",
        speed: "",
        year: dayjs().format("YYYY"),

        ownerName: "",
        vin: "",
        carNo: "",

        ecu: "",
        hw: "",
        sw: "",
        odometre: "",
        usedGas: "",
        usedGasEcu: "",
        azmOriginFiles: [],
        azmFiles: [],
        files: [],
        originFile: { url: undefined },
        savFiles: [],
        readMethod: "AT",

        savComment: "",

        tuningType: undefined,
        tuningOptions: [],
        tuningOptionsMore: "",

        azmOriginFilesTcu: [],
        azmFilesTcu: [],
        filesTcu: [],
        originFileTcu: { url: undefined },
        savFilesTcu: [],
        tcu: {
            tcu: "",
            hw: "",
            sw: "",

            readMethod: "AT",
            savComment: "",
            tuningType: undefined,
            tuningOptions: [],
            tuningOptionsMore: "",
        },

        maintenance: {
            maintenance: "",
            oilChange: "",
            ignition: "",
        },

        partner: partnerId,
        status: ORDER_STATUS.IN_PROGRESS,
        invoice: {
            stage: {
                type: "",
                price: "",
            },
            stageTcu: {
                type: "",
                price: "",
            },
            options: [],
            optionsTcu: [],
            comment: "",
        },
    } as unknown) as TOrderListingMdl;
};
