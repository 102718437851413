import { action, observable, reaction } from "mobx";
import { ORDER_TYPE, TOrderListingMdl } from "orders/_models/OrderMdl";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { createFilesData } from "_common/_utils/fileUtils";
import _ from "lodash";
import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import eventsStore from "main/events/eventsStore";
import { TFilesData } from "_common/_utils/fetchUtils";
import { UNKNOWN_ERROR } from "_common/errors/errorUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { getOrderTuningTypeByType } from "_common/_utils/orderUtils";

const FILES_UPLOAD_BATCH_SIZE = 3;

export class OrderStore {
    @observable filesUploadingState: {
        url: string;
        loadingState: LoadingStateMdl<TOrderListingMdl | undefined>;
    }[] = [];
    @observable currentFilesLoadingState: LoadingStateMdl<TOrderListingMdl | undefined> | undefined = undefined;
    @observable item: TOrderListingMdl;
    @observable similarOrdersLoadingState: LoadingStateMdl<TOrderListingMdl[]> = new LoadingStateMdl<
        TOrderListingMdl[]
    >();
    @observable similarOrders: TOrderListingMdl[] = [];
    readonly itemSavingState = new LoadingStateMdl<TOrderListingMdl | undefined>();
    readonly providerStore: BaseResourceStore<TOrderListingMdl> | undefined;
    protected readonly resource: string;
    protected readonly store: BaseResourceStore<TOrderListingMdl>;

    constructor(providerStore: BaseResourceStore<TOrderListingMdl>, resourceName: string, item: TOrderListingMdl) {
        this.resource = resourceName;
        this.store = providerStore;
        this.item = item;
        if (!this.item._id) {
            const disposer = eventsStore.on(`${this.resource}/created`, (event) => {
                this.item = event.payload.item;
                disposer();
            });
        }
        this.fetchSimilarOrders();
    }

    private onNextFilesLoadingState(cb: () => any) {
        if (!this.currentFilesLoadingState) cb();
        else {
            const disposer = reaction(
                () => !this.currentFilesLoadingState,
                () => {
                    if (!this.currentFilesLoadingState) {
                        disposer();
                        cb();
                    }
                },
            );
        }
    }

    @action deleteFileUploadingState(url: string) {
        return this.filesUploadingState.splice(
            this.filesUploadingState.findIndex((uploadingState) => uploadingState.url === url),
            1,
        );
    }

    @action
    private async executeFilesUpload(
        uploadingState: LoadingStateMdl<TOrderListingMdl | undefined>,
        urls: string[],
        resourceKey: string,
    ) {
        try {
            this.currentFilesLoadingState = uploadingState;
            const uploadedFiles = [...(this.item[resourceKey] ?? []), ...urls.map((url) => ({ url }))];
            const filesData = await createFilesData(
                uploadedFiles.map(({ url }) => url),
                `${resourceKey}.*.url`,
            );
            const patchLoadingState = this.patch(
                { [resourceKey]: uploadedFiles } as Partial<TOrderListingMdl>,
                filesData,
            );
            uploadingState.sync(patchLoadingState);
            patchLoadingState.promise
                ?.then(
                    action(() => {
                        for (const url of urls) {
                            this.deleteFileUploadingState(url);
                        }
                    }),
                )
                .finally(() => (this.currentFilesLoadingState = undefined));
        } catch (err) {
            uploadingState.setError(err);
            this.currentFilesLoadingState = undefined;
        }
    }

    @action
    private uploadFilesBatchs(urls: string[], resourcekey: string) {
        const uploadingState = new LoadingStateMdl<TOrderListingMdl | undefined>("LOADING");
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            this.filesUploadingState.push({ url, loadingState: uploadingState });
        }
        this.onNextFilesLoadingState(() => this.executeFilesUpload(uploadingState, urls, resourcekey));
        return uploadingState;
    }

    addFiles(urls: string[], resourceKey: string) {
        const urlsBatchs = _.chunk(urls, FILES_UPLOAD_BATCH_SIZE);
        return urlsBatchs.map((urlsBatch: string[]) => this.uploadFilesBatchs(urlsBatch, resourceKey));
    }

    deleteFile(fileIndex: number, resourcekey: string) {
        const loadingState = new LoadingStateMdl<TOrderListingMdl | undefined>();
        const deletePromise = new Promise<TOrderListingMdl | undefined>((resolve) => {
            this.onNextFilesLoadingState(() => {
                const updatedFiles = [...(this.item[resourcekey] ?? [])];
                updatedFiles.splice(fileIndex, 1);
                const patchLoadingState = this.patch({ [resourcekey]: updatedFiles } as Partial<TOrderListingMdl>);
                loadingState.sync(patchLoadingState);
                resolve(patchLoadingState.promise);
            });
        });
        loadingState.startLoading(deletePromise);
        return loadingState;
    }

    getStages(type: ORDER_TYPE) {
        const similarOrdersCopy = [...this.similarOrders];
        return similarOrdersCopy.filter((order) => {
            const tuningTypeOrder = getOrderTuningTypeByType(order, type);
            return !!tuningTypeOrder;
        });
    }

    fetchSimilarOrders() {
        if (!this.similarOrdersLoadingState.isLoading) {
            this.similarOrdersLoadingState.startLoading();
            const filters = { value: this.item.vin, type: TFilterType.STRING, id: "vin" };
            const promise = this.store.list(undefined, undefined, undefined, undefined, [filters]);

            promise.then(
                action((data) => {
                    this.similarOrders = data.items.filter((order) => this.item._id !== order._id);
                    this.similarOrdersLoadingState.setSuccess(data.items);
                }),
                action((e) => {
                    this.similarOrdersLoadingState.setError(e);
                }),
            );
        }
        return this.similarOrdersLoadingState;
    }

    private patch(patch: Partial<TOrderListingMdl>, files?: TFilesData) {
        if (!this.itemSavingState.isLoading) {
            const isCreate = !this.item._id;
            const request = isCreate
                ? this.store.create(patch, files)
                : this.store.patch({ ...patch, _id: this.item._id }, files);
            const promise = request.then(
                action((savedItem: TOrderListingMdl | undefined) => {
                    if (savedItem) {
                        this.item = savedItem;
                        this.itemSavingState.setSuccess(savedItem);
                        eventsStore.send({
                            type: isCreate ? `${this.resource}/created` : `${this.resource}/updated`,
                            payload: { item: this.item },
                        });
                    } else {
                        this.itemSavingState.setError(UNKNOWN_ERROR);
                    }
                    return savedItem;
                }),
                (err) => {
                    this.itemSavingState.setError(err);
                    return undefined;
                },
            );
            this.itemSavingState.startLoading(promise);
        }
        return this.itemSavingState;
    }
}
