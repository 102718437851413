const isDev = process.env.NODE_ENV === "development";
const isStaging = process.env.IS_STAGING === "true";
const isProd = process.env.NODE_ENV === "production" && !isStaging;

const languages = {
    fr: {
        lang: "fr",
        baseUrl: `${process.env.APP_URL}/fr`,
        basePath: `/fr`,
        title: "Français",
    },
    en: {
        lang: "en",
        baseUrl: `${process.env.APP_URL}/en`,
        basePath: `/en`,
        title: "English",
    },
};

export type TLang = keyof typeof languages;

const sharedConfig = {
    env: process.env.NODE_ENV,
    isDev,
    isStaging,
    isProd,

    emailName: "AZM Pro",
    email: isDev ? "contact@lesentrecodeurs.com" : "info.azmotorsport@gmail.com",

    defaultLang: "fr" as TLang, // langue par défaut, utilisé si des textes ne sont pas présent dans une autre langue
    languages,

    apiUrl: process.env.API_URL as string,
    appUrl: process.env.APP_URL as string,

    sentry: {
        dsn: undefined, // TODO
    },

    uploadsBaseUrl: "/static/uploads/azm-pro",
};

export default sharedConfig;
