import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { createDefaultOrder, TOrderMdl } from "orders/_models/OrderMdl";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { OrderViewForm } from "orders/OrderViewForm";

export enum STEPS {
    INFO = "INFO",
    ECU = "ECU",
    TCU = "TCU",
    UPKEEP = "UPKEEP",
}

export function OrderNewPage() {
    const form = useForm<TOrderMdl>({
        defaultValues: createDefaultOrder(userStore.user?._id),
    });

    const { t } = useTranslation();
    return (
        <div>
            <Container>
                <h1>{t("order.title.new")}</h1>
                <FormProvider {...form}>
                    <OrderViewForm />
                </FormProvider>
            </Container>
        </div>
    );
}
