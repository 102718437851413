import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { ORDER_STATUS, TOrderListingMdl } from "orders/_models/OrderMdl";
import { action, computed, observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import _ from "lodash";

class OrdersStore extends BaseResourceStore<TOrderListingMdl> {
    @observable ordersState = new LoadingStateMdl<TOrderListingMdl[]>();
    @observable orders: TOrderListingMdl[] = [];
    @observable search = "";
    @observable filteredStatus: ORDER_STATUS | undefined = undefined;

    constructor() {
        super("orders");
    }

    @computed get filteredOrders() {
        return this.ordersFilteredByVin
            .filter((order) => order.vin.includes(this.search))
            .filter((order) => !this.filteredStatus || this.filteredStatus === order.status);
    }

    @computed get ordersFilteredByVin() {
        return Object.values(_.groupBy(this.orders, "vin")).map((order) => order[0]);
    }

    @action setSearch = _.debounce((s: string) => {
        this.search = s;
    }, 400);

    @action setStatusFilter = (status: ORDER_STATUS | undefined) => {
        this.filteredStatus = status;
    };

    fetchOrders() {
        if (!this.ordersState.isLoading) {
            this.ordersState.startLoading();
            fetchUtils.get<{ items: TOrderListingMdl[]; count: number }>(`${this.apiPath}/listing`).then(
                action(({ data }) => {
                    const orders = data.items.map((order) => this.reformatItem(order));
                    this.ordersState.setSuccess(orders);
                    this.orders = orders;
                }),
                action((e) => {
                    this.ordersState.setError(e);
                }),
            );
        }
        return this.ordersState;
    }
}

export const ordersStore = new OrdersStore();
