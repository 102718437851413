import React, { useState } from "react";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "shared/_common/i18n/IntlProvider";
import { pagesStore } from "pages/_stores/pagesStore";
import styles from "./_css/langSelector.module.css";
import clsx from "clsx";

async function changeLang(lang: TLang) {
    const oldLang = i18nextInstance.language as TLang;
    if (oldLang === lang) return;
    const langConfig = sharedConfig.languages[lang];
    await i18nextInstance.changeLanguage(lang);
    const { pathname, hash, search } = window.location;
    if (pathname === "/") {
        window.location.href = langConfig.baseUrl;
    } else {
        const page = pagesStore.getByUrlSync(pathname, oldLang);
        if (page?.localized?.[lang]?.url) {
            window.location.href = langConfig.baseUrl + page.localized[lang]?.url + search + hash;
        } else if (!page && pathname.split("/").length >= 3) {
            const oldTranslatedRoute = pathname.split("/")[2];
            const route = Object.entries(i18nextInstance.getDataByLanguage(oldLang).translation.routes).find(
                ([_key, value]) => value === oldTranslatedRoute,
            );
            if (route && route[0]) {
                const arrNewPathname = [...pathname.split("/")];
                arrNewPathname[2] = i18nextInstance.t(`routes.${route[0]}`);
                const newPathname = arrNewPathname.join("/");
                window.location.href =
                    langConfig.baseUrl + newPathname.substr(langConfig.basePath.length) + search + hash;
            } else {
                window.location.href = langConfig.baseUrl;
            }
        } else {
            window.location.href = langConfig.baseUrl;
        }
    }
}

export function LangSelector() {
    const [opened, setOpened] = useState(false);

    return (
        <div
            onMouseEnter={() => setOpened(!opened)}
            onMouseLeave={() => setOpened(!opened)}
            className={styles.container}
        >
            <div className={clsx("flex_center_center", styles.lang)}>{i18nextInstance.language}</div>
            {opened && (
                <div className={styles.languages}>
                    {Object.entries(sharedConfig.languages).map(([lang, { title }]) => (
                        <div
                            className={styles.language}
                            key={lang}
                            onClick={() => {
                                setOpened(false);
                                changeLang(lang as TLang);
                            }}
                        >
                            {title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
