import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import NotFound from "../assets/images/404.svg";
import styles from "./_css/pageNotFound.module.css";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { URLS } from "_configs/URLS";

export function PageNotFound() {
    const { t } = useTranslation();

    return (
        <Container>
            <Breadcrumb currentItem={{ itemLabelKey: "errors.pageNotFound" }} />
            <div className="flex_column_center alignItems_center textAlign_center mv_60">
                <img className={styles.img} src={NotFound} alt={t("errors.pageNotFound")} />
                <div className="mt_20">{t("errors.pageNotFound")}</div>
                <div className="mt_20">
                    <a className={"flex_row_center"} href={URLS.home()} title={t("words.home")}>
                        <i className="icon-chevron-left mr_3" />
                        {t("backToHome")}
                    </a>
                </div>
            </div>
        </Container>
    );
}
