import React from "react";
import clsx from "clsx";
import styles from "orders/_css/orderCarCharac.module.css";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { userStore } from "users/_stores/userStore";
import { Input } from "_common/ui/forms/Input";
import { useTranslation } from "react-i18next";
import { ORDER_TYPE } from "orders/_models/OrderMdl";

type Props = {
    type: ORDER_TYPE;
    isReadOnly: boolean;
};

export function OrderCarDetails(props: Props) {
    const form = useFormContext();
    const { t } = useTranslation();
    return (
        <div>
            <h2>{t("order.ident." + props.type)}</h2>
            <div className={clsx("flex_column mt_20", styles.row)}>
                <div className="flex-1">
                    <InputBlock label={t("order.ident." + props.type)} required>
                        <Controller
                            readOnly={!userStore.isAdmin && !userStore.isEngineer && props.isReadOnly}
                            as={Input}
                            placeholder={
                                props.type === ORDER_TYPE.ECU
                                    ? t("order.ident.ecuPlaceholder")
                                    : t("order.ident.tcuPlaceholder")
                            }
                            name={props.type === ORDER_TYPE.ECU ? "ecu" : "tcu.tcu"}
                            control={form.control}
                        />
                    </InputBlock>
                </div>
                <div className="flex-1">
                    <InputBlock label={t("order.ident.hw")} required>
                        <Controller
                            readOnly={!userStore.isAdmin && !userStore.isEngineer && props.isReadOnly}
                            as={Input}
                            placeholder={t("order.ident.hwPlaceholder")}
                            name={(props.type === ORDER_TYPE.TCU ? "tcu." : "") + "hw"}
                            control={form.control}
                        />
                    </InputBlock>
                </div>
                <div className="flex-1">
                    <InputBlock label={t("order.ident.sw")} required>
                        <Controller
                            readOnly={!userStore.isAdmin && !userStore.isEngineer && props.isReadOnly}
                            as={Input}
                            placeholder={t("order.ident.swPlaceholder")}
                            name={(props.type === ORDER_TYPE.TCU ? "tcu." : "") + "sw"}
                            control={form.control}
                        />
                    </InputBlock>
                </div>
            </div>
        </div>
    );
}
