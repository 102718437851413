import React from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { DialogContent } from "@material-ui/core";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ListStorePaginator } from "_common/ui/paginator/ListStorePaginator";
import { AdminFilters } from "admin/_common/filters/AdminFilters";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { ResourceTable } from "admin/_common/resources/ResourceTable";
import { ADMIN_MODEL_CONFIG_FILTER, ORDER_COLUMNS } from "admin/orders/AdminOrders";

type Props = {
    onSelect: (file: string) => void;
    open: boolean;
    onClose: () => void;
};

export function OrderSearchFilesDialog(props: Props) {
    const listStore = ordersAdminStore.getListStore("orderSearch");
    return (
        <Dialog fullScreen fullWidth closeCross closeSize={"large"} open={props.open} onClose={props.onClose}>
            <DialogContent>
                <AdminFilters listStore={listStore} filtersConfig={ADMIN_MODEL_CONFIG_FILTER} />

                <div className="mt_25">
                    <ListStorePaginator listStore={listStore} />
                </div>
                <LoadableFromLoading
                    loading={listStore.currentLoadingState}
                    renderer={(_status, error) => {
                        if (error) {
                            return <ErrorBlock error={error} />;
                        }
                        return (
                            <ResourceTable
                                minify
                                onSelectFile={props.onSelect}
                                listStore={listStore}
                                columns={ORDER_COLUMNS}
                            />
                        );
                    }}
                />
                <div className="mv_20">
                    <ListStorePaginator listStore={listStore} />
                </div>
            </DialogContent>
        </Dialog>
    );
}
