import React from "react";
import { useLocation } from "react-router";
import { pagesStore } from "pages/_stores/pagesStore";
import { IPageMdl } from "pages/_models/PageMdl";
import { PageRenderer } from "pages/PageRenderer";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PageNotFound } from "pages/PageNotFound";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { HomePage } from "home/HomePage";
import { userStore } from "users/_stores/userStore";
import { AuthPage } from "users/auth/AuthPage";

export function PageRouter() {
    const location = useLocation();
    const path = location.pathname.startsWith("/")
        ? location.pathname
        : location.pathname.substring(0, location.pathname.length - 1);

    const page = pagesStore.getByUrl(path, i18nextInstance.language as TLang);
    const { loading } = useLoadingFromPromise("then" in page ? (page as Promise<IPageMdl>) : undefined);
    if (path === "/" || path.length === 3 || path === "") {
        if (userStore.isLogged) return <HomePage />;
        else return <AuthPage />;
    }
    if ("then" in page) {
        if (!loading) return null;
        return (
            <LoadableFromLoading
                loading={loading}
                renderer={(_status, error, fetchedPage) => {
                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    if (!fetchedPage) {
                        return <PageNotFound />;
                    }
                    return <PageRenderer page={fetchedPage} />;
                }}
            />
        );
    }

    return <PageRenderer page={page} />;
}
