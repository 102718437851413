import React from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { UserInfoForm } from "users/profile/info/userInfo/UserInfoForm";

export function UserInfo() {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = React.useState(false);
    return (
        <div>
            <div className="flex_row_center mb_20">
                <h3 className="flex-1">{t("profile.info")}</h3>
                <div>
                    <UiButton
                        style={isEditing ? { visibility: "hidden" } : undefined}
                        onClick={() => !isEditing && setIsEditing(!isEditing)}
                        variant={"contained"}
                        color={"primary"}
                    >
                        {t("words.edit")}
                    </UiButton>
                </div>
            </div>
            <UserInfoForm isEditing={isEditing} onStopEditing={() => setIsEditing(false)} />
        </div>
    );
}
