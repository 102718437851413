import React, { useState } from "react";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { ORDER_STATUS, TOrderListingMdl, TOrderReformatMdl } from "orders/_models/OrderMdl";
import { OrderAdminListRow } from "orders/admin/OrderAdminListRow";
import { ListStorePaginator } from "_common/ui/paginator/ListStorePaginator";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { toast } from "react-toastify";
import { CopyLinkBtn } from "_common/ui/share/CopyLinkBtn";
import { DialogActions, DialogContent, DialogTitle, MenuItem } from "@material-ui/core";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { ListStore } from "_common/list/ListStore";
import { observer } from "mobx-react";
import { Input } from "_common/ui/forms/Input";
import _ from "lodash";
import { CompanyFilter } from "orders/filters/CompanyFilter";
import clsx from "clsx";
import styles from "./_css/orderListRow.module.css";

const htmlReprog = (selectedOrder: TOrderReformatMdl, translation: UseTranslationResponse): string => {
    return (
        "Marque : " +
        selectedOrder?.brand?.name +
        " <br />" +
        "<br />" +
        "Modèle : " +
        selectedOrder?.model?.name +
        "<br />" +
        "<br />" +
        "Moteur : " +
        selectedOrder?.motor +
        "<br />" +
        "<br />" +
        "Puissance en (Ch) : " +
        selectedOrder?.power +
        "<br />" +
        "<br />" +
        "Boite de vitesse : " +
        selectedOrder?.speed +
        "<br />" +
        "<br />" +
        "Année : " +
        selectedOrder?.year +
        "<br />" +
        "<br />" +
        "Nom du propriétaire : " +
        selectedOrder?.ownerName +
        "<br />" +
        "<br />" +
        "Plaque d'immatriculation : " +
        "<br />" +
        "<br />" +
        "Numéro de châssis (VIN): " +
        selectedOrder?.vin +
        "<br />" +
        "<br />" +
        "Modèle ECU : " +
        selectedOrder?.ecu +
        "<br />" +
        "<br />" +
        "Numéro HW : " +
        selectedOrder?.hw +
        "<br /> " +
        "<br />" +
        "VAG SW : " +
        selectedOrder?.sw +
        "<br />" +
        "<br />" +
        "Méthode de lecture : " +
        selectedOrder?.readMethod?.toString() +
        "<br />" +
        "<br />" +
        "Type de tuning : " +
        selectedOrder?.tuningType +
        "<br />" +
        "<br />" +
        "Tuning Options : " +
        selectedOrder?.tuningOptions
            .map((option) => `<span key="option"> ${translation.t("order.reprog.option." + option)} </span> `)
            .join(", ") +
        "<br />" +
        "<br />" +
        "Autres Options : " +
        selectedOrder.tuningOptionsMore
    );
};

type Props = {
    listStore: ListStore<TOrderListingMdl>;
};

const _OrdersAdminList = observer((props: Props) => {
    const listStore = props.listStore;
    const [selectedOrder, setSelectedOrder] = useState<undefined | TOrderReformatMdl>(undefined);

    const translation = useTranslation();
    const t = translation.t;

    function companyFilter(partnerId: string) {
        const partnerFilterIndex = listStore.filters.findIndex((filter) => filter.id === "partner");
        if (partnerId === "NONE") {
            listStore.removeFilterAndReload(partnerFilterIndex);
        } else {
            listStore.updateFilterAndReload(
                {
                    id: "partner",
                    value: partnerId,
                    type: TFilterType.ID,
                },
                partnerFilterIndex,
            );
        }
    }

    const delayedUpdateFilterAndReload = _.debounce((resource: string, value: string) => {
        const resourceFilterIndex = listStore.filters.findIndex((filter) => filter.id === resource);
        if (value === "" || value === "NONE") {
            listStore.removeFilterAndReload(resourceFilterIndex);
        } else {
            listStore.updateFilterAndReload(
                {
                    id: resource,
                    value: value,
                    type: TFilterType.STRING,
                },
                resourceFilterIndex,
            );
        }
    }, 200);

    const paginatedItems = listStore.paginatedItems.filter((item) => !!item) as TOrderListingMdl[];
    const groupedAndSortOrders = Object.values(_.groupBy(paginatedItems, "vin")).map((orders) => orders[0]);

    return (
        <div className={styles.contentList}>
            <div className={"flex_row justifyContent_spaceBetween"}>
                <div>
                    <UiSelect defaultValue={"NONE"}>
                        <MenuItem
                            onClick={() => {
                                listStore.removeFilterAndReload(
                                    listStore.filters.findIndex((filter) => filter.id === "status"),
                                );
                            }}
                            value={"NONE"}
                        >
                            Tous les statuts
                        </MenuItem>
                        {Object.keys(ORDER_STATUS).map((status) => {
                            return (
                                <MenuItem
                                    onClick={() =>
                                        listStore.updateFilterAndReload(
                                            {
                                                id: "status",
                                                value: status,
                                                type: TFilterType.ENUM,
                                            },
                                            listStore.filters.findIndex((filter) => filter.id === "status"),
                                        )
                                    }
                                    value={status}
                                    key={status}
                                >
                                    {t("order.status." + status)}
                                </MenuItem>
                            );
                        })}
                    </UiSelect>
                </div>
                <div>
                    <CompanyFilter onSelectedPartnerId={(partnerId) => companyFilter(partnerId)} />
                </div>
                <div>
                    <Input placeholder={"#VIN"} onChange={(e) => delayedUpdateFilterAndReload("vin", e.target.value)} />
                </div>
            </div>
            <div className={clsx("mt_20")}>
                {groupedAndSortOrders
                    .sort((order: TOrderListingMdl) =>
                        order.status === ORDER_STATUS.IN_PROGRESS ? -1 : order.status === ORDER_STATUS.SAV ? -1 : 1,
                    )
                    .map((order: TOrderListingMdl) => {
                        return <OrderAdminListRow onSelectOrder={setSelectedOrder} key={order._id} order={order} />;
                    })}
            </div>
            <div className="mt_20">
                <ListStorePaginator listStore={listStore} />
            </div>
            <Dialog maxWidth={"md"} fullWidth open={!!selectedOrder} onClose={() => setSelectedOrder(undefined)}>
                {!!selectedOrder && (
                    <>
                        <DialogTitle>Apercu de la demande</DialogTitle>
                        <DialogContent>
                            <div dangerouslySetInnerHTML={{ __html: htmlReprog(selectedOrder, translation) }} />
                        </DialogContent>
                        <DialogActions>
                            <CopyLinkBtn
                                text={htmlReprog(selectedOrder, translation).replace(/<br \/>/g, "\n")}
                                onCopied={() => toast.info("Copié")}
                            />
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
});

export const OrdersAdminList = () => {
    const { t } = useTranslation();
    const listStore = ordersAdminStore.getListStore(
        "default",
        20,
        undefined,
        // [{ id: "aggregate", type: TFilterType.GROUP_ORDER, value: "order" }],
        { createdAt: -1, status: -1 },
    );
    const listStoreLoadingState = listStore.load(1);

    return (
        <LoadableFromLoading
            loading={listStoreLoadingState}
            renderer={(_status, error) => {
                if (error) return null;
                if (listStore.items.length === 0) return <div>{t("homePage.text")}</div>;
                return <_OrdersAdminList listStore={listStore} />;
            }}
        />
    );
};
