import React from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { authStore } from "users/auth/_stores/authStore";
import { UserInfo } from "users/profile/info/userInfo/UserInfo";
import { useTranslation } from "react-i18next";
import { ResetPasswordBlock } from "users/auth/blocks/ResetPasswordBlock";

export function ProfileInfo() {
    const { t } = useTranslation();
    return (
        <div>
            <h2 className="flex_row_center flexWrap_wrap">
                <div className="mr_15 flex-1">{t("profile.title")}</div>
                <UiButton variant={"outlined"} onClick={() => authStore.signOut()}>
                    {t("profile.logout")}
                </UiButton>
            </h2>
            <div className="mt_25">
                <UserInfo />
            </div>
            <div className="mt_25">
                <ResetPasswordBlock />
            </div>
        </div>
    );
}
