import React, { useState } from "react";
import clsx from "clsx";
import styles from "orders/_css/orderCarCharac.module.css";
import { OrderCarDetails } from "orders/OrderCarDetails";
import { OrderCarFiles } from "orders/OrderCarFiles";
import { OrderReprog } from "orders/OrderReprog";
import { toast } from "react-toastify";
import { URLS } from "_configs/URLS";
import { userStore } from "users/_stores/userStore";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { ordersStore } from "orders/_stores/ordersStore";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { OrderInProgress } from "orders/OrderInProgress";
import { ORDER_STATUS, ORDER_TYPE, TOrderListingMdl, TOrderMdl, TUNING_STAGE } from "orders/_models/OrderMdl";
import { createFilesData } from "_common/_utils/fileUtils";
import { reformatStringValueOfObject } from "_common/_utils/textUtils";
import { OrderClosed } from "orders/OrderClosed";
import { OrderSav } from "orders/OrderSav";
import { useOrderStore } from "orders/OrderContext";
import { INFOS_FIELDS } from "orders/OrderCarCharac";
import { OrderUpgradeModal } from "orders/OrderUpgradeModal";

type Props = {
    isReadOnly?: boolean;
    type: ORDER_TYPE;
    isPartnerForm?: boolean;
    order?: TOrderListingMdl;
    onUpdate?: (promise: Promise<TOrderListingMdl | undefined>) => void;
};

export function OrderCarTcuEcu(props: Props) {
    const store = userStore.isAdmin || userStore.isEngineer ? ordersAdminStore : ordersStore;
    const form = useFormContext();
    const [isSaving, setIsSaving] = useState(false);
    const [tuningStage, setTuningStage] = useState<TUNING_STAGE>();
    const { t } = useTranslation();
    const history = useHistory();
    const orderStore = useOrderStore();

    const originFileName = props.type === ORDER_TYPE.ECU ? "originFile" : "originFileTcu";

    return (
        <div className={clsx(styles.container, "p_20 flex-1")}>
            <OrderCarDetails type={props.type} isReadOnly={!!props.isReadOnly} />
            <div className="mt_20">
                <OrderCarFiles isReadOnly={props.isReadOnly} type={props.type} />
            </div>
            <div className="mt_20">
                <OrderReprog
                    isReadOnly={props.isReadOnly}
                    isSaving={isSaving}
                    order={props.order}
                    onSave={form.handleSubmit(async (data) => {
                        if (data._id) {
                            store.patch(data).then(
                                () => {
                                    toast.success(t("order.successfullySent"));
                                    history.push(URLS.home());
                                },
                                () => toast.error(t("words.error")),
                            );
                        } else {
                            setIsSaving(true);
                            const filesData = await createFilesData(
                                (data as TOrderMdl)[originFileName].url,
                                `${originFileName}.url`,
                            );
                            const reformatedData = reformatStringValueOfObject<Omit<Partial<TOrderListingMdl>, "_id">>(
                                data,
                                ["toUpperCase", "noAccents"],
                                ["ownerName", "brand", "model"],
                            );
                            ordersStore.create(reformatedData, filesData).then(
                                () => {
                                    toast.success(t("order.successfullySent"));
                                    history.push(URLS.home());
                                },
                                () => {
                                    toast.error(t("words.error"));
                                    setIsSaving(false);
                                },
                            );
                        }
                    })}
                    onUpgrade={(tuningStage) => setTuningStage(tuningStage)}
                    type={props.type}
                />
            </div>
            {(userStore.isAdmin || userStore.isEngineer) && (
                <div className="mt_20">
                    <OrderInProgress type={props.type} />
                </div>
            )}
            {props.order && props.order.status !== ORDER_STATUS.IN_PROGRESS && (
                <>
                    <div className={"mt_20"}>
                        <OrderClosed type={props.type} />
                    </div>
                    <div className={"mt_20"}>
                        <OrderSav
                            type={props.type}
                            onSave={form.handleSubmit(async (data) => {
                                store.patch({ ...data, status: ORDER_STATUS.SAV }).then(
                                    () => {
                                        if (props.onUpdate) props.onUpdate(store.getAsync(orderStore.item._id, true));
                                        toast.success(t("order.successfullySent"));
                                    },
                                    () => toast.error(t("words.error")),
                                );
                            })}
                        />
                    </div>
                </>
            )}
            <OrderUpgradeModal
                onSuccess={async (file: string) => {
                    if (!tuningStage) return;
                    const filesData = await createFilesData(file, `${originFileName}.url`);
                    const newOrder = {
                        ...form.getValues([...Object.values(INFOS_FIELDS), "partner"]),
                        ...(props.type === ORDER_TYPE.ECU
                            ? { tuningType: tuningStage }
                            : { tcu: { tuningType: tuningStage } }),
                        invoice: {
                            ...props.order?.invoice,
                            stage: {
                                type: props.type === ORDER_TYPE.ECU ? tuningStage : "",
                                price: 0,
                            },
                            stageTcu: {
                                type: props.type === ORDER_TYPE.TCU ? tuningStage : "",
                                price: 0,
                            },
                            comment: props.order?.invoice.comment ?? "",
                            options:
                                props.order?.invoice.options?.map((option) => {
                                    return {
                                        ...option,
                                        alreadyCounted: true,
                                    };
                                }) ?? [],
                            optionsTcu:
                                props.order?.invoice.optionsTcu?.map((option) => {
                                    return {
                                        ...option,
                                        alreadyCounted: true,
                                    };
                                }) ?? [],
                        },
                        status: ORDER_STATUS.IN_PROGRESS,
                    };

                    ordersStore.create(newOrder, filesData).then(
                        (data) => {
                            if (!data) {
                                toast.error(t("words.error"));
                                return;
                            }
                            ordersStore.orders.push(data);
                            toast.success(t("order.successfullySent"));
                            if (data) history.push(URLS.order(data._id));
                        },
                        () => {
                            toast.error(t("words.error"));
                        },
                    );
                }}
                open={!!tuningStage}
                onClose={() => setTuningStage(undefined)}
            />
        </div>
    );
}
