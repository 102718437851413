import React from "react";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./_css/breadcrumb.module.css";
import clsx from "clsx";

export type TBreadcrumbItem = {
    itemLabelKey?: string;
    itemLabel?: string;
    url?: string;
};

type Props = {
    currentItem: TBreadcrumbItem;
    items?: TBreadcrumbItem[];
};

export function Breadcrumb(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container)}>
            <ul className={clsx("container")}>
                <li className="flex_row_center">
                    <Link to={URLS.home()}>{t("words.home")}</Link>
                </li>
                {props.items &&
                    props.items.map((item, idx) => (
                        <li key={idx}>
                            <Link to={item.url}>{item.itemLabel ?? t(item.itemLabelKey ?? "")}</Link>
                        </li>
                    ))}
                <li>{props.currentItem.itemLabel ?? t(props.currentItem.itemLabelKey ?? "")}</li>
            </ul>
        </div>
    );
}
