import { TLang } from "_configs/sharedConfig";
import { GARAGE } from "orders/_models/OrderMdl";

export type IHeardAboutUs = { key: string; other?: string };

export enum USER_STATUS {
    INACTIVE = "INACTIVE",
    ACTIVE = "ACTIVE",
}

export enum USER_ROLES {
    ADMIN = "ADMIN",
    PARTNER = "PARTNER",
    ENGINEER = "ENGINEER",
}

export type IAddressModel = {
    street: string;
    extra: string;
    city: string;
    postalCode: string;
    country: string;
    province: string;
};

export type IUserBaseMdl = {
    _id: string;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    token: string;
    companyName?: string;
    garage?: GARAGE;
    address?: IAddressModel;
    status: USER_STATUS;
    roles: USER_ROLES[];
    lang: TLang;
};

export type IUserMdl = IUserBaseMdl & {
    password: string;
};

export type TUserSummaryMdl = {
    _id: string;
    photo?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    lang: TLang;
};
