import React from "react";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { createDefaultOrder, TOrderReformatMdl, TUNING_OPTIONS } from "orders/_models/OrderMdl";
import { Input } from "_common/ui/forms/Input";
import styles from "./_css/invoicesBody.module.css";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import { Checkbox, MenuItem } from "@material-ui/core";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { observer } from "mobx-react";
import { TInvoiceMdl } from "invoices/_models/InvoiceMdl";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { TextArea } from "_common/ui/forms/TextArea";
import { InvoiceHistory } from "invoices/InvoiceHistory";

type Props = {
    selectedInvoice: string | undefined;
    invoice: TOrderReformatMdl;
};

export const InvoiceBody = observer(({ invoice: order, selectedInvoice: selectedOrderId }: Props) => {
    const { t } = useTranslation();

    const form = useForm<TInvoiceMdl>({
        mode: "onBlur",
        defaultValues: { ...createDefaultOrder().invoice, ...order.invoice },
    });

    if (selectedOrderId !== order._id) return null;
    if (!order.invoice.stage && !order.invoice.stageTcu) return null;
    return (
        <div
            className={clsx("p_20", styles.container, {
                [styles.selectedInvoice]: selectedOrderId === order._id,
            })}
        >
            <FormProvider {...form}>
                <div className={"flex_row justifyContent_spaceBetween"}>
                    <div className={"flex-1"}>
                        <div className={"textAlign_center mb_20"}>ECU</div>
                        {order.invoice?.stage?.type && (
                            <div className={"flex_row"}>
                                <InputBlock className={"mr_10"} label={"Type"}>
                                    <Controller
                                        as={Input}
                                        control={form.control}
                                        name={"invoice.stage.type"}
                                        defaultValue={order.invoice?.stage?.type}
                                        disabled={true}
                                    />
                                </InputBlock>
                                <InputBlock label={"Prix"}>
                                    <Controller
                                        as={Input}
                                        control={form.control}
                                        name={"invoice.stage.price"}
                                        placeholder={order.invoice?.stage?.price.toString()}
                                        type={"number"}
                                        defaultValue={order.invoice?.stage?.price}
                                    />
                                </InputBlock>
                            </div>
                        )}
                        {!!order.invoice.options?.length && (
                            <>
                                {order.invoice.options.map((option, index) => {
                                    if (!option) return null;
                                    return (
                                        <div className={"flex_row"} key={index + option.type}>
                                            <InputBlock label={"Deja facturé ?"}>
                                                <Controller
                                                    control={form.control}
                                                    name={`invoice.options[${index}].alreadyCounted`}
                                                    defaultValue={order.invoice.options[index].alreadyCounted}
                                                    render={(field) => {
                                                        return (
                                                            <Checkbox
                                                                defaultChecked={
                                                                    order.invoice.options[index].alreadyCounted
                                                                }
                                                                checked={field.value}
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.checked);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </InputBlock>
                                            <InputBlock label={"Prix"}>
                                                <Controller
                                                    as={Input}
                                                    control={form.control}
                                                    name={`invoice.options[${index}].price`}
                                                    type={"number"}
                                                    defaultValue={order.invoice.options[index].price}
                                                />
                                            </InputBlock>
                                            <InputBlock label={"Option"}>
                                                <Controller
                                                    as={
                                                        <UiSelect
                                                            className={"mt_10 mb_20 p_10"}
                                                            label={"Option"}
                                                            disabled={true}
                                                        >
                                                            <MenuItem value={"select"}>
                                                                <div className="flex_row_center"> Option</div>
                                                            </MenuItem>
                                                            {Object.values(TUNING_OPTIONS).map((option, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={option}>
                                                                        <div className="flex_row_center">
                                                                            {t(`order.reprog.option.${option}`)}
                                                                        </div>
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </UiSelect>
                                                    }
                                                    control={form.control}
                                                    name={`invoice.options[${index}].type`}
                                                    defaultValue={order.invoice.options[index].type}
                                                />
                                            </InputBlock>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                    <div className={"flex-1 ml_10"}>
                        <div className={"textAlign_center mb_20"}>TCU</div>
                        {order.invoice?.stageTcu?.type && (
                            <div className={"flex_row"}>
                                <InputBlock className={"mr_10"} label={"Type"}>
                                    <Controller
                                        as={Input}
                                        control={form.control}
                                        name={"invoice.stageTcu.type"}
                                        defaultValue={order.invoice?.stageTcu?.type}
                                        disabled={true}
                                    />
                                </InputBlock>
                                <InputBlock label={"Prix"}>
                                    <Controller
                                        as={Input}
                                        control={form.control}
                                        name={"invoice.stageTcu.price"}
                                        placeholder={order.invoice?.stageTcu?.price.toString()}
                                        type={"number"}
                                        defaultValue={order.invoice?.stageTcu?.price}
                                    />
                                </InputBlock>
                            </div>
                        )}
                        {!!order?.invoice?.optionsTcu?.length && (
                            <>
                                {order.invoice.optionsTcu.map((option, index) => {
                                    if (!option) return null;
                                    return (
                                        <div className={"flex_row mt_20"} key={index + option.type}>
                                            <InputBlock label={"Deja facturé ?"}>
                                                <Controller
                                                    control={form.control}
                                                    name={`invoice.optionsTcu[${index}].alreadyCounted`}
                                                    defaultValue={order.invoice.optionsTcu[index].alreadyCounted}
                                                    render={(field) => {
                                                        return (
                                                            <Checkbox
                                                                defaultChecked={
                                                                    order.invoice.optionsTcu[index].alreadyCounted
                                                                }
                                                                checked={field.value}
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.checked);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </InputBlock>
                                            <InputBlock label={"Prix"}>
                                                <Controller
                                                    as={Input}
                                                    control={form.control}
                                                    name={`invoice.optionsTcu[${index}].price`}
                                                    type={"number"}
                                                    defaultValue={order.invoice.optionsTcu[index].price}
                                                />
                                            </InputBlock>
                                            <InputBlock label={"Option"}>
                                                <Controller
                                                    as={
                                                        <UiSelect
                                                            className={"mt_10 mb_20 p_10"}
                                                            label={"Option"}
                                                            disabled={true}
                                                        >
                                                            <MenuItem value={"select"}>
                                                                <div className="flex_row_center"> Option</div>
                                                            </MenuItem>
                                                            {Object.values(TUNING_OPTIONS).map((option, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={option}>
                                                                        <div className="flex_row_center">
                                                                            {t(`order.reprog.option.${option}`)}
                                                                        </div>
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </UiSelect>
                                                    }
                                                    control={form.control}
                                                    name={`invoice.optionsTcu[${index}].type`}
                                                    defaultValue={order.invoice.optionsTcu[index].type}
                                                />
                                            </InputBlock>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>
                <InputBlock label={"Comment"}>
                    <Controller
                        as={TextArea}
                        rows={6}
                        control={form.control}
                        name={"invoice.comment"}
                        defaultValue={order.invoice.comment}
                    />
                </InputBlock>
                <div className={"flex_row justifyContent_flexEnd"}>
                    <UiButton
                        className={"mt_20"}
                        onClick={form.handleSubmit<{ invoice: TInvoiceMdl }>(async (data) => {
                            const { invoice } = data;

                            return ordersAdminStore
                                .save(
                                    {
                                        invoice: {
                                            stage: invoice.stage,
                                            stageTcu: invoice.stageTcu,
                                            options: invoice.options,
                                            optionsTcu: invoice.optionsTcu,
                                            comment: invoice.comment,
                                        },
                                        _id: order._id,
                                    },
                                    true,
                                )
                                .then(
                                    () => {
                                        toast.success(t("words.submitChangeInvoice"));
                                    },
                                    (err) => {
                                        toast.error(getErrorMessage(err));
                                    },
                                );
                        })}
                        color={"primary"}
                        variant={"contained"}
                        disabled={form.formState.isSubmitting}
                    >
                        {form.formState.isSubmitting ? t("loaders.message") : t("words.submit")}
                    </UiButton>
                </div>
                <InvoiceHistory invoice={order.invoice} />
            </FormProvider>
        </div>
    );
});
