import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { SignInBlock } from "users/auth/blocks/SignInBlock";
import { Redirect } from "react-router";
import { AskResetPasswordBlock } from "users/auth/blocks/AskResetPasswordBloc";
import { ResetPasswordBlock } from "users/auth/blocks/ResetPasswordBlock";
import { PageRegular } from "pages/PageRegular";

export function AuthPage() {
    return (
        <div>
            <Helmet>
                <meta name="robots" content="NOINDEX" />
            </Helmet>
            <PageRegular>
                <div className="flex_center_center pt_20">
                    <Switch>
                        <Route path={URLS.auth.signIn()} component={SignInBlock} />
                        <Route path={URLS.auth.askResetPassword()} component={AskResetPasswordBlock} />
                        <Route path={URLS.auth.resetPassword()} component={ResetPasswordBlock} />
                        <Redirect to={URLS.auth.signIn()} />
                    </Switch>
                </div>
            </PageRegular>
        </div>
    );
}
