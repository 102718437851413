import React from "react";
import { ListStore } from "shared/_common/list/ListStore";
import { observer } from "mobx-react";
import { ErrorBlock } from "shared/_common/errors/ErrorBlock";
import { LoadableFromLoading } from "shared/_common/loaders/LoadableFromLoading";
import { AdminFilters } from "admin/_common/filters/AdminFilters";
import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TResourceFilterConfig } from "admin/_common/resources/ResourceFilterMdl";
import { ResourceTable, TColumn } from "admin/_common/resources/ResourceTable";
import { ListStorePaginator } from "admin/_common/list/ListStorePaginator";
import { Link } from "react-router-dom";
import { TObjWithId } from "_common/types/GenericTypes";

type Props<TResource extends TObjWithId> = {
    resourceStore: BaseResourceStore<TResource>;
    listStore: ListStore<TResource>;
    columns: TColumn<TResource>[];
    filtersConfig?: TResourceFilterConfig[];
    new?: {
        label: string;
        url: string;
    };
    minify?: boolean;
};

function _GenericAdminResourcePanel<TResource extends TObjWithId>(props: Props<TResource>) {
    const loadingState = props.listStore.currentLoadingState;
    if (!loadingState) return <div />;

    return (
        <div>
            {props.new && (
                <div className="mb_20">
                    <Link to={props.new.url}>{props.new.label}</Link>
                </div>
            )}

            <AdminFilters listStore={props.listStore} filtersConfig={props.filtersConfig} />

            <div className="mt_25">
                <ListStorePaginator listStore={props.listStore} />
            </div>
            <LoadableFromLoading
                loading={loadingState}
                renderer={(_status, error) => {
                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    return <ResourceTable minify={props.minify} listStore={props.listStore} columns={props.columns} />;
                }}
            />
            <div className="mv_20">
                <ListStorePaginator listStore={props.listStore} />
            </div>
        </div>
    );
}

export const ResourcePanel = observer(_GenericAdminResourcePanel);
