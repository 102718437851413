import React from "react";
import styles from "./_css/header.module.css";
import { Container, NoSsr } from "@material-ui/core";
import clsx from "clsx";
import { useScrollPosition } from "_common/_utils/hookUtils";
import { PAGE_REF } from "pages/PageRegular";
import { LangSelector } from "main/menu/LangSelector";
import sharedConfig from "_configs/sharedConfig";
import { Logo } from "_common/icons/Logo";
import { Menu } from "main/menu/Menu";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { userStore } from "users/_stores/userStore";

function Header() {
    const scrollPosition = useScrollPosition(PAGE_REF);
    return (
        <header>
            <div>
                <div
                    className={clsx("mui-fixed", styles.wrapper, {
                        [styles.containerScrolling]: scrollPosition > 100,
                    })}
                >
                    <Container>
                        <div
                            className={clsx("flex_row_center flex-1", styles.container, {
                                [styles.containerScrolling]: scrollPosition > 100,
                            })}
                        >
                            <NoSsr
                                fallback={
                                    <div>
                                        <Logo />
                                    </div>
                                }
                            >
                                {userStore.isLogged ? (
                                    <>
                                        <Link className="mt_5 mr_40" to={URLS.home()}>
                                            <Logo />
                                        </Link>
                                        <div className="flex_row_center flex-1 mb_5">
                                            <Menu />
                                            {Object.keys(sharedConfig.languages).length > 1 && <LangSelector />}
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <Logo />
                                    </div>
                                )}
                            </NoSsr>
                        </div>
                    </Container>
                </div>
            </div>
            <div
                className={clsx(styles.placeholder, {
                    [styles.containerScrolling]: scrollPosition > 100,
                })}
            />
        </header>
    );
}

export default Header;
