export function removeAccentFromString(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function reformatStringValueOfObject<T>(
    object: T,
    options: ("noAccents" | "toUpperCase" | "toLowerCase")[],
    excludedKeys?: string[],
): T {
    let reformatedObject: T = object;
    Object.entries(object).map(([key, value]) => {
        if (!excludedKeys?.includes(key) && typeof value === "string") {
            let reformatedValue = value;
            if (options.includes("noAccents")) reformatedValue = removeAccentFromString(reformatedValue);
            if (options.includes("toLowerCase")) reformatedValue = reformatedValue.toLowerCase();
            if (options.includes("toUpperCase")) reformatedValue = reformatedValue.toUpperCase();
            reformatedObject = { ...reformatedObject, [key]: reformatedValue };
        }
    });
    return reformatedObject;
}
