import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TBrandMdl } from "admin/brands/_models/BrandMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";

class BrandsStore extends BaseResourceStore<TBrandMdl> {
    constructor() {
        super("brands");
    }

    getBrandForPartner(brandId: string) {
        return fetchUtils.get<TBrandMdl>(`${this.apiPath}/getBrand/${brandId}`).then(({ data }) => data);
    }
}

export const brandsStore = new BrandsStore();
