import React, { useState } from "react";
import { MessengerPanel } from "messages/messenger/MessengerPanel";
import { MessengerConversation } from "messages/messenger/MessengerConversation";
import { messengerStore } from "messages/messenger/_stores/messengerStore";
import { Container } from "@material-ui/core";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { userStore } from "users/_stores/userStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { ordersStore } from "orders/_stores/ordersStore";
import { TOrderMdl } from "orders/_models/OrderMdl";
import clsx from "clsx";
import styles from "./_css/messengerView.module.css";

type Props = {
    orderId?: string;
};

export function MessengerView(props: Props) {
    const [selectedOrderId, setSelectedOrderId] = useState(
        props.orderId ?? Object.keys(messengerStore.conversations)[0],
    );
    const { loading } = useLoadingFromPromise(ordersStore.getAsync(selectedOrderId));
    if (!loading) return null;
    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, _error, order: TOrderMdl) => {
                if (!order) return null;
                const isPartner = order.partner === userStore.user?._id;
                return (
                    <Container>
                        <div className={clsx("flex_row", styles.container)}>
                            <MessengerPanel
                                selectedOrderId={selectedOrderId}
                                onSelection={(orderId) => setSelectedOrderId(orderId)}
                            />
                            <MessengerConversation isPartner={isPartner} orderId={selectedOrderId} />
                        </div>
                    </Container>
                );
            }}
        />
    );
}
