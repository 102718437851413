import { Dayjs } from "dayjs";
import { TDocument } from "_common/types/baseTypes";

export enum MESSAGE_STATUS {
    NOT_READ = "NOT_READ",
    READ = "READ",
    ARCHIVE = "ARCHIVE",
}

export interface TMessageMdl {
    _id: string;
    status: MESSAGE_STATUS;

    order: string;
    user: string;
    message: string;
    files?: TDocument[];

    createdAt: Dayjs;
    updatedAt: Dayjs;
}
