export enum TFilterType {
    STRING = "string",
    DATE = "date",
    BOOLEAN = "boolean",
    NUMBER = "number",
    ENUM = "enum",
    ID = "_id",
    BRAND = "brand",
    MODEL = "model",
}

export type TResourceFilterConfig = {
    path: string;
    label: string;
    type: TFilterType;
    enum?: { label?: string; value: string }[];
};
