import React from "react";
import { TBrandMdl } from "admin/brands/_models/BrandMdl";

type Props = {
    brand: TBrandMdl;
    model: string;
};

export function OrderAdminModelName(props: Props) {
    const model =
        props.brand.models && props.brand.models.length > 0
            ? props.brand.models.find((model) => model._id === props.model)
            : undefined;
    return (
        <div className={"flex_row"}>
            {props.brand.name} {model ? model.name : ""}
        </div>
    );
}
