import React from "react";
import { ORDER_STATUS } from "orders/_models/OrderMdl";
import { Label } from "_common/ui/labels/Label";
import styles from "./_css/orderStartus.module.css";
import { useTranslation } from "react-i18next";

type Props = {
    status: ORDER_STATUS;
};

export function OrderStatus(props: Props) {
    const { t } = useTranslation();
    return (
        <Label
            className={{
                [styles.inProgress]: props.status === ORDER_STATUS.IN_PROGRESS,
                [styles.closed]: props.status === ORDER_STATUS.CLOSED,
                [styles.sav]: props.status === ORDER_STATUS.SAV,
            }}
        >
            {t("order.status." + props.status)}
        </Label>
    );
}
