import React from "react";
import CookieConsent from "react-cookie-consent";
import i18next from "i18next";

export function Cookie() {
    return (
        <CookieConsent
            location="bottom"
            buttonText="J'accepte"
            cookieName="lecCookie"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px", marginRight: "100px" }}
            expires={150}
        >
            {i18next.t("cookies.text")}
        </CookieConsent>
    );
}
