import React from "react";
import { TOrderMdl } from "orders/_models/OrderMdl";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { FILES_TYPE } from "admin/orders/AdminOrdersFilesActions";
import { DialogContent } from "@material-ui/core";
import { OrderFilesEditor } from "orders/OrderFilesEditor";

type Props = {
    order: TOrderMdl;
    open: boolean;
    filesType: FILES_TYPE;
    onClose: (type: FILES_TYPE) => void;
    onSelectFile?: (file: string) => void;
};

export function AdminOrdersFilesDialog(props: Props) {
    if (!props.order) return null;

    const resourceKey = props.filesType;

    return (
        <Dialog closeSize={"large"} closeCross open={props.open} fullWidth fullScreen onClose={props.onClose}>
            <div className={"flex_row p_20"}>
                <DialogContent>
                    <OrderFilesEditor onSelectFile={props.onSelectFile} resourceKey={resourceKey} />
                </DialogContent>
            </div>
        </Dialog>
    );
}
