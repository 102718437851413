import React from "react";
import { Spinner } from "_common/loaders/Spinner";
import { useTranslation } from "react-i18next";

type Props = {
    text?: string;
};

export function Loader(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="flex_center_center p_20 flex_column">
            <Spinner />
            <div className="mt_40 text_small_semiBold textAlign_center">{props.text ?? t("loaders.message")}</div>
        </div>
    );
}
