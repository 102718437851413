import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./_css/orderCarCharac.module.css";
import clsx from "clsx";
import { Input } from "_common/ui/forms/Input";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { useTranslation } from "react-i18next";
import { CloudDownload } from "@material-ui/icons";
import { SingleFilePicker } from "_common/ui/forms/images/SingleFilePicker";
import { userStore } from "users/_stores/userStore";
import { ORDER_TYPE } from "orders/_models/OrderMdl";

type Props = {
    isReadOnly?: boolean;
    type: ORDER_TYPE;
};

export function OrderCarFiles(props: Props) {
    const form = useFormContext();
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.container, "flex-1")}>
            <h2>
                {t("order.files.title")} ({props.type})
            </h2>
            <div>
                <div className={clsx("flex_row", styles.row)}>
                    <div className="flex-1 mr_40">
                        <InputBlock label={t("order.files.originFile")} required>
                            {props.isReadOnly ? (
                                <a
                                    className={"flex_row_center"}
                                    href={form.watch(
                                        props.type === ORDER_TYPE.TCU ? "originFileTcu.url" : "originFile.url",
                                    )}
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    <div className="mr_5">{t("order.files.download")}</div> <CloudDownload />
                                </a>
                            ) : (
                                <Controller
                                    as={SingleFilePicker}
                                    name={props.type === ORDER_TYPE.TCU ? "originFileTcu.url" : "originFile.url"}
                                    control={form.control}
                                />
                            )}
                        </InputBlock>
                    </div>
                    <div className="flex-1">
                        <InputBlock label={t("order.files.readMethod")} required>
                            <Controller
                                readOnly={
                                    userStore.isAdmin || userStore.isEngineer ? "" : props.isReadOnly ? "readOnly" : ""
                                }
                                // rules={{ required: t<string>("errors.forms.required") }}
                                as={Input}
                                placeholder={t("order.files.readMethod")}
                                name={(props.type === ORDER_TYPE.TCU ? "tcu." : "") + "readMethod"}
                                control={form.control}
                            />
                        </InputBlock>
                    </div>
                </div>
            </div>
        </div>
    );
}
