import React, { useState } from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { observer } from "mobx-react";
import { AdminFilter } from "admin/_common/filters/AdminFilter";
import { TResourceFilterConfig } from "admin/_common/resources/ResourceFilterMdl";
import { ListStore } from "_common/list/ListStore";
import { TFilter } from "admin/_common/filters/TFilter";

type Props = {
    filtersConfig?: TResourceFilterConfig[];
    listStore: ListStore<any>;
};

export const AdminFilters = observer((props: Props) => {
    const filtersConfig = props.filtersConfig;
    if (!filtersConfig || filtersConfig.length === 0) return null;

    const listStore = props.listStore;
    const [showFilters, setShowFilters] = useState(false);
    const filters: TFilter[] =
        listStore.filters.length === 0
            ? [{ id: filtersConfig[0].path, type: filtersConfig[0].type }]
            : listStore.filters;
    const noFilter =
        listStore.filters.length === 0 ||
        (listStore.filters.length === 1 && (!listStore.filters[0] || listStore.filters[0].value === ""));

    return (
        <div>
            <div>
                <UiButton
                    className="mb_10"
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => setShowFilters(!showFilters)}
                >
                    Filtres ({noFilter ? 0 : listStore.filters.length})
                </UiButton>
            </div>
            {showFilters &&
                filters.map((filter, index) => (
                    <div className="flex_row_center">
                        <AdminFilter
                            filtersConfig={filtersConfig}
                            filter={filter}
                            onChange={(newFilter) => listStore.updateFilterAndReload(newFilter, index)}
                        />
                        {!noFilter && (
                            <UiButton onClick={() => listStore.removeFilterAndReload(index)}>Delete</UiButton>
                        )}
                        {index === filters.length - 1 && (
                            <UiButton
                                onClick={() =>
                                    listStore.updateFilterAndReload({
                                        id: filtersConfig[0].path,
                                        type: filtersConfig[0].type,
                                    })
                                }
                            >
                                Add
                            </UiButton>
                        )}
                    </div>
                ))}
        </div>
    );
});
