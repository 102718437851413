import { i18nextInstance } from "_common/i18n/IntlProvider";

export const URLS = {
    home: (lang?: string) => `/${lang || i18nextInstance.language}`,

    auth: {
        base: (lang?: string) => `/${lang || i18nextInstance.language}/auth`,
        signIn: (lang?: string) => `/${lang || i18nextInstance.language}/auth/signIn`,
        askResetPassword: (lang?: string) => `/${lang || i18nextInstance.language}/auth/askResetPassword`,
        resetPassword: (lang?: string) => `/${lang || i18nextInstance.language}/auth/resetPassword`,
    },

    user: {
        profile: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.profile")}`,
        about: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.aboutUs")}`,
        messenger: (lang?: string, order?: string) =>
            `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.messenger")}/${order ? order : ""}`,
    },

    newOrder: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.newOrder")}`,
    order: (orderId: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.order")}/${orderId}`,
    invoices: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.invoices")}`,
    editInvoice: (inVoiceId: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.editInvoice")}/${inVoiceId}`,
    admin: () => "/azm-admin",
};
