import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { TOrderListingMdl } from "orders/_models/OrderMdl";
import { ordersStore } from "orders/_stores/ordersStore";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { userStore } from "users/_stores/userStore";
import { OrderStore } from "orders/_stores/OrderStore";
import { OrderContext } from "orders/OrderContext";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { MessengerIcon } from "messages/messenger/MessengerIcon";
import { settingsStore } from "settings/_stores/settingsStore";
import { OrderViewForm } from "orders/OrderViewForm";

type Props = {
    order: TOrderListingMdl;
    onUpdate: (promise: Promise<TOrderListingMdl | undefined>) => void;
};

export function OrderView(props: Props) {
    const { t } = useTranslation();
    const store = userStore.isAdmin || userStore.isEngineer ? ordersAdminStore : ordersStore;
    const orderStore = new OrderStore(store, "order", props.order);
    const form = useForm<TOrderListingMdl>({ mode: "onBlur", defaultValues: JSON.parse(JSON.stringify(props.order)) });
    orderStore.fetchSimilarOrders();
    return (
        <Container className="mb_40">
            <h1>{t("order.title.edit", { vin: props.order.vin })}</h1>
            <OrderContext orderStore={orderStore}>
                <FormProvider {...form}>
                    <OrderViewForm isReadOnly order={props.order} onUpdate={props.onUpdate} />
                </FormProvider>
                {(userStore.user?._id === settingsStore.getOne()?.userRecipient ||
                    userStore.user?._id === props.order.partner) && <MessengerIcon order={props.order} />}
            </OrderContext>
        </Container>
    );
}
