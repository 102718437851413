import React from "react";
import { useTranslation } from "react-i18next";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ordersStore } from "orders/_stores/ordersStore";
import { OrderListRow } from "orders/OrderListRow";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import { ORDER_STATUS } from "orders/_models/OrderMdl";
import { Input } from "_common/ui/forms/Input";
import { observer } from "mobx-react";

export const _OrdersList = observer(() => {
    const { t } = useTranslation();
    return (
        <div>
            <div className={"flex_row justifyContent_spaceBetween"}>
                <div>
                    <UiSelect defaultValue={"NONE"}>
                        <MenuItem onClick={() => ordersStore.setStatusFilter(undefined)} value={"NONE"}>
                            Tous les statuts
                        </MenuItem>
                        {Object.keys(ORDER_STATUS).map((status) => {
                            return (
                                <MenuItem
                                    onClick={() => ordersStore.setStatusFilter(status as ORDER_STATUS)}
                                    value={status}
                                    key={status}
                                >
                                    {t("order.status." + status)}
                                </MenuItem>
                            );
                        })}
                    </UiSelect>
                </div>
                <div>
                    <Input placeholder={"#VIN"} onChange={(e) => ordersStore.setSearch(e.target.value)} />
                </div>
            </div>
            {ordersStore.ordersFilteredByVin.map((orders) => {
                return <OrderListRow key={orders._id} order={orders} />;
            })}
        </div>
    );
});

export const OrdersList = () => {
    const { t } = useTranslation();
    ordersStore.fetchOrders();
    return (
        <LoadableFromLoading
            loading={ordersStore.ordersState}
            renderer={(_status, error) => {
                if (error) return null;
                if (ordersStore.orders.length === 0) return <div>{t("homePage.text")}</div>;
                return <_OrdersList />;
            }}
        />
    );
};
