import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { userStore } from "users/_stores/userStore";
import { InputBlock } from "_common/ui/forms/InputBlock";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Input } from "_common/ui/forms/Input";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import sharedConfig from "_configs/sharedConfig";
import frImage from "../../../../assets/images/fr.png";
import enImage from "../../../../assets/images/en.png";
import { IUserMdl } from "users/_models/UserMdl";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { getErrorMessage } from "_common/errors/errorUtils";
import styles from "./_css/userInfoForm.module.css";
import clsx from "clsx";

type Props = {
    isEditing?: boolean;
    onStopEditing: () => void;
    cannotCancel?: boolean;
};

export const UserInfoForm = observer((props: Props) => {
    const { t } = useTranslation();
    const form = useForm<IUserMdl>({
        mode: "onBlur",
        defaultValues: userStore.user,
    });

    React.useEffect(() => {
        form.reset(userStore.user);
    }, [userStore.user]);
    return (
        <div className={clsx(styles.container, "p_20")}>
            <FormProvider {...form}>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6}>
                        <InputBlock required label={t("words.email")}>
                            <Controller
                                as={Input}
                                control={form.control}
                                theme={props.isEditing ? undefined : "label"}
                                name={"email"}
                                disabled
                            />
                        </InputBlock>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputBlock label={t("words.companyName")} required>
                            <Controller
                                as={Input}
                                control={form.control}
                                name={"companyName"}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                                theme={props.isEditing ? undefined : "label"}
                                disabled={!props.isEditing || form.formState.isSubmitting}
                                error={form.errors.lastName}
                            />
                        </InputBlock>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputBlock label={t("words.firstName")} required>
                            <Controller
                                as={Input}
                                control={form.control}
                                name={"firstName"}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                                theme={props.isEditing ? undefined : "label"}
                                disabled={!props.isEditing || form.formState.isSubmitting}
                                error={form.errors.firstName}
                            />
                        </InputBlock>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputBlock label={t("words.lastName")} required>
                            <Controller
                                as={Input}
                                control={form.control}
                                name={"lastName"}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                                theme={props.isEditing ? undefined : "label"}
                                disabled={!props.isEditing || form.formState.isSubmitting}
                                error={form.errors.lastName}
                            />
                        </InputBlock>
                    </Grid>
                    <Grid item sm={12} md={12}>
                        <InputBlock label={t("words.phone")} required>
                            <Controller
                                as={Input}
                                control={form.control}
                                name={"phone"}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                                theme={props.isEditing ? undefined : "label"}
                                disabled={!props.isEditing || form.formState.isSubmitting}
                                error={form.errors.phone}
                            />
                        </InputBlock>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputBlock label={t("profile.lang")}>
                            <Controller
                                control={form.control}
                                name={"lang"}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                                defaultValue={userStore.user?.lang ?? sharedConfig.defaultLang}
                                as={
                                    <UiSelect disabled={!props.isEditing || form.formState.isSubmitting}>
                                        {Object.entries(sharedConfig.languages).map(([itemKey, item], index) => {
                                            return (
                                                <MenuItem key={index} value={itemKey}>
                                                    <div className="flex_row_center">
                                                        <img
                                                            className={"mr_10"}
                                                            height={24}
                                                            src={itemKey === "fr" ? frImage : enImage}
                                                            alt={itemKey}
                                                        />
                                                        {item.title}
                                                    </div>
                                                </MenuItem>
                                            );
                                        })}
                                    </UiSelect>
                                }
                            />
                        </InputBlock>
                    </Grid>
                </Grid>
                {props.isEditing && (
                    <div className="mt_20 flex_row">
                        {!props.cannotCancel && (
                            <UiButton
                                onClick={() => {
                                    props.onStopEditing();
                                    form.reset(userStore.user);
                                }}
                                variant={"outlined"}
                                color={"secondary"}
                                className="mr_10"
                                disabled={form.formState.isSubmitting}
                            >
                                {t("words.cancel")}
                            </UiButton>
                        )}
                        <UiButton
                            onClick={form.handleSubmit(async (data) => {
                                return userStore
                                    .save({
                                        ...userStore.user,
                                        ...data,
                                    } as IUserMdl)
                                    .then(
                                        () => {
                                            toast.success(t("profile.success"));
                                            props.onStopEditing();
                                        },
                                        (err) => {
                                            toast.error(getErrorMessage(err));
                                        },
                                    );
                            })}
                            color={"primary"}
                            variant={"contained"}
                            disabled={form.formState.isSubmitting}
                        >
                            {form.formState.isSubmitting ? t("loaders.message") : t("words.submit")}
                        </UiButton>
                    </div>
                )}
            </FormProvider>
        </div>
    );
});
