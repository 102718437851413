import React from "react";
import { ResourcePanel } from "admin/_common/resources/ResourcePanel";
import { TColumn } from "admin/_common/resources/ResourceTable";
import { ResourceActions } from "admin/_common/resources/ResourceActions";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { ORDER_TYPE, TOrderMdl } from "orders/_models/OrderMdl";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { TFilterType, TResourceFilterConfig } from "admin/_common/resources/ResourceFilterMdl";
import { AdminOrdersFilesActions } from "admin/orders/AdminOrdersFilesActions";
import { OrderStatus } from "orders/OrderStatus";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { brandsStore } from "admin/brands/_stores/brandsStore";
import { URLS } from "_configs/URLS";

export const ORDER_COLUMNS: TColumn<TOrderMdl>[] = [
    {
        id: "status",
        title: "Statut",
        renderer: (order) => {
            return (
                <div className={"flex_row"}>
                    <OrderStatus status={order.status} />
                </div>
            );
        },
    },
    {
        id: "brand",
        title: "Marque",
        renderer: (order) => {
            const brandList = brandsStore.getListStore(undefined, Number.MAX_SAFE_INTEGER, undefined, { name: 1 });
            return (
                <LoadableFromLoading
                    loading={brandList.currentLoadingState}
                    renderer={() => {
                        const objBrand = brandList.items.find((objBrand) => objBrand?._id === order.brand);
                        if (!objBrand) return null;
                        return objBrand.name;
                    }}
                />
            );
        },
    },
    {
        id: "model",
        title: "Modèle",
        renderer: (order) => {
            const brandList = brandsStore.getListStore(undefined, Number.MAX_SAFE_INTEGER, undefined, { name: 1 });
            return (
                <LoadableFromLoading
                    loading={brandList.currentLoadingState}
                    renderer={() => {
                        const objBrand = brandList.items.find((objBrand) => objBrand?._id === order.brand);
                        if (!objBrand) return null;
                        const model = objBrand.models?.find((model) => model._id === order.model);
                        if (!model) return null;
                        return model.name;
                    }}
                />
            );
        },
    },
    {
        id: "year",
        title: "Année",
        renderer: (order) => {
            return order.year;
        },
    },
    {
        id: "motor",
        title: "Moteur",
        renderer: (order) => {
            return order.motor;
        },
    },
    {
        id: "power",
        title: "Puissance",
        renderer: (order) => {
            return order.power;
        },
    },
    {
        id: "vin",
        title: "Chassis",
        renderer: (order) => {
            return order.vin;
        },
    },
    {
        id: "ecu",
        title: "ECU",
        renderer: (order) => {
            return order.ecu;
        },
    },
    {
        id: "tcu.tcu",
        title: "TCU",
        renderer: (order) => {
            return order?.tcu?.tcu ?? "-";
        },
    },
    {
        id: "hw",
        title: "HW",
        renderer: (order) => {
            return (
                <div>
                    {order.hw} - {order?.tcu?.hw ?? ""}
                </div>
            );
        },
    },
    {
        id: "sw",
        title: "SW",
        renderer: (order) => {
            return (
                <div>
                    {order.sw} - {order?.tcu?.sw ?? ""}
                </div>
            );
        },
    },
    {
        id: "files Ecu",
        title: "Fichiers ECU",
        renderer: (order, onSelectFile) => {
            return <AdminOrdersFilesActions onSelectFile={onSelectFile} order={order} type={ORDER_TYPE.ECU} />;
        },
    },
    {
        id: "files Tcu",
        title: "Fichiers TCU",
        renderer: (order, onSelectFile) => {
            return <AdminOrdersFilesActions onSelectFile={onSelectFile} order={order} type={ORDER_TYPE.TCU} />;
        },
    },
    {
        id: "action",
        title: "",
        renderer: (order) => (
            <ResourceActions<TOrderMdl>
                store={ordersAdminStore}
                item={order}
                labelKey={order.vin}
                editUrl={URLS.order}
                canDelete={true}
                newPage
            />
        ),
    },
];

export const ADMIN_MODEL_CONFIG_FILTER: TResourceFilterConfig[] = [
    { path: "brand", label: "Marque", type: TFilterType.BRAND },
    { path: "model", label: "Modèle", type: TFilterType.MODEL },
    { path: "year", label: "Année", type: TFilterType.STRING },
    { path: "motor", label: "Moteur", type: TFilterType.STRING },
    { path: "power", label: "Puissance", type: TFilterType.STRING },
    { path: "vin", label: "Chassis", type: TFilterType.STRING },
    { path: "ecu", label: "ECU", type: TFilterType.STRING },
    { path: "hw", label: "HW", type: TFilterType.STRING },
    { path: "sw", label: "SW", type: TFilterType.STRING },
];

export const AdminOrders = observer(() => {
    const { t } = useTranslation();
    const store = ordersAdminStore;
    const listStore = store.getListStore("admin");
    if (!store || !listStore) return null;
    return (
        <div style={{ width: "100%" }} className="p_25">
            <h1>{t("words.orders")}</h1>
            <div style={{ overflowX: "auto" }}>
                <ResourcePanel<TOrderMdl>
                    minify
                    resourceStore={store}
                    listStore={listStore}
                    columns={ORDER_COLUMNS}
                    filtersConfig={ADMIN_MODEL_CONFIG_FILTER}
                />
            </div>
        </div>
    );
});
