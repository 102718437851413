import React from "react";
import styles from "./_css/footer.module.css";
import { Container } from "@material-ui/core";
import dayjs from "dayjs";
import { settingsStore } from "settings/_stores/settingsStore";
import { IconComponent } from "components/base/icon/IconComponent";
import clsx from "clsx";

export function Footer() {
    const settings = settingsStore.getOne();
    return (
        <div className={clsx(styles.container, "mt_20")}>
            <Container>
                <div className={"flex_center_center p_20"}>
                    <div className={clsx("flex_row", styles.content)}>
                        <div className="mh_20">
                            {dayjs().format("YYYY")} © <a href={"https://www.azmotorsport.ca"}>Az Motor</a>
                        </div>
                        {settings && settings.links.fb && (
                            <div className={clsx("flex_center_center", styles.network)}>
                                <span className={styles.separator}>|</span>
                                <div className="mh_20">
                                    <a href={settings.links.fb}>
                                        <IconComponent icon={"icon-facebook1"} /> facebook
                                    </a>
                                </div>
                            </div>
                        )}
                        {settings && settings.links.ig && (
                            <div className={clsx("flex_center_center", styles.network)}>
                                <span className={styles.separator}>|</span>
                                <div className="mh_20">
                                    <a href={settings.links.ig}>
                                        <IconComponent icon={"icon-instagram1"} /> instagram
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}
