import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./_css/orderCarCharac.module.css";
import clsx from "clsx";
import { Input } from "_common/ui/forms/Input";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { CAR_SPEEDS, TOrderListingMdl, USED_GAS } from "orders/_models/OrderMdl";
import dayjs from "dayjs";
import { ArrowForward } from "@material-ui/icons";
import { userStore } from "users/_stores/userStore";
import _ from "lodash";
import { brandsStore } from "admin/brands/_stores/brandsStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { TModelMdl } from "admin/brands/_models/BrandMdl";
import { adminUsersStore } from "admin/users/_stores/adminUsersStore";
import { USER_ROLES } from "users/_models/UserMdl";

type Props = {
    isReadOnly?: boolean;
    onBlur?: (hasError: boolean) => void;
    isPartnerForm?: boolean;
    order?: TOrderListingMdl;
};

export enum INFOS_FIELDS {
    BRAND = "brand",
    MODEL = "model",
    MOTOR = "motor",
    SPEED = "speed",
    POWER = "power",
    YEAR = "year",
    CARNO = "carNo",
    OWNERNAME = "ownerName",
    VIN = "vin",
    ODOMETRE = "odometre",
    USEDGAS = "usedGas",
    USEDGASECU = "usedGasEcu",
    GARAGE = "garage",
    PARTNER = "partner",
    READMETHOD = "readMethod",
    EUC = "ecu",
    HW = "hw",
    SW = "sw",
    TCU = "tcu.tcu",
    TCUHW = "tcu.hw",
    TCUSW = "tcu.sw",
    TUNINGOPTIONS = "tuningOptions",
    TUNINGOPTIONSMORE = "tuningOptionsMore",
}

export function OrderCarCharac(props: Props) {
    const form = useFormContext();
    const { t } = useTranslation();
    const [models, setModels] = useState<TModelMdl[] | undefined>([]);

    function hasErrors() {
        return (
            form.watch(INFOS_FIELDS.BRAND) === "" ||
            form.watch(INFOS_FIELDS.MODEL) === "" ||
            form.watch(INFOS_FIELDS.MOTOR) === "" ||
            form.watch(INFOS_FIELDS.SPEED) === "" ||
            form.watch(INFOS_FIELDS.POWER) === "" ||
            form.watch(INFOS_FIELDS.YEAR) === "" ||
            form.watch(INFOS_FIELDS.OWNERNAME) === "" ||
            form.watch(INFOS_FIELDS.VIN) === "" ||
            form.watch(INFOS_FIELDS.ODOMETRE) === "" ||
            form.watch(INFOS_FIELDS.USEDGAS) === "" ||
            Object.keys(form.errors).length > 0
        );
    }

    if (props.onBlur) props?.onBlur(hasErrors());
    const brandList = brandsStore.getListStore(undefined, Number.MAX_SAFE_INTEGER, undefined, { name: 1 });
    const isAdminOrEngineer = userStore.isAdmin || userStore.isEngineer;

    const partnersList = adminUsersStore.getListStore(undefined, Number.MAX_SAFE_INTEGER, undefined, { name: 1 });

    const years = _.range(2006, parseInt(dayjs().add(2, "year").format("YYYY"))).reverse();

    useEffect(() => {
        const brand = brandList.items.find((brand) => brand?._id === form.watch("brand"));
        if (brand && brand.models) {
            setModels(brand.models);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.watch("brand")]);
    return (
        <div className={clsx(styles.container, "p_20")}>
            <h2 className={"m_0"}>{t("order.charac.title")}</h2>
            {props.isPartnerForm && <div className={styles.reminder}>{t("order.rules")}</div>}
            <div className={"flex-1 mt_20"}>
                <div className={clsx("flex_row", styles.row)}>
                    <div className="flex-1 mr_40">
                        <InputBlock label={t("order.charac.brand")} required>
                            <LoadableFromLoading
                                loading={brandList.currentLoadingState}
                                renderer={() => {
                                    const brand = brandList.items.find((brand) => brand?._id === form.watch("brand"));
                                    if (brand && brand.models) {
                                        setModels(brand.models);
                                    }
                                    return (
                                        <Controller
                                            rules={{ required: t<string>("errors.forms.required") }}
                                            as={
                                                <UiSelect>
                                                    {brandList.items.map((brand, index) => {
                                                        if (!brand) return null;
                                                        return (
                                                            <MenuItem key={index} value={brand._id}>
                                                                <div className="flex_row_center">{brand.name}</div>
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </UiSelect>
                                            }
                                            placeholder={t("order.charac.brandPlaceholder")}
                                            name={"brand"}
                                            readOnly={isAdminOrEngineer ? "" : props.isReadOnly ? "readOnly" : ""}
                                            control={form.control}
                                        />
                                    );
                                }}
                            />
                        </InputBlock>
                    </div>
                    <div className="flex-1">
                        {models && (
                            <InputBlock label={t("order.charac.model")} required>
                                <Controller
                                    rules={{ required: t<string>("errors.forms.required") }}
                                    as={
                                        <UiSelect>
                                            {models.map((model, index) => {
                                                if (!model) return null;
                                                return (
                                                    <MenuItem key={index} value={model._id}>
                                                        <div className="flex_center_center">
                                                            {model.name} {model.startingProdYear}{" "}
                                                            {model.endingProdYear && (
                                                                <span className={"flex_center_center"}>
                                                                    {" "}
                                                                    <ArrowForward /> {model.endingProdYear}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                );
                                            })}
                                        </UiSelect>
                                    }
                                    placeholder={t("order.charac.modelPlaceholder")}
                                    name={"model"}
                                    readOnly={isAdminOrEngineer ? "" : props.isReadOnly ? "readOnly" : ""}
                                    control={form.control}
                                />
                            </InputBlock>
                        )}
                    </div>
                </div>
                <div className={clsx("flex_row mt_20", styles.row)}>
                    <div className="flex-1 mr_40">
                        <InputBlock label={t("order.charac.year")} required>
                            <Controller
                                inputProps={{
                                    readOnly: isAdminOrEngineer ? false : !!props.isReadOnly,
                                }}
                                rules={{ required: t<string>("errors.forms.required") }}
                                as={
                                    <UiSelect>
                                        {Object.values(years).map((year, index) => {
                                            return (
                                                <MenuItem key={index} value={year}>
                                                    <div className="flex_row_center">{year}</div>
                                                </MenuItem>
                                            );
                                        })}
                                    </UiSelect>
                                }
                                name={"year"}
                                control={form.control}
                            />
                        </InputBlock>
                    </div>
                    <div className="flex-1">
                        <InputBlock label={t("order.charac.motor")} required>
                            <Controller
                                rules={{ required: t<string>("errors.forms.required") }}
                                as={Input}
                                placeholder={t("order.charac.motorPlaceholder")}
                                name={"motor"}
                                readOnly={!userStore.isAdmin && !userStore.isEngineer && props.isReadOnly}
                                control={form.control}
                            />
                        </InputBlock>
                    </div>
                </div>
                <div className={clsx("flex_row mt_20", styles.row)}>
                    <div className="flex-1 mr_40">
                        <InputBlock label={t("order.charac.power")} required>
                            <Controller
                                rules={{ required: t<string>("errors.forms.required") }}
                                as={Input}
                                placeholder={t("order.charac.powerPlaceholder")}
                                name={"power"}
                                readOnly={!userStore.isAdmin && !userStore.isEngineer && props.isReadOnly}
                                control={form.control}
                            />
                        </InputBlock>
                    </div>
                    <div className="flex-1">
                        <InputBlock label={t("order.charac.speed")} required>
                            <Controller
                                rules={{ required: t<string>("errors.forms.required") }}
                                inputProps={{
                                    readOnly: isAdminOrEngineer ? false : !!props.isReadOnly,
                                }}
                                as={
                                    <UiSelect>
                                        {Object.keys(CAR_SPEEDS).map((carSpeed, index) => {
                                            return (
                                                <MenuItem key={index} value={carSpeed}>
                                                    <div className="flex_row_center">
                                                        {t("order.charac.carSpeed." + carSpeed)}
                                                    </div>
                                                </MenuItem>
                                            );
                                        })}
                                    </UiSelect>
                                }
                                name={"speed"}
                                control={form.control}
                            />
                        </InputBlock>
                    </div>
                </div>
                <div>
                    <div className={clsx("flex_row", styles.row)}>
                        <div className="flex-1 mr_40">
                            <InputBlock label={t("order.ident.ownerName")} required>
                                <Controller
                                    readOnly={!userStore.isAdmin && !userStore.isEngineer && props.isReadOnly}
                                    rules={{ required: t<string>("errors.forms.required") }}
                                    as={Input}
                                    placeholder={t("order.ident.ownerNamePlaceholder")}
                                    name={"ownerName"}
                                    control={form.control}
                                />
                            </InputBlock>
                        </div>
                        <div className="flex-1">
                            <InputBlock label={t("order.ident.vin")} required>
                                <Controller
                                    readOnly={!userStore.isAdmin && !userStore.isEngineer && props.isReadOnly}
                                    rules={{
                                        required: t<string>("errors.forms.required"),
                                        minLength: {
                                            value: 17,
                                            message: t("order.ident.vinLength"),
                                        },
                                        maxLength: {
                                            value: 17,
                                            message: t("order.ident.vinLength"),
                                        },
                                    }}
                                    as={Input}
                                    placeholder={t("order.ident.vinPlaceholder")}
                                    name={"vin"}
                                    control={form.control}
                                    error={form.errors.vin}
                                />
                            </InputBlock>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={clsx("flex_row", styles.row)}>
                        <div className="flex-1 mr_40">
                            <InputBlock label={t("order.charac.odometre")} required>
                                <Controller
                                    readOnly={!userStore.isAdmin && !userStore.isEngineer && props.isReadOnly}
                                    rules={{ required: t<string>("errors.forms.required") }}
                                    as={Input}
                                    placeholder={t("order.charac.odometre")}
                                    name={"odometre"}
                                    control={form.control}
                                />
                            </InputBlock>
                        </div>
                        <div className="flex-1">
                            <InputBlock label={t("order.charac.gas")} required>
                                <Controller
                                    rules={{ required: t<string>("errors.forms.required") }}
                                    inputProps={{
                                        readOnly: isAdminOrEngineer ? false : !!props.isReadOnly,
                                    }}
                                    as={
                                        <UiSelect>
                                            {Object.keys(USED_GAS).map((gas, index) => {
                                                return (
                                                    <MenuItem key={index} value={gas}>
                                                        <div className="flex_row_center">
                                                            {t("order.charac.usedGas." + gas)}
                                                        </div>
                                                    </MenuItem>
                                                );
                                            })}
                                        </UiSelect>
                                    }
                                    name={"usedGas"}
                                    control={form.control}
                                />
                            </InputBlock>
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <InputBlock label={t("order.garage")} required>
                        {isAdminOrEngineer ? (
                            <LoadableFromLoading
                                loading={partnersList.currentLoadingState}
                                renderer={() => {
                                    return (
                                        <Controller
                                            rules={{ required: t<string>("errors.forms.required") }}
                                            inputProps={{
                                                readOnly: isAdminOrEngineer ? false : !!props.isReadOnly,
                                            }}
                                            defaultValue={props.order?.partner ?? userStore.user?._id}
                                            as={
                                                <UiSelect disabled={!isAdminOrEngineer}>
                                                    {partnersList.items
                                                        .filter(
                                                            (partner) =>
                                                                partner?.roles.includes(USER_ROLES.PARTNER) ||
                                                                partner?.roles.includes(USER_ROLES.ADMIN),
                                                        )
                                                        .map((partner) => {
                                                            if (!partner) return null;
                                                            return (
                                                                <MenuItem key={partner._id} value={partner?._id}>
                                                                    <div className="flex_row_center">
                                                                        {partner?.garage ?? partner?.companyName}
                                                                    </div>
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </UiSelect>
                                            }
                                            name={"partner"}
                                            control={form.control}
                                        />
                                    );
                                }}
                            />
                        ) : (
                            <Controller
                                rules={{ required: t<string>("errors.forms.required") }}
                                inputProps={{
                                    readOnly: isAdminOrEngineer ? false : !!props.isReadOnly,
                                }}
                                defaultValue={props.order?.partner ?? userStore.user?._id}
                                as={
                                    <UiSelect disabled={!isAdminOrEngineer}>
                                        <MenuItem value={userStore.user?._id}>
                                            <div className="flex_row_center">
                                                {userStore.user?.garage ?? userStore.user?.companyName}
                                            </div>
                                        </MenuItem>
                                    </UiSelect>
                                }
                                name={"partner"}
                                control={form.control}
                            />
                        )}
                    </InputBlock>
                </div>
            </div>
        </div>
    );
}
