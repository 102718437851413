import React from "react";
import { Redirect, Route } from "react-router-dom";
import { userStore } from "users/_stores/userStore";
import { RouteComponentProps, RouteProps } from "react-router";
import { NoSsr } from "@material-ui/core";
import { URLS } from "_configs/URLS";

type Props = RouteProps & { reverse?: boolean };

export const AuthenticatedRoute = ({ reverse, component, ...props }: Props) => {
    return (
        <Route
            {...props}
            component={(componentProps: RouteComponentProps<any>) => {
                if ((component && !reverse && userStore.isLogged) || (reverse && !userStore.isLogged)) {
                    const Component = component as any;
                    if (!reverse) {
                        return (
                            <NoSsr>
                                <Component {...componentProps} />
                            </NoSsr>
                        );
                    } else {
                        return <Component {...componentProps} />;
                    }
                } else if (__BROWSER__) {
                    return (
                        <Redirect
                            to={{
                                pathname: URLS.auth.signIn(),
                                state: { from: props.location },
                            }}
                        />
                    );
                }
                return null;
            }}
        />
    );
};
