import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Input } from "shared/_common/ui/forms/Input";
import { InputBlock } from "shared/_common/ui/forms/InputBlock";
import { useLoadingFromPromise } from "shared/_common/loaders/useLoadingFromPromise";
import { observer } from "mobx-react";
import { authStore } from "users/auth/_stores/authStore";
import { ErrorBlock } from "shared/_common/errors/ErrorBlock";
import { IconButton } from "@material-ui/core";
import { UiButton } from "shared/_common/ui/mui/buttons/UiButton";
import { useLocation } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from "./_css/resetPasswordBlock.module.css";
import clsx from "clsx";
import { userStore } from "users/_stores/userStore";
import sharedConfig from "_configs/sharedConfig";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";

type TFormData = {
    password: string;
    confirmPassword: string;
};

export const ResetPasswordBlock = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { handleSubmit, register, errors, watch } = useForm<TFormData>({
        mode: "onBlur",
    });
    const { loading, setPromise } = useLoadingFromPromise();
    const isLoading = loading?.status === "LOADING";
    const [showPassword, setShowPassword] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);
    const url = sharedConfig.appUrl + location.pathname + location.search.replace("?", "&");
    const searchParams = new URLSearchParams(url);
    const token = searchParams.get("token") ?? (userStore.isLogged ? userStore.user?.token : "");

    const onSubmit = (values: TFormData) => {
        if (typeof token === "string") {
            const promise = authStore.resetPassword(token, values.password);
            setPromise(promise);
            promise.then(() => {
                setIsUpdated(true);
                toast.success(t("auth.forgottenPassword.updated"));
                history.push(URLS.auth.signIn());
            });
        }
    };

    return (
        <div className={clsx(styles.container, "p_20")}>
            {t("auth.forgottenPassword.reset")}
            <hr />
            {!isUpdated && (
                <>
                    <ErrorBlock error={loading?.error} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputBlock label={t("words.password")} text={t("auth.signUp.passwordHint")}>
                            <Input
                                name="password"
                                type={showPassword ? "text" : "password"}
                                ref={register({
                                    required: t<string>("errors.forms.required"),
                                    minLength: {
                                        value: 8,
                                        message: t("auth.signUp.passwordsLength"),
                                    },
                                })}
                                error={errors.password}
                                disabled={isLoading}
                                endIcon={
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size="small"
                                    >
                                        {showPassword ? (
                                            <Visibility fontSize="small" />
                                        ) : (
                                            <VisibilityOff fontSize="small" />
                                        )}
                                    </IconButton>
                                }
                            />
                        </InputBlock>
                        <InputBlock label={t("auth.signUp.passwordConfirmation")}>
                            <Input
                                name="confirmPassword"
                                type={showPassword ? "text" : "password"}
                                ref={register({
                                    required: t<string>("errors.forms.required"),
                                    minLength: {
                                        value: 8,
                                        message: t<string>("auth.signUp.passwordsLength"),
                                    },
                                    validate: (value) =>
                                        value !== watch("password")
                                            ? t<string>("auth.signUp.passwordsMismatch")
                                            : undefined,
                                })}
                                error={errors.confirmPassword}
                                disabled={isLoading}
                                endIcon={
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size="small"
                                    >
                                        {showPassword ? (
                                            <Visibility fontSize="small" />
                                        ) : (
                                            <VisibilityOff fontSize="small" />
                                        )}
                                    </IconButton>
                                }
                            />
                        </InputBlock>
                        <div className="flex_center_center mt_25">
                            <UiButton type="submit" disabled={isLoading} variant="contained" color="primary">
                                {t(isLoading ? "loaders.message" : "auth.resetPassword.title")}
                            </UiButton>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
});
