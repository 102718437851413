import React from "react";
import { messengerStore } from "messages/messenger/_stores/messengerStore";
import styles from "./_css/mesengerNotification.module.css";
import { IconComponent } from "components/base/icon/IconComponent";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { observer } from "mobx-react";
import { MESSAGE_STATUS } from "messages/_models/MessageMdl";
import { userStore } from "users/_stores/userStore";

type Props = {
    orderId: string;
};

export const MessengerNotification = observer((props: Props) => {
    if (!messengerStore.conversations[props.orderId]) return null;

    const messageNotRead = messengerStore.conversations[props.orderId].filter(
        (message) => message.status === MESSAGE_STATUS.NOT_READ && message.user != userStore?.user?._id,
    );

    const notification = messageNotRead.length;
    if (notification == 0) return null;

    return (
        <Link
            className={clsx("flex_row_center_center ", styles.content)}
            to={URLS.user.messenger(i18next.language, props.orderId)}
        >
            <div className={clsx("flex_row_center", styles.container)}>
                {notification}
                <IconComponent icon={"message"} />
            </div>
        </Link>
    );
});
