export const ADMIN_PATH = "/azm-admin";

export const ADMIN_URLS = {
    home: () => ADMIN_PATH,

    users: {
        base: () => `${ADMIN_PATH}/users`,
        new: () => `${ADMIN_PATH}/users/edit`,
        edit: (id: string) => `${ADMIN_PATH}/users/edit/${id}`,
    },

    orders: {
        base: () => `${ADMIN_PATH}/orders`,
    },

    brands: {
        base: () => `${ADMIN_PATH}/brands`,
        new: () => `${ADMIN_PATH}/brands/edit`,
        edit: (id: string) => `${ADMIN_PATH}/brands/edit/${id}`,
    },

    pages: {
        base: () => `${ADMIN_PATH}/pages`,
        new: () => `${ADMIN_PATH}/pages/edit`,
        edit: (id: string) => `${ADMIN_PATH}/pages/edit/${id}`,
    },

    settings: {
        base: () => `${ADMIN_PATH}/settings`,
    },

    menu: {
        base: () => `${ADMIN_PATH}/menu`,
    },
    invoiceSettings: {
        base: () => `${ADMIN_PATH}/invoiceSettings`,
        edit: () => `${ADMIN_PATH}/invoiceSettings/edit`,
    },
};
