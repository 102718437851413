import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import { ORDER_TYPE, TOrderMdl } from "orders/_models/OrderMdl";
import { OrderContext } from "orders/OrderContext";
import { OrderStore } from "orders/_stores/OrderStore";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { AdminOrdersFilesDialog } from "admin/orders/AdminOrdersFilesDialog";

type Props = {
    order: TOrderMdl;
    onSelectFile?: (file: string) => void;
    type: ORDER_TYPE;
};

export type FILES_TYPE = "files" | "azmOriginFiles" | "azmFiles" | "filesTcu" | "azmOriginFilesTcu" | "azmFilesTcu";

export function AdminOrdersFilesActions(props: Props) {
    const [type, setType] = useState<FILES_TYPE | false>(false);
    const orderStore = new OrderStore(ordersAdminStore, "order", props.order);
    return (
        <OrderContext orderStore={orderStore}>
            <div>
                <IconButton
                    title="Fichiers clients"
                    onClick={() => setType(props.type === ORDER_TYPE.TCU ? "filesTcu" : "files")}
                >
                    <AttachFile />
                </IconButton>
                <IconButton
                    title="Fichiers d'origine AZM"
                    onClick={() => setType(props.type === ORDER_TYPE.TCU ? "azmOriginFilesTcu" : "azmOriginFiles")}
                >
                    <AttachFile />
                </IconButton>
                <IconButton
                    title="Fichiers de reprog AZM"
                    onClick={() => setType(props.type === ORDER_TYPE.TCU ? "azmFilesTcu" : "azmFiles")}
                >
                    <AttachFile />
                </IconButton>
                <AdminOrdersFilesDialog
                    filesType={type ?? ("azmFiles" as FILES_TYPE)}
                    open={!!type}
                    onClose={() => setType(false)}
                    onSelectFile={props.onSelectFile}
                    order={props.order}
                />
            </div>
        </OrderContext>
    );
}
