import React from "react";
import { MenuItem } from "@material-ui/core";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { brandsStore } from "admin/brands/_stores/brandsStore";
import { toJS } from "mobx";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";

type Props = {
    onChange: (modelIds: string) => void;
    modelId: string;
};

export function AdminModelInput(props: Props) {
    const ordersStore = ordersAdminStore.getListStore("orderSearch");
    const brandFilter = ordersStore.filters.find((filter) => filter.id === "brand");

    const models = brandsStore
        .getListStore(undefined, Number.MAX_SAFE_INTEGER, undefined, { name: 1 })
        .items.filter((brand) => (brandFilter ? brand?._id === brandFilter?.value : true))
        .map((brand) => {
            return toJS(brand?.models);
        })
        .flat();

    return (
        <div>
            <UiSelect onChange={(e) => props.onChange(e.target.value as string)}>
                {models.map((model) => {
                    if (!model) return null;
                    return (
                        <MenuItem key={model._id} value={model._id}>
                            {model.name + " " + model.startingProdYear}
                        </MenuItem>
                    );
                })}
            </UiSelect>
        </div>
    );
}
