import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { CardContent, CardHeader, Container, Grid } from "@material-ui/core";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { SignInFormBlock } from "users/auth/blocks/SignInFormBlock";
import { userStore } from "users/_stores/userStore";
import { Redirect } from "react-router";
import { ADMIN_URLS } from "admin/_configs/ADMIN_URLS";
import { settingsStore } from "settings/_stores/settingsStore";
import { pagesStore } from "pages/_stores/pagesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import styles from "./_css/signInBlock.module.css";
import { NewsLine } from "components/news/newsCard/NewsLine";

export const SignInBlock = observer(() => {
    const { t } = useTranslation();

    if (userStore.isLogged) {
        return <Redirect to={userStore.isAdmin ? ADMIN_URLS.home() : URLS.home()} />;
    }

    const settings = settingsStore.getOne();

    return (
        <Container>
            <Grid container spacing={2} justify={"center"}>
                <Grid item sm={12} md={6} lg={4}>
                    <div className={styles.container}>
                        <SignInFormBlock />
                        <hr />
                        <CardContent className="flex_center_center flex_column">
                            <div>{t("auth.forgottenPassword.forgotPassword")}</div>
                            <Link to={URLS.auth.askResetPassword()} className="link">
                                {t("auth.forgottenPassword.title")}
                            </Link>
                        </CardContent>
                    </div>
                </Grid>
                {settings && (
                    <Grid item sm={12} md={6} lg={4}>
                        <div className={styles.container}>
                            <CardHeader style={{ textAlign: "center" }} title={t("homePage.headerTitle")} />
                            <CardContent>
                                <div dangerouslySetInnerHTML={{ __html: settings.notConnectedText }} />
                            </CardContent>
                        </div>
                    </Grid>
                )}
                <Grid item sm={12} md={12} lg={4}>
                    <div className={styles.container}>
                        <CardHeader style={{ textAlign: "center" }} title={t("homePage.lastNews")} />
                        <CardContent>
                            <LoadableFromLoading
                                loading={pagesStore.getLatestArticles(5)}
                                renderer={(_status, _error, latestArticles) => {
                                    if (!latestArticles) return null;
                                    return latestArticles.map((article) => {
                                        return <NewsLine key={article.localized.url} article={article} />;
                                    });
                                }}
                            />
                        </CardContent>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
});
