import { useEffect, useState } from "react";
import _ from "lodash";

export function useSSR() {
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    return isFirstRender;
}

export function useScrollPosition(pageRef: { current: any }) {
    const [scrollPosition, setScrollPosition] = useState(pageRef.current?.scrollTop);
    const handleScroll = () => {
        if (__BROWSER__) {
            const position = pageRef.current.scrollTop;
            setScrollPosition(position);
        }
    };

    useEffect(() => {
        if (__BROWSER__) {
            pageRef.current.addEventListener("scroll", handleScroll);
            return () => {
                pageRef.current ? pageRef.current.removeEventListener("scroll", handleScroll) : undefined;
            };
        }
    }, []);

    return scrollPosition;
}

export function useWindowSize(throttle?: number) {
    const [windowSize, setWindowSize] = useState({ width: -1, height: -1 });
    if (__BROWSER__) {
        useEffect(() => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            const listener = _.throttle(
                () =>
                    setWindowSize({
                        width: window.innerWidth,
                        height: window.innerHeight,
                    }),
                throttle ?? 100,
            );
            window.addEventListener("resize", listener);
            return () => window.removeEventListener("resize", listener);
        }, []);
    }
    return windowSize;
}
