import React, { useState } from "react";
import styles from "./_css/orderCarCharac.module.css";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Search } from "@material-ui/icons";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { OrderFilesEditor } from "orders/OrderFilesEditor";
import { observer } from "mobx-react";
import { OrderSearchFilesDialog } from "orders/OrderSearchFilesDialog";
import { useOrderStore } from "orders/OrderContext";
import { ORDER_TYPE } from "orders/_models/OrderMdl";

type Props = {
    type: ORDER_TYPE;
};

export const OrderInProgress = observer((props: Props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const orderStore = useOrderStore();

    return (
        <div className={clsx(styles.container, "flex-1")}>
            <h2>
                {t("order.inProgress.title")} ({props.type})
            </h2>
            <div>
                <div className={clsx("flex_row", styles.row)}>
                    <div className={"flex-1 mr_40"}>
                        <h3>Fichiers non cryptés</h3>
                        <InputBlock label={t("order.inProgress.azmOriginFiles")}>
                            <OrderFilesEditor
                                resourceKey={props.type === ORDER_TYPE.TCU ? "azmOriginFilesTcu" : "azmOriginFiles"}
                            />
                        </InputBlock>
                        <InputBlock label={t("order.inProgress.azmFiles")}>
                            <OrderFilesEditor
                                resourceKey={props.type === ORDER_TYPE.TCU ? "azmFilesTcu" : "azmFiles"}
                            />
                        </InputBlock>
                    </div>
                    <div className={"flex-1"}>
                        <h3>Fichiers cryptés</h3>
                        <div>
                            <InputBlock label={t("order.inProgress.files")} required>
                                <OrderFilesEditor resourceKey={props.type === ORDER_TYPE.TCU ? "filesTcu" : "files"} />
                            </InputBlock>
                        </div>
                        <div className={"mt_20"}>
                            <UiButton onClick={() => setOpen(true)} color={"primary"} variant={"contained"}>
                                <div className="mr_3 flex_row_center">
                                    <Search />
                                </div>
                                {t("order.inProgress.searchFiles")}
                            </UiButton>
                            <OrderSearchFilesDialog
                                onSelect={(file) =>
                                    orderStore.addFiles([file], props.type === ORDER_TYPE.TCU ? "filesTcu" : "files")
                                }
                                open={open}
                                onClose={() => setOpen(false)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
