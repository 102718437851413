import React from "react";
import styles from "./_css/mobileMenu.module.css";
import clsx from "clsx";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { Logo } from "_common/icons/Logo";
import { useWindowSize } from "_common/_utils/hookUtils";
import { MENU_ITEMS } from "main/menu/Menu";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { LangSelector } from "main/menu/LangSelector";
import { userStore } from "users/_stores/userStore";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { Add } from "@material-ui/icons";

type Props = {
    opened: boolean;
    onClose: () => void;
};

export function MobileMenu(props: Props) {
    const menu = MENU_ITEMS;
    const windowsSize = useWindowSize();
    const { t } = useTranslation();
    if (!menu) return null;
    return (
        <div
            className={clsx(styles.container, {
                [styles.opened]: props.opened,
                [styles.fullWidthContainer]: windowsSize.width < 600,
            })}
        >
            <div className={styles.logo}>
                <Logo width={120} textFill="#fff" fill="#222" />
            </div>
            <IconButton className={styles.closeIcon} onClick={props.onClose}>
                <CloseIcon style={{ color: "#FFF" }} fontSize="large" />
            </IconButton>
            {Object.values(MENU_ITEMS).map((menu_item) => (
                <li className={"ph_20 pv_10"} key={menu_item.key} onClick={props.onClose}>
                    <Link to={menu_item.url(i18next.language as TLang)}>{t(menu_item.key)}</Link>
                </li>
            ))}
            {userStore.isLogged ? (
                <li key={"profile"} className={"ph_20 pv_10 position_relative"} onClick={props.onClose}>
                    <Link to={URLS.user.profile()}>{t("menu.profile")}</Link>
                </li>
            ) : (
                <li key={"signIn"} className={"ph_20 pv_10"} onClick={props.onClose}>
                    <Link to={URLS.auth.signIn()}>{t("menu.signIn")}</Link>
                </li>
            )}
        </div>
    );
}
