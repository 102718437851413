import React, { useState } from "react";
import { ORDER_STATUS, TOrderListingMdl, TOrderReformatMdl } from "orders/_models/OrderMdl";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/orderListRow.module.css";
import { OrderStatus } from "orders/OrderStatus";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import IconButton from "@material-ui/core/IconButton";
import { CloudDownload, CloudUpload, TextFormat } from "@material-ui/icons";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { OrderFilesEditor } from "orders/OrderFilesEditor";
import { OrderContext } from "orders/OrderContext";
import { OrderStore } from "orders/_stores/OrderStore";
import { DialogContent, DialogTitle } from "@material-ui/core";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { brandsStore } from "admin/brands/_stores/brandsStore";
import { TBrandMdl } from "admin/brands/_models/BrandMdl";
import { OrderAdminModelName } from "orders/admin/OrderAdminModelName";
import { adminUsersStore } from "admin/users/_stores/adminUsersStore";
import { IUserMdl } from "users/_models/UserMdl";

type Props = {
    order: TOrderListingMdl;
    onSelectOrder: (order: TOrderReformatMdl) => void;
};

export const OrderAdminListRow = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { loading } = useLoadingFromPromise<TBrandMdl | undefined>(brandsStore.getAsync(props.order.brand));
    const { loading: loadingUser } = useLoadingFromPromise<IUserMdl | undefined>(
        adminUsersStore.getAsync(props.order.partner),
    );
    const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
    if (!props.order._id || !loading) return null;

    async function selectOrder() {
        const reformatOrder = await ordersAdminStore.reformatItemForBrandAndModel(props.order);
        props.onSelectOrder(reformatOrder);
    }

    return (
        <div className={clsx("flex_center_center mv_10 p_20", styles.container)}>
            <div
                onClick={() => history.push(URLS.order(props.order._id))}
                className={clsx("flexWrap_wrap", styles.column)}
            >
                <LoadableFromLoading
                    loading={loading}
                    renderer={(_status, error, brand) => {
                        if (error || !brand) return null;
                        return <OrderAdminModelName brand={brand} model={props.order.model} />;
                    }}
                />

                {props.order.tuningType && (
                    <div className={clsx("mr_10")}>
                        <div className={"text_small_semiBold"}>
                            {t("order.reprog.tuningTypes." + props.order.tuningType)} (ECU)
                        </div>
                        {props.order.tuningOptions && props.order.tuningOptions.length > 0 && (
                            <div className={"flex_row text_small lineHeight_1-1"}>
                                {props.order.tuningOptions
                                    .map((option) => t("order.reprog.option." + option))
                                    .join(", ")
                                    .trim()}
                            </div>
                        )}
                    </div>
                )}
                {props.order.tcu?.tuningType && (
                    <div className={clsx("mr_10")}>
                        <div className={"text_small_semiBold"}>
                            {t("order.reprog.tuningTypes." + props.order.tcu.tuningType)} (TCU)
                        </div>
                        {props.order.tcu.tuningOptions && props.order.tcu.tuningOptions.length > 0 && (
                            <div className={"flex_row text_small lineHeight_1-1"}>
                                {props.order.tcu.tuningOptions
                                    .map((option) => t("order.reprog.option." + option))
                                    .join(", ")}
                            </div>
                        )}
                    </div>
                )}
                {props.order.maintenance?.maintenance && (
                    <div className={clsx("mr_10", styles.column)}>
                        <div className={"text_small_semiBold"}>{t("order.maintenance.maintenance")}</div>
                    </div>
                )}
            </div>
            {loadingUser && (
                <div
                    onClick={() => history.push(URLS.order(props.order._id))}
                    className={clsx("text_small_semiBold", styles.column)}
                >
                    <LoadableFromLoading
                        loading={loadingUser}
                        renderer={(_status, error, user) => {
                            if (error || !user) return null;
                            return <div>{user.garage ?? user.companyName}</div>;
                        }}
                    />
                </div>
            )}
            <div
                onClick={() => history.push(URLS.order(props.order._id))}
                className={clsx("text_small_semiBold", styles.column)}
            >
                #{props.order.vin}
            </div>
            <div
                onClick={() => history.push(URLS.order(props.order._id))}
                className={clsx("flex_row_center", styles.smallColumn)}
            >
                <OrderStatus status={props.order.status} />
            </div>
            <div onClick={() => history.push(URLS.order(props.order._id))} className={styles.smallColumn}>
                {props.order.createdAt.format(t("dates.formats.dayAndHour"))}
            </div>
            <div className={clsx(styles.icons, styles.column)}>
                <div onClick={() => setOpenFileUploadDialog(true)}>
                    <IconButton title={"Charger fichier origine crypté"}>
                        <CloudUpload />
                    </IconButton>
                </div>
                {props.order?.originFile?.url && (
                    <div className="">
                        <a href={props.order.originFile.url} target={"_blank"} rel="noreferrer">
                            <IconButton title={"Fichier d'origine"}>
                                <CloudDownload />
                            </IconButton>
                        </a>
                    </div>
                )}
                <IconButton onClick={() => selectOrder()} title="Reprog format text">
                    <TextFormat />
                </IconButton>
                {/*{userStore.isAdmin && userStore?.user?._id === settingsStore.getOne()?.userRecipient && (*/}
                {/*    <div className="flex-1 flex_center_center">*/}
                {/*        <LoadableFromLoading*/}
                {/*            loading={messengerStore.list()}*/}
                {/*            renderer={(_status, error) => {*/}
                {/*                if (messengerStore.conversations[props.order._id] === undefined || error) {*/}
                {/*                    return null;*/}
                {/*                }*/}
                {/*                return <MessengerNotification orderId={props.order._id} />;*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
            <Dialog
                maxWidth={"sm"}
                fullWidth
                open={openFileUploadDialog}
                onClose={() => setOpenFileUploadDialog(false)}
            >
                <OrderContext orderStore={new OrderStore(ordersAdminStore, "order", props.order)}>
                    <DialogTitle>Charger les fichiers de reprogrammation client</DialogTitle>
                    <DialogContent>
                        <div className={"pb_20"}>
                            <OrderFilesEditor
                                onSave={() => {
                                    ordersAdminStore.save({ status: ORDER_STATUS.CLOSED });
                                    setOpenFileUploadDialog(false);
                                }}
                                resourceKey={"files"}
                            />
                        </div>
                    </DialogContent>
                </OrderContext>
            </Dialog>
        </div>
    );
};
