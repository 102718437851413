import React, { useState } from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { SingleFilePicker } from "_common/ui/forms/images/SingleFilePicker";
import { useTranslation } from "react-i18next";

type Props = {
    open: boolean;
    onClose: () => void;
    onSuccess: (file: string) => void;
};

export function OrderUpgradeModal(props: Props) {
    const { t } = useTranslation();
    const [file, setFile] = useState<string>();
    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth keepMounted={false} closeCross>
            <DialogTitle>Upload original file</DialogTitle>
            <DialogContent>
                <SingleFilePicker value={file} onChange={(file) => setFile(file)} />
            </DialogContent>
            <DialogActions>
                <UiButton onClick={props.onClose} variant={"outlined"}>
                    {t("words.cancel")}
                </UiButton>
                <UiButton disabled={!file} onClick={() => props.onSuccess(file)} variant={"contained"}>
                    {t("words.submit")}
                </UiButton>
            </DialogActions>
        </Dialog>
    );
}
