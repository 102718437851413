import React, { Suspense } from "react";
import { Loader } from "_common/loaders/Loader";
import { NoSsr } from "@material-ui/core";

class Suspenser extends React.Component<any, any> {
    render() {
        return (
            <NoSsr>
                <Suspense fallback={<Loader />}>{this.props.children}</Suspense>
            </NoSsr>
        );
    }
}

export default Suspenser;
