import React, { lazy, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "./_css/app.css";
import "./_css/fonts/icomoon.css";
import "./_css/fonts/font.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch } from "react-router-dom";
import { AuthenticatedRoute } from "shared/_common/_utils/AuthenticatedRoute";
import { AuthPage } from "users/auth/AuthPage";
import { StickyContainer } from "react-sticky";
import sharedConfig from "_configs/sharedConfig";
import Suspenser from "_common/loaders/Suspenser";
import { AppContent } from "AppContent";
import favicon from "./assets/images/favicon.png";
import { URLS } from "_configs/URLS";
import { ToastContainer } from "react-toastify";
import { OverlayObserver } from "main/overlay/OverlayObserver";
import { NoSsr } from "@material-ui/core";
import { PageTracker } from "pages/PageTracker";
import { Cookie } from "main/cookieBanner/Cookie";
import { useTranslation } from "react-i18next";
import thumbnail from "./assets/images/thumbnail.jpg";
import { useStaticRendering } from "mobx-react-lite";

const LazyAdminRootPage = lazy(() => import("./admin/AdminRootPage"));

export const App: React.FC = () => {
    const { t } = useTranslation();
    useEffect(() => {
        // @typescript-eslint/no-var-requires
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const WebFont = require("webfontloader");
        WebFont.load({
            google: {
                families: ["Inter:400,600,800", "Material+Icons"],
            },
        });
    }, []);

    useStaticRendering(!__BROWSER__);

    return (
        <div>
            <Helmet defaultTitle={t("meta.title")} link={[{ rel: "icon", type: "image/png", href: favicon }]} _La>
                <meta name="robots" content={sharedConfig.isProd ? "INDEX,FOLLOW" : "NOINDEX,NOFOLLOW"} />
                <meta name="description" content={t("meta.description")} />
                <meta name="image" content={thumbnail} />
                <meta name="og:image" content={thumbnail} />
                <meta name="twitter:image" content={thumbnail} />
            </Helmet>
            <StickyContainer>
                <PageTracker>
                    <Switch>
                        <AuthenticatedRoute path={URLS.auth.base()} reverse={true} component={AuthPage} />
                        <Route
                            path={URLS.admin()}
                            component={() => (
                                <Suspenser>
                                    <LazyAdminRootPage />
                                </Suspenser>
                            )}
                        />
                        <Route path="/" component={AppContent} />
                    </Switch>
                </PageTracker>
            </StickyContainer>
            <NoSsr>
                <Cookie />
                <ToastContainer />
                <OverlayObserver />
            </NoSsr>
        </div>
    );
};
