import React from "react";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./_css/menu.module.css";
import { userStore } from "users/_stores/userStore";
import { NoSsr } from "@material-ui/core";
import clsx from "clsx";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { Add } from "@material-ui/icons";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { USER_ROLES } from "users/_models/UserMdl";
import { MobileMenuBtn } from "main/menu/mobile/MobileMenuBtn";

export const MENU_ITEMS: {
    [menuItem: string]: {
        key: string;
        url: (lang: TLang) => string;
        iconComponent?: string;
        role?: USER_ROLES[] | undefined;
    };
} = {
    newOrder: {
        key: "menu.newOrder",
        url: (lang: TLang) => URLS.newOrder(lang),
        iconComponent: "add",
        role: [USER_ROLES.PARTNER],
    },
    orders: {
        key: "menu.orders",
        url: (lang: TLang) => URLS.home(lang),
    },
    invoices: {
        key: "menu.invoices",
        url: (lang: TLang) => URLS.invoices(lang),
        role: [USER_ROLES.ADMIN, USER_ROLES.ENGINEER],
    },
};

export function Menu() {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles.mobileContainer}>
                <MobileMenuBtn />
            </div>
            <ul className={clsx(styles.ul, "flex_row_center")}>
                {Object.values(MENU_ITEMS).map((menu_item) => {
                    if (menu_item.role) {
                        if (
                            !userStore.user?.roles.includes(menu_item.role[0]) &&
                            !userStore.user?.roles.includes(menu_item.role[1])
                        ) {
                            return null;
                        }
                    }
                    return (
                        <li className={"ph_20 pv_10"} key={menu_item.key}>
                            <Link to={menu_item.url(i18next.language as TLang)}>
                                {menu_item.iconComponent ? (
                                    <div className="flex_row">
                                        <UiButton color={"primary"} variant={"contained"}>
                                            <Add /> <span className="fontWeight_bold">{t(menu_item.key)}</span>
                                        </UiButton>
                                    </div>
                                ) : (
                                    t(menu_item.key)
                                )}
                            </Link>
                        </li>
                    );
                })}
            </ul>
            <div className="flex-1" />
            <ul className={clsx(styles.ul, "pr_40")}>
                <NoSsr>
                    {userStore.isLogged ? (
                        <li key={"profile"} className={"ph_20 pv_10 position_relative"}>
                            <Link to={URLS.user.profile()}>{t("menu.profile")}</Link>
                        </li>
                    ) : (
                        <li key={"signIn"} className={"ph_20 pv_10"}>
                            <Link to={URLS.auth.signIn()}>{t("menu.signIn")}</Link>
                        </li>
                    )}
                </NoSsr>
            </ul>
        </>
    );
}
