import React from "react";
import { IUserMdl } from "users/_models/UserMdl";

type Props = {
    partner: IUserMdl;
};

export function PartnerName(props: Props) {
    return <div>{props.partner?.companyName ?? props.partner?.firstName + " " + props.partner?.lastName}</div>;
}
