import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { ORDER_STATUS, TOrderListingMdl, TOrderReformatMdl, TUNING_STAGE } from "orders/_models/OrderMdl";
import { brandsStore } from "admin/brands/_stores/brandsStore";
import { TModelMdl } from "admin/brands/_models/BrandMdl";
import { computed, observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { Dayjs } from "dayjs";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";

class OrdersAdminStore extends BaseResourceStore<TOrderListingMdl> {
    @observable invoicesState = new LoadingStateMdl();
    @observable invoices: TOrderReformatMdl[] = observable.array();

    constructor() {
        super("orders");
    }

    async fetchInvoicesByPeriod(startDate?: Dayjs, endDate?: Dayjs) {
        if (!this.invoicesState.isLoading) {
            this.invoices = [];
            this.invoicesState.startLoading();
            const results = await this.list(undefined, undefined, undefined, undefined, [
                { id: "status", type: TFilterType.STRING, value: ORDER_STATUS.CLOSED },
                { id: "createdAt", type: TFilterType.DATE, value: startDate, op: "gte" },
                { id: "createdAt", type: TFilterType.DATE, value: endDate, op: "lte" },
            ]);
            for (const item of results.items) {
                this.invoices.push(await this.reformatItemForBrandAndModel(item));
            }
            this.invoicesState.setSuccess();
        }
    }

    async reformatItemForBrandAndModel(item: TOrderListingMdl) {
        const brand = await brandsStore.get(item.brand);
        if (!brand || !brand?.models) return (item as unknown) as TOrderReformatMdl;
        const model = brand.models.find((model: TModelMdl) => model._id === item.model);
        return { ...item, brand, model } as TOrderReformatMdl;
    }

    calcTotalForOneItem(item: TOrderListingMdl | TOrderReformatMdl) {
        let totalEcu = parseInt(String(item.invoice?.stage?.price ?? 0));
        let totalTcu = parseInt(String(item.invoice?.stageTcu?.price ?? 0));
        if (item.invoice?.options?.length) {
            item.invoice.options
                .filter((option) => !option.alreadyCounted)
                .map((option) => (totalEcu = totalEcu + parseInt(String(option?.price))));
        }
        if (item.invoice?.optionsTcu?.length) {
            item.invoice.optionsTcu
                .filter((option) => !option.alreadyCounted)
                .map((option) => (totalTcu = totalTcu + parseInt(String(option?.price))));
        }
        return totalTcu + totalEcu;
    }

    @computed get totalForAllItem() {
        let totalStage = 0;
        let totalOption = 0;
        let totalNumberOption = 0;
        let totalStage1 = 0;
        let totalNumberS1 = 0;
        let totalStage2 = 0;
        let totalNumberS2 = 0;
        let totalStage3 = 0;
        let totalNumberS3 = 0;

        this.invoices.map((order) => {
            const invoice = order.invoice;
            if (invoice && (invoice.stage || invoice.stageTcu)) {
                if (invoice.stage?.price) totalStage = totalStage + invoice.stage.price * 1;
                if (invoice.stageTcu?.price) totalStage = totalStage + invoice.stageTcu.price * 1;
                if (invoice?.stage?.type === TUNING_STAGE.STAGE_1) {
                    totalStage1 = totalStage1 + invoice.stage.price * 1;
                    totalNumberS1++;
                } else if (invoice?.stage?.type === TUNING_STAGE.STAGE_2) {
                    totalStage2 = totalStage2 + invoice.stage.price * 1;
                    totalNumberS2++;
                } else if (invoice?.stage?.type === TUNING_STAGE.STAGE_3) {
                    totalStage3 = totalStage3 + invoice.stage.price;
                    totalNumberS3++;
                }
                if (invoice?.stageTcu?.type === TUNING_STAGE.STAGE_1) {
                    totalStage1 = totalStage1 + invoice.stageTcu.price * 1;
                    totalNumberS1++;
                } else if (invoice?.stageTcu?.type === TUNING_STAGE.STAGE_2) {
                    totalStage2 = totalStage2 + invoice.stageTcu.price * 1;
                    totalNumberS2++;
                } else if (invoice?.stageTcu?.type === TUNING_STAGE.STAGE_3) {
                    totalStage3 = totalStage3 + invoice.stageTcu.price * 1;
                    totalNumberS3++;
                }
            }

            if (invoice.options && invoice.options.length > 0) {
                invoice.options
                    .filter((option) => !option.alreadyCounted)
                    .map((option) => {
                        totalOption = totalOption + option.price * 1;
                        totalNumberOption++;
                    });
            }

            if (invoice.optionsTcu && invoice.optionsTcu.length > 0) {
                invoice.optionsTcu
                    .filter((option) => !option.alreadyCounted)
                    .map((option) => {
                        totalOption = totalOption + option.price * 1;
                        totalNumberOption++;
                    });
            }
        });
        return {
            totalStage1: totalStage1,
            totalNumberS1: totalNumberS1,
            totalStage2: totalStage2,
            totalNumberS2: totalNumberS2,
            totalStage3: totalStage3,
            totalNumberS3: totalNumberS3,
            totalOptions: totalOption,
            totalNumberOption: totalNumberOption,
            totalInvoice: totalStage + totalOption,
            totalNumberInvoice: totalNumberOption + totalNumberS1 + totalNumberS2 + totalNumberS3,
        };
    }

    async save(order: Partial<TOrderListingMdl>, fromInvoice = false) {
        const orderUpdated = await this[fromInvoice ? "updateInvoice" : "patch"](order);
        const orderInvoiceUpdatedIndex = this.invoices.findIndex((invoice) => invoice._id === order._id);
        this.invoices[orderInvoiceUpdatedIndex] = { ...this.invoices[orderInvoiceUpdatedIndex], ...orderUpdated };
        return orderUpdated;
    }

    async updateInvoice(order: Partial<TOrderListingMdl>) {
        return fetchUtils
            .patch<TOrderListingMdl>(this.apiPath + "/updateInvoice/" + order._id, order)
            .then(({ data }) => this.handleItemUpdated(data));
    }
}

export const ordersAdminStore = new OrdersAdminStore();
