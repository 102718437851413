import React, { useState } from "react";
import { messengerStore } from "messages/messenger/_stores/messengerStore";
import styles from "./_css/messengerConversation.module.css";
import clsx from "clsx";
import { userStore } from "users/_stores/userStore";
import { observer } from "mobx-react";
import { Input } from "_common/ui/forms/Input";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { MESSAGE_STATUS } from "messages/_models/MessageMdl";
import { messagesStore } from "messages/_stores/messagesStore";
import { settingsStore } from "settings/_stores/settingsStore";
import { FilePicker } from "_common/ui/forms/images/FilePicker";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { IconButton } from "@material-ui/core";
import { FilePreview } from "_common/ui/forms/images/FilePreview";
import { useMessengerConversation } from "messages/messenger/_hooks/messengerHooks";
import { toast } from "react-toastify";
import { socketStore } from "messages/messenger/_stores/socketStore";

type Props = {
    orderId: string;
    isPartner: boolean;
};

function scrollToBottomOfConversation(ref: HTMLDivElement) {
    setTimeout(() => {
        ref.scrollTop = ref.scrollHeight;
    }, 100);
}

export const CONVERSATION_REF: { current: HTMLDivElement | null } = { current: null };

export const MessengerConversation = observer((props: Props) => {
    const [newMessage, setNewMessage] = useState("");
    const { t } = useTranslation();
    const orderId = props.orderId;

    if (Object.keys(messengerStore.conversations).length === 0 || !messengerStore.conversations?.[orderId]) {
        return <div className={clsx("flex-2 position_relative flex_column", styles.container)}></div>;
    }

    useMessengerConversation(orderId);

    let leadUserId: string | undefined;
    if (props.isPartner) {
        leadUserId = settingsStore.getOne()?.userRecipient;
    }

    function onSubmit() {
        if (!newMessage) toast.info("Veuillez joindre un message svp");

        socketStore.sendMessage(newMessage, props.orderId, leadUserId, messagesStore.files);

        setTimeout(() => {
            messagesStore.resetFiles();
        }, 400);
        setNewMessage("");
        if (CONVERSATION_REF.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            //@ts-ignore
            scrollToBottomOfConversation(CONVERSATION_REF.current);
        }
    }

    const hasFiles = messagesStore.files && messagesStore?.files.urls.length > 0;
    return (
        <div className={clsx("flex-2 position_relative flex_column", styles.container)}>
            <div
                ref={(ref) => (CONVERSATION_REF.current = ref)}
                className={clsx("flex-1 flex_column", styles.conversation)}
            >
                {messengerStore.conversations[orderId].map((message) => {
                    return (
                        <div
                            className={clsx("flex_row", {
                                ["justifyContent_flexEnd"]: message.user === userStore.user?._id,
                            })}
                            key={message._id}
                        >
                            <div className={clsx("flex_column mh_20 mb_20")}>
                                <div
                                    className={clsx("text_discreet mb_3", {
                                        ["textAlign_right"]: message.user === userStore.user?._id,
                                    })}
                                >
                                    {dayjs(message.createdAt).format(t("dates.formats.dayAndHour"))}
                                </div>
                                <div
                                    className={clsx(styles.cardContainer, "p_20 flex-1", {
                                        [styles.mine]:
                                            message.user === userStore.user?._id ||
                                            (userStore.isAdmin &&
                                                message.user === settingsStore.getOne()?.userRecipient),
                                        [styles.notRead]:
                                            message.status === MESSAGE_STATUS.NOT_READ &&
                                            message.user !== userStore.user?._id,
                                    })}
                                >
                                    {message.message}
                                </div>
                                {message.files && message.files.length > 0 && (
                                    <div
                                        className={clsx("flex_row flexWrap_wrap", {
                                            ["textAlign_right"]: message.user === userStore.user?._id,
                                        })}
                                    >
                                        {message.files.map((file) => (
                                            <FilePreview url={file.url} key={file.url} />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={clsx(styles.input, "p_20")}>
                <div className={"flex-1"}>
                    {hasFiles && (
                        <div>
                            <h6 style={{ marginBottom: 0 }}>Fichiers joints au message</h6>
                            <div className={"flex_row"}>
                                {messagesStore.files?.urls?.map((file) => {
                                    return <FilePreview url={file} key={file} />;
                                })}
                            </div>
                        </div>
                    )}
                </div>
                <div className={"mt_10 flex_row_center"}>
                    <div className={"flex-1 mr_10"}>
                        <Input
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") onSubmit();
                            }}
                        />
                    </div>
                    <FilePicker
                        withFilesObject
                        value={[]}
                        onChange={(files) => {
                            if ("files" in files) messagesStore.addFiles(files);
                        }}
                        maxNbFiles={25}
                    >
                        <IconButton>
                            <FileCopyIcon />
                        </IconButton>
                    </FilePicker>
                    <UiButton disabled={newMessage === ""} onClick={onSubmit} color={"primary"} variant={"contained"}>
                        {t("words.send")}
                    </UiButton>
                </div>
            </div>
        </div>
    );
});
