import React from "react";
import clsx from "clsx";
import styles from "invoices/_css/invoicesPage.module.css";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

export const InvoicesTotalTable = observer(() => {
    const { t } = useTranslation();

    return (
        <table className={clsx(styles.table)}>
            <tbody>
                <tr>
                    <td> </td>
                    <td>
                        <h4>{t("order.reprog.tuningTypes.STAGE_1")}</h4>
                    </td>
                    <td>
                        <h4>{t("order.reprog.tuningTypes.STAGE_2")}</h4>
                    </td>
                    <td>
                        <h4>{t("order.reprog.tuningTypes.STAGE_3")} </h4>
                    </td>
                    <td>
                        <h4>Options</h4>
                    </td>
                    <td>
                        <h4>Total</h4>
                    </td>
                </tr>
                <tr>
                    <td> {t("words.number")} </td>
                    <td> {ordersAdminStore.totalForAllItem.totalNumberS1}</td>
                    <td> {ordersAdminStore.totalForAllItem.totalNumberS2}</td>
                    <td> {ordersAdminStore.totalForAllItem.totalNumberS3}</td>
                    <td> {ordersAdminStore.totalForAllItem.totalNumberOption}</td>
                    <td> {ordersAdminStore.totalForAllItem.totalNumberInvoice}</td>
                </tr>
                <tr>
                    <td> {t("words.amount")} </td>
                    <td> {ordersAdminStore.totalForAllItem.totalStage1}</td>
                    <td> {ordersAdminStore.totalForAllItem.totalStage2}</td>
                    <td> {ordersAdminStore.totalForAllItem.totalStage3}</td>
                    <td> {ordersAdminStore.totalForAllItem.totalOptions}</td>
                    <td> {ordersAdminStore.totalForAllItem.totalInvoice}</td>
                </tr>
            </tbody>
        </table>
    );
});
