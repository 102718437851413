import React, { useState } from "react";
import { MenuItem, Tab, Tabs } from "@material-ui/core";
import clsx from "clsx";
import styles from "orders/_css/orderNewPage.module.css";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { OrderCarCharac } from "orders/OrderCarCharac";
import { OrderCarTcuEcu } from "orders/OrderCarTcuEcu";
import OrderCarMaintenance from "orders/OrderCarMaintenance";
import { toast } from "react-toastify";
import { URLS } from "_configs/URLS";
import { createFilesData } from "_common/_utils/fileUtils";
import { ORDER_STATUS, ORDER_TYPE, TOrderListingMdl, TOrderMdl } from "orders/_models/OrderMdl";
import { reformatStringValueOfObject } from "_common/_utils/textUtils";
import { ordersStore } from "orders/_stores/ordersStore";
import { STEPS } from "orders/OrderNewPage";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { useOrderStore } from "orders/OrderContext";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { Save } from "@material-ui/icons";
import { getOrderTuningTypeByType } from "_common/_utils/orderUtils";

type Props = {
    isReadOnly?: boolean;
    order?: TOrderListingMdl;
    onUpdate?: (promise: Promise<TOrderListingMdl | undefined>) => void;
};

export function OrderViewForm(props: Props) {
    const [tab, setTab] = useState<STEPS>(STEPS.INFO);
    const [infoIsErrored, setInfoIsErrored] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();
    const store = userStore.isAdmin || userStore.isEngineer ? ordersAdminStore : ordersStore;
    const orderStore = useOrderStore();
    const form = useFormContext();
    const { t } = useTranslation();
    const currentStageEcu = props.order ? getOrderTuningTypeByType(props.order, ORDER_TYPE.ECU) : undefined;
    const currentStageTcu = props.order ? getOrderTuningTypeByType(props.order, ORDER_TYPE.TCU) : undefined;
    return (
        <div>
            <Tabs className="mb_10" value={tab} onChange={(_e, newValue) => setTab(newValue)} variant="fullWidth">
                <Tab label={t("order.tabs.INFO")} value={STEPS.INFO} />
                <Tab
                    label={
                        t("order.tabs.ECU") +
                        (currentStageEcu ? ` (${t("order.reprog.tuningTypes." + currentStageEcu)})` : "")
                    }
                    value={STEPS.ECU}
                    disabled={infoIsErrored && !props.isReadOnly}
                />
                <Tab
                    label={
                        t("order.tabs.TCU") +
                        (currentStageTcu ? ` (${t("order.reprog.tuningTypes." + currentStageTcu)})` : "")
                    }
                    value={STEPS.TCU}
                    disabled={infoIsErrored && !props.isReadOnly}
                />
                <Tab
                    label={t("order.tabs.UPKEEP")}
                    value={STEPS.UPKEEP}
                    disabled={infoIsErrored && !props.isReadOnly}
                />
            </Tabs>
            <div className={clsx("flex_row position_relative", styles.content)}>
                <Controller className={"hidden"} name={"partner"} as={Input} control={form.control} />
                <Controller className={"hidden"} name={"_id"} as={Input} control={form.control} />
                <div hidden={tab !== "INFO"} className={"flex-1"}>
                    <OrderCarCharac
                        onBlur={(isError) => setInfoIsErrored(isError)}
                        isPartnerForm
                        isReadOnly={props.isReadOnly}
                        order={props.order}
                    />
                </div>
                <div hidden={tab !== "ECU"} className={"flex-1"}>
                    <OrderCarTcuEcu
                        type={ORDER_TYPE.ECU}
                        isPartnerForm
                        isReadOnly={props.isReadOnly}
                        order={props.order}
                        onUpdate={props.onUpdate}
                    />
                </div>
                <div hidden={tab !== "TCU"} className={"flex-1"}>
                    <OrderCarTcuEcu
                        type={ORDER_TYPE.TCU}
                        isPartnerForm
                        isReadOnly={props.isReadOnly}
                        order={props.order}
                        onUpdate={props.onUpdate}
                    />
                </div>
                <div hidden={tab !== "UPKEEP"} className={"flex-1"}>
                    <OrderCarMaintenance
                        isSaving={isSaving}
                        isReadOnly={props.isReadOnly}
                        onSave={form.handleSubmit(async (data) => {
                            if (data._id) {
                                store.patch(data).then(
                                    () => {
                                        toast.success(t("order.successfullySent"));
                                        history.push(URLS.home());
                                    },
                                    () => toast.error(t("words.error")),
                                );
                            } else {
                                setIsSaving(true);
                                const filesData = await createFilesData(
                                    (data as TOrderMdl).originFile.url,
                                    `originFile.url`,
                                );
                                const reformatedData = reformatStringValueOfObject<
                                    Omit<Partial<TOrderListingMdl>, "_id">
                                >(data, ["toUpperCase", "noAccents"], ["ownerName", "brand", "model"]);
                                ordersStore.create(reformatedData, filesData).then(
                                    () => {
                                        toast.success(t("order.successfullySent"));
                                        history.push(URLS.home());
                                    },
                                    () => {
                                        toast.error(t("words.error"));
                                        setIsSaving(false);
                                    },
                                );
                            }
                        })}
                    />
                </div>
            </div>
            {(userStore.isAdmin || userStore.isEngineer) && (
                <div className={clsx("flex_row mt_40", styles.row)}>
                    <div className={"flex-1"} />
                    <div className={"flex_row flex-1 justifyContent_flexEnd"}>
                        <div className="mr_40">
                            <InputBlock label={t("order.inProgress.status")} required>
                                <Controller
                                    rules={{ required: t<string>("errors.forms.required") }}
                                    as={
                                        <UiSelect>
                                            {Object.keys(ORDER_STATUS).map((status, index) => {
                                                return (
                                                    <MenuItem key={index} value={status}>
                                                        <div className="flex_row_center">
                                                            {t("order.status." + status)}
                                                        </div>
                                                    </MenuItem>
                                                );
                                            })}
                                        </UiSelect>
                                    }
                                    name={"status"}
                                    control={form.control}
                                />
                            </InputBlock>
                        </div>
                        <UiButton
                            disabled={Object.keys(form.errors).length > 0}
                            variant={"contained"}
                            color={"primary"}
                            onClick={form.handleSubmit(
                                async (data) => {
                                    store.patch(data).then(
                                        () => {
                                            if (props.onUpdate) {
                                                props.onUpdate(store.getAsync(orderStore.item._id, true));
                                            }
                                            toast.success(t("order.successfullySent"));
                                        },
                                        () => toast.error(t("words.error")),
                                    );
                                },
                                async (e) => console.error(e),
                            )}
                        >
                            {t("words.save")} <Save />
                        </UiButton>
                    </div>
                </div>
            )}
        </div>
    );
}
