import React from "react";
import { MenuItem } from "@material-ui/core";
import { brandsStore } from "admin/brands/_stores/brandsStore";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";

type Props = {
    onChange: (brandIds: string) => void;
    brandId: string;
};

export function AdminBrandInput(props: Props) {
    const brands = brandsStore.getListStore(undefined, Number.MAX_SAFE_INTEGER, undefined, { name: 1 }).items;

    return (
        <div>
            <UiSelect onChange={(e) => props.onChange(e.target.value as string)}>
                {brands.map((brand) => {
                    if (!brand) return null;
                    return (
                        <MenuItem key={brand._id} value={brand._id}>
                            {brand.name}
                        </MenuItem>
                    );
                })}
            </UiSelect>
        </div>
    );
}
