import { TMessageMdl } from "messages/_models/MessageMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import dayjs from "dayjs";
import sharedConfig from "_configs/sharedConfig";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { action, extendObservable, observable } from "mobx";
import { userStore } from "users/_stores/userStore";

class MessengerStore {
    @observable conversations: { [orderId: string]: TMessageMdl[] } = {};
    @observable notification = 0;
    conversationsState: LoadingStateMdl = new LoadingStateMdl();

    constructor(private apiPath = sharedConfig.apiUrl + "/messages") {}

    list() {
        if (!this.conversationsState.isLoading) {
            this.conversationsState.startLoading();
            let path = "/listing";
            if (userStore.isAdmin) path = "/listingIsAdmin";
            const url = `${this.apiPath}${path}?sort=${JSON.stringify({ createdAt: 1 })}`;
            fetchUtils.get<{ count: number; items: { [order: string]: TMessageMdl[] } }>(url).then(
                ({ data: { items: orderConversations } }) => {
                    Object.entries(orderConversations)
                        .reverse()
                        .forEach(([orderId, messages]) => {
                            extendObservable(this.conversations, {
                                [orderId]: messages.map((message) => this.reformatItem(message)),
                            });
                        });
                    this.conversationsState.setSuccess();
                },
                () => this.conversationsState.setError({ key: "errors" }),
            );
        }
        return this.conversationsState;
    }

    @action addMessage(message: TMessageMdl) {
        if (!this.conversations[message.order]) this.conversations[message.order] = [];
        this.conversations[message.order].push(message);
    }

    protected reformatItem<TResource>(item: TResource) {
        const formattedItem = { ...item };
        if ((item as any).createdAt) (formattedItem as any).createdAt = dayjs((formattedItem as any).createdAt);
        if ((item as any).updatedAt) (formattedItem as any).updatedAt = dayjs((formattedItem as any).updatedAt);
        return formattedItem;
    }
}

export const messengerStore = new MessengerStore();
