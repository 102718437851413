import React, { CSSProperties, useRef } from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import styles from "./_css/newsCardComponent.module.css";
import { TComponent } from "pages/_models/PageMdl";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export type NewsCardComponentProps = {
    date?: Dayjs | string;
    title?: string;
    text?: string;
    image?: string;
    url?: string;
    style?: CSSProperties;
    $component?: TComponent;
};

export function NewsCardComponent(props: NewsCardComponentProps) {
    const mouseDownStart = useRef(0);
    const history = useHistory();
    const { t } = useTranslation();
    const date = typeof props.date === "string" ? dayjs(props.date) : props.date;
    return (
        <div
            style={props.style}
            className={styles.container}
            onMouseDown={() => (mouseDownStart.current = Date.now())}
            onMouseUp={() => {
                if (!props.url) return;
                if (Date.now() - mouseDownStart.current > 250) return;
                if (props.url?.startsWith("/")) {
                    history.push("/" + i18next.language + props.url);
                } else if (props.url) {
                    window.location.href = props.url;
                }
            }}
        >
            <div className={styles.content}>
                <div className={clsx(styles.image, "pt_20")} style={{ backgroundImage: `url(${props.image})` }}>
                    {date && <span className={clsx(styles.date, "mh_20")}>{date.format("DD/MM/YYYY")}</span>}
                </div>
                <div className={clsx(styles.title, "ph_20 pt_20 fontWeight_bold")}>{props.title}</div>
                <div className={clsx(styles.text, "p_20")}>{props.text}</div>
                <div className={clsx(styles.readMore, "flex_column justifyContent_center fontWeight_bold ml_20")}>
                    <div className="flex_row_center">
                        <div className={styles.textGradient} />
                        {t("news.seeMore")} <i className="icon-arrow-right1" />
                    </div>
                </div>
            </div>
        </div>
    );
}
