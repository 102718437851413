import React from "react";
import clsx from "clsx";
import styles from "orders/_css/orderCarCharac.module.css";
import { useTranslation } from "react-i18next";
import { FilePreview } from "_common/ui/forms/images/FilePreview";
import { useOrderStore } from "orders/OrderContext";
import { observer } from "mobx-react";
import { ORDER_TYPE } from "orders/_models/OrderMdl";

type Props = {
    type: ORDER_TYPE;
};
export const OrderClosed = observer((props: Props) => {
    const { t } = useTranslation();
    const orderStore = useOrderStore();
    return (
        <div className={clsx(styles.container, "p_20 flex-1")}>
            <h2>{t("order.closed.title")}</h2>
            <div className={"flex_row flexWrap_wrap"}>
                {props.type === ORDER_TYPE.ECU ? (
                    <div className={"flex_column"}>
                        <h3 className={"m_0"}>{t("order.ident.ecu")}</h3>
                        <div className={"flex_row flexWrap_wrap"}>
                            {orderStore.item.files.map((file, idx) => (
                                <FilePreview key={idx} url={file.url} />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className={"flex_column"}>
                        <h3 className={"m_0"}>{t("order.ident.tcu")}</h3>
                        <div className={"flex_row flexWrap_wrap"}>
                            {orderStore.item.filesTcu.map((file, idx) => (
                                <FilePreview key={idx} url={file.url} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});
