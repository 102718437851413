import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { TOrderListingMdl } from "orders/_models/OrderMdl";
import { Tooltip } from "@material-ui/core";
import { IconComponent } from "components/base/icon/IconComponent";
import { MessengerSwipeUp } from "messages/messenger/MessengerSwipeUp";
import { toast } from "react-toastify";
import clsx from "clsx";
import i18next from "i18next";
import { Link } from "react-router-dom";
import styles from "./_css/messengerIcon.module.css";
import { URLS } from "_configs/URLS";

type Props = {
    order: TOrderListingMdl;
};

export function MessengerIcon(props: Props) {
    const { t } = useTranslation();
    const order = props.order;
    const [openMessenger, setOpenMessenger] = useState(false);

    if (!order) return null;
    const isPartner = userStore.user?._id === order?.partner;
    /*if (isPartner) return null;*/

    return (
        <div>
            <Tooltip title={t("words.message")}>
                <div
                    className={styles.container}
                    onClick={() => {
                        userStore.isLogged ? setOpenMessenger(true) : userStore.openSignInWithModal();
                    }}
                >
                    <div className={clsx("flex_row", styles.messengerIcon)}>
                        <IconComponent icon={"message"} />
                    </div>
                </div>
            </Tooltip>
            <MessengerSwipeUp
                order={order}
                onSuccess={() =>
                    toast.info(
                        <div>
                            <Link to={URLS.user.messenger(i18next.language, order._id)}>
                                {"Voir l'ensemble de la conversation"}
                            </Link>
                        </div>,
                        {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                        },
                    )
                }
                onClose={() => setOpenMessenger(false)}
                open={openMessenger}
            />
        </div>
    );
}
