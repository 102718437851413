import React from "react";
import { IconButton } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import copy from "copy-to-clipboard";
import { ExtendButtonBase } from "@material-ui/core/ButtonBase";
import { IconButtonTypeMap } from "@material-ui/core/IconButton/IconButton";

type Props = {
    text: string;
    onCopied?: () => void;
};

export function CopyLinkBtn({ text, onCopied, ...props }: Props & ExtendButtonBase<IconButtonTypeMap>) {
    return (
        <IconButton
            {...props}
            onClick={() => {
                copy(text);
                onCopied?.();
            }}
        >
            <FileCopy />
        </IconButton>
    );
}
