import sharedConfig, { TLang } from "_configs/sharedConfig";
import { universalLanguageDetect } from "@unly/universal-language-detector";

export function detectLanguage(pathname: string, acceptLanguageHeader?: string) {
    if (pathname.startsWith("/fr")) return sharedConfig.languages.fr;
    if (pathname.startsWith("/en")) return sharedConfig.languages.en;
    const lang = universalLanguageDetect({
        supportedLanguages: ["fr", "en"],
        fallbackLanguage: "fr",
        acceptLanguageHeader,
    }) as TLang;
    return sharedConfig.languages[lang];
}
