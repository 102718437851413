import { useEffect } from "react";
import { messengerStore } from "messages/messenger/_stores/messengerStore";
import { CONVERSATION_REF } from "messages/messenger/MessengerConversation";
import { MESSAGE_STATUS } from "messages/_models/MessageMdl";
import { userStore } from "users/_stores/userStore";
import { messagesStore } from "messages/_stores/messagesStore";

export function useMessengerConversation(orderId: string) {
    useEffect(() => {
        if (CONVERSATION_REF.current !== null) {
            // eslint-disable-next-line
            //@ts-ignore
            CONVERSATION_REF.current.scrollTop = CONVERSATION_REF.current.scrollHeight;
        }
    }, [messengerStore.conversations, CONVERSATION_REF.current?.scrollHeight]);

    useEffect(() => {
        if (CONVERSATION_REF.current) {
            // eslint-disable-next-line
            //@ts-ignore
            CONVERSATION_REF.current.scrollTop = CONVERSATION_REF.current.scrollHeight;
        }
    }, [orderId, messengerStore.conversations, CONVERSATION_REF.current?.scrollHeight]);

    useEffect(() => {
        const messageNotRead = messengerStore.conversations[orderId].filter(
            (message) => message.status === MESSAGE_STATUS.NOT_READ && message.user != userStore.user?._id,
        );
        if (messageNotRead && messageNotRead.length > 0) {
            setTimeout(() => {
                messageNotRead.map((message) => {
                    messagesStore.changeStatusForOneMessage(message._id, MESSAGE_STATUS.READ);
                    const indexForMessage = messengerStore.conversations[orderId].findIndex(
                        (_message) => _message._id === message._id,
                    );
                    messengerStore.conversations[orderId][indexForMessage].status = MESSAGE_STATUS.READ;
                });
            }, 5000);
        }
    }, [messengerStore.conversations, CONVERSATION_REF.current?.scrollHeight]);
}
