import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { MESSAGE_STATUS } from "messages/_models/MessageMdl";
import { messengerStore } from "messages/messenger/_stores/messengerStore";
import { observable } from "mobx";

class MessagesStore extends BaseResourceStore<any> {
    @observable files: { urls: string[]; files: File[] } | undefined;
    constructor() {
        super("messages");
        if (__BROWSER__) this.onInit();
    }

    changeStatusForOneMessage(messageId: string, status: MESSAGE_STATUS) {
        this.patch({ _id: messageId, status: status });
        messengerStore.conversations = JSON.parse(JSON.stringify(messengerStore.conversations));
    }

    addFiles(urls: { urls: string[]; files: File[] }) {
        if (this.files) {
            this.files.urls.push(...urls.urls);
            this.files.files.push(...urls.files);
        } else this.files = { ...urls };
    }

    resetFiles() {
        this.files = { urls: [], files: [] };
    }
}

const messagesStore = new MessagesStore();
export { messagesStore };
