import React, { PropsWithChildren } from "react";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { isImage } from "_common/_utils/fileUtils";
import styles from "./_css/filePreview.module.css";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { UiButton } from "_common/ui/mui/buttons/UiButton";

type Props = {
    url: string;
    isImage?: boolean;
    onDelete?: () => void;
};

export function FilePreview(props: PropsWithChildren<Props>) {
    const displayImage = props.isImage || isImage(props.url);
    const arrFile = props.url.split("/");
    const fileSize = arrFile.length;
    const fileName = arrFile[fileSize - 1];
    return (
        <div
            className={clsx(styles.container, "cursor_pointer")}
            onClick={(event) => {
                event.stopPropagation();
                window.open(props.url, "_blank");
            }}
        >
            <UiButton title={fileName}>
                {displayImage ? <img src={props.url} alt="" /> : <InsertDriveFileIcon fontSize="large" />}
            </UiButton>
            {props.children}
            {props.onDelete && (
                <div
                    className={styles.delete}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.onDelete?.();
                    }}
                >
                    <CloseIcon className={styles.deleteIcon} />
                </div>
            )}
        </div>
    );
}
