import React from "react";
import clsx from "clsx";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import styles from "./_css/invoicesLine.module.css";
import { TOrderReformatMdl } from "orders/_models/OrderMdl";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { observer } from "mobx-react";
import { Notifications, OpenInNew } from "@material-ui/icons";

type Props = {
    invoice: TOrderReformatMdl;
    onSelectInvoice: (order: string | undefined) => void;
    selectedInvoice: string | undefined;
};

export const InvoiceLine = observer((props: Props) => {
    const { t } = useTranslation();
    const order = props.invoice;
    const orderInvoiceHasHistory = order.invoice.history?.length > 0;
    return (
        <div
            className={clsx(styles.container, "cursor_pointer flex_row p_20", {
                [styles.selectedInvoice]: props.selectedInvoice === order._id,
            })}
            onClick={() => {
                if (props.selectedInvoice === order._id) props.onSelectInvoice(undefined);
                else props.onSelectInvoice(order._id);
            }}
        >
            <div className={clsx("flex_row_center flex-1", styles.firstColumn)}>
                <div className={"flex-1"}> {order?.vin} </div>
                <div className={"flex-1"}>
                    {t("words.amount")} {ordersAdminStore.calcTotalForOneItem(order)}
                </div>

                <div className={"flex-1"}> {order.createdAt.format(t("dates.formats.dayAndHour"))}</div>
                <div className={"flex-1 flex_row_center justifyContent_spaceBetween"}>
                    <Link target={"_blank"} to={URLS.order(order._id)} className="flex_row_center mr_5">
                        {t("order.order")} <OpenInNew fontSize={"small"} />
                    </Link>
                    {orderInvoiceHasHistory && (
                        <div className={"flex_row_center"}>
                            <Notifications fontSize={"small"} color={"secondary"} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
