import React, { ReactNode } from "react";
import { ListStore } from "shared/_common/list/ListStore";
import { observer } from "mobx-react";
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { TObjWithId } from "_common/types/GenericTypes";
import clsx from "clsx";
import styles from "./_css/resourceTable.module.css";

export type TColumn<TResource extends TObjWithId> = {
    id: string;
    title?: string;
    sortable?: boolean;
    renderer: (item: TResource, onAction?: (file: string) => void) => ReactNode;
};

type Props<TResource extends TObjWithId> = {
    listStore: ListStore<TResource>;
    columns: TColumn<TResource>[];
    minify?: boolean;
    onSelectFile?: (file: string) => void;
};

type HeaderProps<TResource extends TObjWithId> = {
    listStore: ListStore<TResource>;
    column: TColumn<TResource>;
};

const TableHeader = observer(({ listStore, column }: HeaderProps<any>) => {
    const columnSort = listStore?.sort?.[column.id];
    return (
        <TableCell>
            <TableSortLabel
                active={!!columnSort}
                direction={columnSort === 1 ? "asc" : "desc"}
                onClick={() =>
                    column.sortable !== false &&
                    listStore?.setSort({
                        [column.id]: columnSort ? columnSort * -1 : 1,
                    })
                }
            >
                {column.title}
            </TableSortLabel>
        </TableCell>
    );
});

const renderRow = (columns: TColumn<any>[], resource: any, onSelectFile?: (file: string) => void) => {
    return (
        <TableRow key={resource._id}>
            {columns.map((column) => (
                <TableCell key={column.id}>{column.renderer(resource, onSelectFile)}</TableCell>
            ))}
        </TableRow>
    );
};

function _GenericAdminResourceTable<TResource extends TObjWithId>(props: Props<TResource>) {
    return (
        <div>
            <Table
                className={clsx({
                    [styles.container]: props.minify,
                })}
            >
                <TableHead>
                    <TableRow>
                        {props.columns.map((column) => (
                            <TableHeader key={column.id} listStore={props.listStore} column={column} />
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.listStore.paginatedItems.map((item) => renderRow(props.columns, item, props.onSelectFile))}
                </TableBody>
            </Table>
        </div>
    );
}

export const ResourceTable = observer(_GenericAdminResourceTable);
