import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, NoSsr } from "@material-ui/core";
import styles from "./_css/mobileMenuBtn.module.css";
import { MobileMenu } from "main/menu/mobile/MobileMenu";
import { useScrollPosition } from "_common/_utils/hookUtils";
import { PAGE_REF } from "pages/PageRegular";

export function MobileMenuBtn() {
    const [opened, setOpened] = useState(false);
    const scrollPosition = useScrollPosition(PAGE_REF);
    return (
        <div>
            <IconButton
                size={"small"}
                className={styles.container}
                onClick={() => {
                    setOpened(!opened);
                }}
                style={{ top: scrollPosition > 40 ? 8 : 0 }}
            >
                <MenuIcon style={{ color: "white" }} fontSize="large" />
            </IconButton>
            <NoSsr>
                <MobileMenu opened={opened} onClose={() => setOpened(false)} />
            </NoSsr>
        </div>
    );
}
