import React from "react";
import { Route, Switch } from "react-router";
import { PageRouter } from "pages/PageRouter";
import { PageRegular } from "pages/PageRegular";
import { ScrollTop } from "_common/_utils/ScrollTop";
import { NoSsr } from "@material-ui/core";
import { ProfilePage } from "users/profile/ProfilePage";
import { URLS } from "_configs/URLS";
import { AuthenticatedRoute } from "_common/_utils/AuthenticatedRoute";
import { OrderNewPage } from "orders/OrderNewPage";
import { OrderPage } from "orders/OrderPage";
import { InvoicesPage } from "invoices/InvoicesPage";
import { MessengerPage } from "messages/messenger/MessengerPage";

export function AppContent() {
    return (
        <PageRegular>
            <Switch>
                <AuthenticatedRoute path={URLS.user.profile(":lang")} component={ProfilePage} />
                <AuthenticatedRoute path={URLS.newOrder(":lang")} component={OrderNewPage} />
                <AuthenticatedRoute path={URLS.order(":orderId", ":lang")} component={OrderPage} />
                <AuthenticatedRoute path={URLS.invoices(":lang")} component={InvoicesPage} />
                <AuthenticatedRoute path={URLS.user.messenger(":lang", ":order?")} component={MessengerPage} />
                <Route path="/" component={() => <PageRouter />} />
            </Switch>
            <NoSsr>
                <ScrollTop />
            </NoSsr>
        </PageRegular>
    );
}
