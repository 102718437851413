import React from "react";
import { Container, NoSsr } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import header from "../assets/images/home/header.jpg";
import styles from "./_css/homePage.module.css";
import clsx from "clsx";
import { useHistory } from "react-router";
import { userStore } from "users/_stores/userStore";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { Add } from "@material-ui/icons";
import { OrdersList } from "orders/OrdersList";
import { OrdersAdminList } from "orders/admin/OrdersAdminList";
import { URLS } from "_configs/URLS";

export function HomePage() {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <NoSsr>
            <div className="position_relative">
                <LazyLoadImage src={header} className={styles.headerImg} />
                <div className={clsx(styles.headerTitle, "position_absolute flex_center_center textAlign_center")}>
                    <div>{t("homePage.headerTitle")}</div>
                </div>
                <div className={clsx(styles.headerImgOver, "position_absolute")} />
            </div>
            <Container>
                <NoSsr>
                    <div className={clsx("flex_column", styles.container)}>
                        {userStore.isAdmin || userStore.isEngineer ? (
                            <div className={clsx("flex-2", styles.content)}>
                                <h2 className={styles.title}>{t("homePage.adminTitle")}</h2>
                                <OrdersAdminList />
                            </div>
                        ) : (
                            <>
                                <div className={"flex_row justifyContent_flexEnd"}>
                                    <UiButton
                                        onClick={() => history.push(URLS.newOrder())}
                                        className="flex_row_center mt_20"
                                        variant={"contained"}
                                        color={"primary"}
                                    >
                                        <Add /> {t("homePage.btn")}
                                    </UiButton>
                                </div>
                                <div className={clsx("flex-2 mr_40", styles.content)}>
                                    <h2 className={styles.title}>{t("homePage.title")}</h2>
                                    <OrdersList />
                                </div>
                            </>
                        )}
                    </div>
                </NoSsr>
            </Container>
        </NoSsr>
    );
}
