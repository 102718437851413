import React from "react";
import clsx from "clsx";
import styles from "orders/_css/orderCarCharac.module.css";
import { useTranslation } from "react-i18next";
import { OrderFilesEditor } from "orders/OrderFilesEditor";
import { userStore } from "users/_stores/userStore";
import { FilePreview } from "_common/ui/forms/images/FilePreview";
import { useOrderStore } from "orders/OrderContext";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { TextArea } from "_common/ui/forms/TextArea";
import { ORDER_STATUS, ORDER_TYPE } from "orders/_models/OrderMdl";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { Send } from "@material-ui/icons";

type Props = {
    onSave: () => void;
    type: ORDER_TYPE;
};

export function OrderSav(props: Props) {
    const { t } = useTranslation();
    const orderStore = useOrderStore();
    const form = useFormContext();
    return (
        <div className={clsx(styles.container, "p_20 flex-1")}>
            <h2>
                {t(
                    userStore.isAdmin || orderStore.item.status === ORDER_STATUS.SAV
                        ? "order.sav.adminTitle"
                        : "order.sav.title",
                )}
            </h2>
            {userStore.isAdmin || orderStore.item.status === ORDER_STATUS.SAV ? (
                <div className={"flex_row"}>
                    <div className={"flex-1 mr_40"}>
                        {props.type === ORDER_TYPE.ECU ? (
                            <InputBlock label={"Commentaire ECU"}>{orderStore.item.savComment}</InputBlock>
                        ) : (
                            <InputBlock label={"Commentaire TCU"}>{orderStore.item.tcu.savComment}</InputBlock>
                        )}
                    </div>
                    <div className={"flex-1 flex_row"}>
                        {props.type === ORDER_TYPE.ECU ? (
                            <>
                                {orderStore.item.savFiles?.map((file, idx) => (
                                    <FilePreview key={idx} url={file.url} />
                                ))}
                            </>
                        ) : (
                            <>
                                {orderStore.item.savFilesTcu?.map((file, idx) => (
                                    <FilePreview key={idx} url={file.url} />
                                ))}
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    {props.type === ORDER_TYPE.ECU ? (
                        <div className={"flex_row"}>
                            <div className={"flex-1 mr_40"}>
                                <InputBlock label={"Commentaire (ECU)"}>
                                    <Controller rows={6} as={TextArea} control={form.control} name={"savComment"} />
                                </InputBlock>
                            </div>
                            <div className={"flex-1"}>
                                <InputBlock label={"Joindre des fichiers à votre demande ECU"}>
                                    <OrderFilesEditor resourceKey={"savFiles"} />
                                </InputBlock>
                            </div>
                        </div>
                    ) : (
                        <div className={"flex_row"}>
                            <div className={"flex-1 mr_40"}>
                                <InputBlock label={"Commentaire (TCU)"}>
                                    <Controller rows={6} as={TextArea} control={form.control} name={"tcu.savComment"} />
                                </InputBlock>
                            </div>
                            <div className={"flex-1"}>
                                <InputBlock label={"Joindre des fichiers à votre demande TCU"}>
                                    <OrderFilesEditor resourceKey={"savFiles"} />
                                </InputBlock>
                            </div>
                        </div>
                    )}
                    <div className={clsx("flex_row", styles.row)}>
                        <div className={"flex-1 mr_40"} />
                        <div className={"flex_row flex-1 mt_40 justifyContent_flexEnd"}>
                            <UiButton
                                disabled={Object.keys(form.errors).length > 0}
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => props.onSave()}
                            >
                                <span className={"mr_3"}>{t("words.send")}</span> <Send />
                            </UiButton>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
