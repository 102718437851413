import React from "react";
import styles from "./_css/panel.module.css";
import clsx from "clsx";
import { useWindowSize } from "_common/_utils/hookUtils";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { panelStore, TPanelOption } from "main/menu/panel/_stores/panelStore";

type Props = {
    option?: TPanelOption;
};

export function Panel(props: Props) {
    const windowsSize = useWindowSize();
    return (
        <>
            {props.option?.opened && <div className={styles.overlay} onClick={() => panelStore.close()} />}
            <div
                {...props}
                className={clsx(styles.container, {
                    [styles.opened]: props.option?.opened,
                    [styles.fullWidthContainer]: windowsSize.width < 600,
                })}
            >
                <IconButton size={"small"} className={styles.closeIcon} onClick={() => panelStore.close()}>
                    <CloseIcon style={{ color: "#000" }} fontSize="large" />
                </IconButton>
                {props.option?.children}
            </div>
        </>
    );
}
