import React from "react";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { Link } from "react-router-dom";
import { TObjWithId } from "_common/types/GenericTypes";
import { userStore } from "users/_stores/userStore";

type Props<T extends TObjWithId> = {
    store: BaseResourceStore<T>;
    item: T;
    labelKey: string;
    editUrl: (itemId: string) => string;
    duplicate?: boolean;
    canDelete?: boolean;
    newPage?: boolean;
};

export function ResourceActions<T extends TObjWithId>(props: Props<T>) {
    return (
        <div className="flex_row_center">
            <Link target={props.newPage ? "_blank" : "_self"} to={props.editUrl(props.item._id)} className="mr_5">
                <IconButton>
                    <EditIcon />
                </IconButton>
            </Link>
            {props.duplicate && (
                <IconButton onClick={() => props.store.duplicate(props.item._id)} title="Duplicate">
                    <FileCopyIcon />
                </IconButton>
            )}
            {props.canDelete && !userStore.isEngineer && (
                <IconButton
                    onClick={() => {
                        const confirmation = window.confirm(
                            `Are you sure you want to delete "${
                                (props.item as any)[props.labelKey] ?? props.labelKey
                            }"?`,
                        );
                        if (confirmation) {
                            props.store.delete(props.item._id);
                        }
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </div>
    );
}
