import { fetchUtils } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { tokenStore } from "users/_stores/tokenStore";
import i18next from "i18next";
import { URLS } from "_configs/URLS";

class AuthStore {
    signIn(email: string, password: string) {
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/signIn", {
                email,
                password,
            })
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    askResetPassword(email: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/askResetPassword", { email });
    }

    resetPassword(token: string, password: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/resetPassword", { token, password });
    }

    signOut() {
        tokenStore.setToken(undefined);
        window.location.href = URLS.home(i18next.language);
    }
}

const authStore = new AuthStore();
export { authStore };
