import React from "react";
import { adminUsersStore } from "admin/users/_stores/adminUsersStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { IUserMdl } from "users/_models/UserMdl";
import { MenuItem } from "@material-ui/core";
import { PartnerName } from "partners/PartnerName";

type Props = {
    onSelectedPartnerId: (parterId: string) => void;
};

export function CompanyFilter(props: Props) {
    const { loading } = useLoadingFromPromise(adminUsersStore.list(0, Number.MAX_SAFE_INTEGER));
    return (
        <LoadableFromLoading<{ items: IUserMdl[] }>
            loading={loading}
            renderer={(status, error, value: { items: IUserMdl[] }) => {
                if (error || !value?.items) return null;
                const partners = value.items;
                return (
                    <UiSelect defaultValue={"NONE"}>
                        <MenuItem value={"NONE"}>Toutes les compagnies</MenuItem>
                        {partners.map((partner) => {
                            return (
                                <MenuItem
                                    onClick={(e) => props.onSelectedPartnerId(partner._id)}
                                    key={partner._id}
                                    value={partner._id}
                                >
                                    <PartnerName partner={partner} />
                                </MenuItem>
                            );
                        })}
                    </UiSelect>
                );
            }}
        />
    );
}
