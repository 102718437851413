import React from "react";
import styles from "./_css/orderCarMaintenance.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { Save } from "@material-ui/icons";

type Props = {
    onSave?: () => void;
    isSaving?: boolean;
    isReadOnly?: boolean;
};

const OrderCarMaintenance = (props: Props) => {
    const { t } = useTranslation();
    const form = useFormContext();

    function hasErrors() {
        return (
            !form.watch("maintenance.maintenance") ||
            !form.watch("maintenance.oilChange") ||
            !form.watch("maintenance.ignition") ||
            form.watch("maintenance.maintenance") === "" ||
            form.watch("maintenance.oilChange") === "" ||
            form.watch("maintenance.ignition") === "" ||
            Object.keys(form.errors).length > 0
        );
    }

    return (
        <div className={clsx(styles.container, "p_20 flex-1")}>
            <h2>{t("order.maintenance.title")}</h2>
            <div className={"flex_row flexWrap_wrap flex-1"}>
                <InputBlock label={t("order.maintenance.maintenance")} className={clsx("flex-1 mr_40", styles.input)}>
                    <Controller name={"maintenance.maintenance"} control={form.control} as={Input} />
                </InputBlock>
                <InputBlock label={t("order.maintenance.oilChange")} className={clsx("flex-1 mr_40", styles.input)}>
                    <Controller name={"maintenance.oilChange"} control={form.control} as={Input} />
                </InputBlock>
                <InputBlock label={t("order.maintenance.ignition")} className={clsx("flex-1", styles.input)}>
                    <Controller name={"maintenance.ignition"} control={form.control} as={Input} />
                </InputBlock>
            </div>
            {!props.isReadOnly && (
                <div className="flex_row mt_20">
                    <div className="flex-1" />
                    <UiButton
                        disabled={hasErrors() || Object.keys(form.errors).length > 0 || props.isSaving}
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                            if (props.onSave) props.onSave();
                        }}
                    >
                        {t("words.save")} <Save />
                    </UiButton>
                </div>
            )}
        </div>
    );
};

export default OrderCarMaintenance;
