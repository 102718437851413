import React from "react";
import { IPageShortMdl } from "pages/_models/PageMdl";
import { Link } from "react-router-dom";
import i18next from "i18next";
import styles from "./_css/newsLine.module.css";
import clsx from "clsx";
import dayjs from "dayjs";
import { ArrowForward } from "@material-ui/icons";

type Props = {
    article: IPageShortMdl;
};

export function NewsLine(props: Props) {
    return (
        <div className={clsx(styles.container, "p_10 mb_10")}>
            <Link to={`/${i18next.language}${props.article.localized.url}`}>
                <div className="flex_row_center">
                    <div className="flex_column flex-1">
                        <div className={"text_discreet"}>
                            {dayjs(props.article.localized.publishedAt)?.format("DD/MM/YYYY")}
                        </div>
                        <div className={"flex-1"}>{props.article.localized.title}</div>
                    </div>
                    <ArrowForward />
                </div>
            </Link>
        </div>
    );
}
