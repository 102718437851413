import React from "react";
import styles from "./_css/messengerSwipeUp.module.css";
import { Container } from "@material-ui/core";
import { Controller, FormProvider, useForm } from "react-hook-form";
import clsx from "clsx";
import { TextArea } from "_common/ui/forms/TextArea";
import { IconComponent } from "components/base/icon/IconComponent";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { TOrderMdl } from "orders/_models/OrderMdl";
import { useHistory } from "react-router";
import i18next from "i18next";
import { URLS } from "_configs/URLS";
import { messengerStore } from "messages/messenger/_stores/messengerStore";
import { socketStore } from "messages/messenger/_stores/socketStore";
import { messagesStore } from "messages/_stores/messagesStore";

type Props = {
    onClose: () => void;
    onSuccess: () => void;
    order: TOrderMdl;
    open?: boolean;
};

export function MessengerSwipeUp(props: Props) {
    const order = props.order;
    const { t } = useTranslation();
    const history = useHistory();
    const form = useForm({
        mode: "onBlur",
        defaultValues: {
            message: "",
        },
    });

    return (
        <div className={clsx("position_relative", styles.container, { [styles.displayed]: props.open })}>
            <div onClick={props.onClose} className={clsx(styles.close, "flex_row")}>
                <IconComponent icon="close" />
            </div>
            <Container>
                <div className="mt_20">
                    <FormProvider {...form}>
                        <div>
                            <Controller
                                as={TextArea}
                                name="message"
                                control={form.control}
                                rows={6}
                                resizeable={false}
                                placeholder={"Saisir un message"}
                            />
                            {/*<FilePicker value={[]} onChange={(files) => messagesStore.addFiles(files)} maxNbFiles={25}>
                                <IconButton>
                                    <FileCopyIcon />
                                </IconButton>
                            </FilePicker>*/}
                        </div>
                        <div className={"flex_row justifyContent_spaceBetween"}>
                            <div className={"mt_20"}>
                                <UiButton
                                    onClick={form.handleSubmit((data: { message: string }) => {
                                        socketStore.sendMessage(
                                            data.message,
                                            order._id,
                                            undefined,
                                            messagesStore.files,
                                        );
                                        props.onClose();
                                        props.onSuccess();
                                    })}
                                    variant={"contained"}
                                    color={"primary"}
                                >
                                    {t("words.send")}
                                </UiButton>
                            </div>
                            <div className={"mt_20"}>
                                {messengerStore.conversations[order._id] && (
                                    <UiButton
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={() => history.push(URLS.user.messenger(i18next.language, order._id))}
                                    >
                                        {t("words.seeConversation")}
                                    </UiButton>
                                )}
                            </div>
                        </div>
                    </FormProvider>
                </div>
            </Container>
        </div>
    );
}
