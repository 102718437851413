import React from "react";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import MenuItem from "@material-ui/core/MenuItem";

export const opItems = [
    { value: "gt", label: "plus que" },
    { value: "gte", label: "plus ou égale à" },
    { value: "eq", label: "égale à" },
    { value: "ne", label: "pas égale à" },
    { value: "lt", label: "moins que" },
    { value: "lte", label: "moins ou égale à" },
    { value: "minMax", label: "compris entre" },
];

export type TOpItems = "gt" | "gte" | "eq" | "ne" | "lt" | "lte" | "minMax" | undefined;

type Props = {
    value: TOpItems;
    onChange: (item: TOpItems) => void;
};

export function OpPicker(props: Props) {
    return (
        <UiSelect
            style={{ minWidth: 120 }}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value as TOpItems)}
        >
            {opItems.map((op) => (
                <MenuItem value={op.value} key={op.value}>
                    {op.label}
                </MenuItem>
            ))}
        </UiSelect>
    );
}
