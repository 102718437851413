import React from "react";
import { TOrderListingMdl } from "orders/_models/OrderMdl";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/orderListRow.module.css";
import { OrderStatus } from "orders/OrderStatus";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import { MessengerNotification } from "messages/messenger/MessengerNotification";
import { messengerStore } from "messages/messenger/_stores/messengerStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { TBrandMdl } from "admin/brands/_models/BrandMdl";
import { brandsStore } from "admin/brands/_stores/brandsStore";
import { OrderAdminModelName } from "orders/admin/OrderAdminModelName";

type Props = {
    order: TOrderListingMdl;
};

export const OrderListRow = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { loading } = useLoadingFromPromise<TBrandMdl | undefined>(brandsStore.getBrandForPartner(props.order.brand));
    if (!props.order._id || !loading) return null;

    return (
        <div
            className={clsx("flex_center_center mv_10 p_20", styles.container)}
            onClick={() => history.push(URLS.order(props.order._id))}
        >
            <div
                onClick={() => history.push(URLS.order(props.order._id))}
                className={clsx("flex-1 text_small_semiBold", styles.column)}
            >
                <LoadableFromLoading
                    loading={loading}
                    renderer={(_status, error, brand) => {
                        if (error || !brand) return null;
                        return <OrderAdminModelName brand={brand} model={props.order.model} />;
                    }}
                />
            </div>
            <div className={"flex-2 flex_row flexWrap_wrap"}>
                {!!props.order.tuningType && (
                    <div className={clsx("mr_10", styles.column)}>
                        <div className={"text_small_semiBold"}>
                            {t("order.reprog.tuningTypes." + props.order.tuningType)} (ECU)
                        </div>
                        {!!props.order?.tuningOptions?.length && props.order?.tuningOptions?.length > 0 && (
                            <div className={clsx("flex_row text_small lineHeight_1-1", styles.options)}>
                                {props.order.tuningOptions
                                    .map((option) => t("order.reprog.option." + option))
                                    .join(", ")}
                            </div>
                        )}
                    </div>
                )}
                {!!props.order?.tcu?.tuningType && (
                    <div className={clsx("mr_10", styles.column)}>
                        <div className={"text_small_semiBold"}>
                            {t("order.reprog.tuningTypes." + props.order.tcu.tuningType)} (TCU)
                        </div>
                        {!!props.order.tcu?.tuningOptions?.length && props.order.tcu.tuningOptions.length > 0 && (
                            <div className={"flex_row text_small lineHeight_1-1"}>
                                {props.order.tcu.tuningOptions
                                    .map((option) => t("order.reprog.option." + option))
                                    .join(", ")}
                            </div>
                        )}
                    </div>
                )}
                {!!props.order.maintenance?.maintenance && (
                    <div className={clsx("mr_10", styles.column)}>
                        <div className={"text_small_semiBold"}>{t("order.maintenance.maintenance")}</div>
                    </div>
                )}
            </div>
            <div className={clsx("flex-1 text_small_semiBold", styles.column)}>#{props.order.vin}</div>
            <div className={clsx("flex-1 flex_center_center", styles.column)}>
                <OrderStatus status={props.order.status} />
            </div>
            <div onClick={() => history.push(URLS.order(props.order._id))} className={clsx("flex-1", styles.column)}>
                {props.order.createdAt.format(t("dates.formats.dayAndHour"))}
            </div>
            <div className={clsx("flex_row_center justifyContent_flexEnd ml_10", styles.column)}>
                <LoadableFromLoading
                    loading={messengerStore.list()}
                    renderer={(_status, error) => {
                        if (messengerStore.conversations[props.order._id] === undefined || error) {
                            return null;
                        }
                        return <MessengerNotification orderId={props.order._id} />;
                    }}
                />
            </div>
        </div>
    );
};
