import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ordersAdminStore } from "orders/_stores/ordersAdminStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { InvoicesListPage } from "invoices/InvoicesListPage";
import dayjs from "dayjs";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { observer } from "mobx-react";
import { Container } from "@material-ui/core";
import { InvoicesTotalTable } from "invoices/InvoicesTotalTable";

export const InvoicesPage = observer(() => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(dayjs().startOf("month").startOf("day"));
    const [endDate, setEndDate] = useState(dayjs().endOf("month").endOf("day"));
    useEffect(() => {
        ordersAdminStore.fetchInvoicesByPeriod(startDate, endDate);
    }, [endDate, startDate, ordersAdminStore.invoices]);

    return (
        <Container className="mb_40">
            <LoadableFromLoading
                loading={ordersAdminStore.invoicesState}
                renderer={(_status, error) => {
                    if (error) return <div>{t("homePage.text")}</div>;
                    return (
                        <div>
                            <div className={"flex_row justifyContent_spaceBetween"}>
                                <UiButton
                                    className={"mb_20 ml_10"}
                                    style={{ backgroundColor: "var(--color-primary" }}
                                    onClick={() => {
                                        setStartDate(startDate.subtract(1, "month"));
                                        setEndDate(endDate.subtract(1, "month"));
                                    }}
                                >
                                    {"<-"}
                                </UiButton>
                                <h2 className={"ml_20 mb_10"}>
                                    {t(`dates.monthList.${dayjs(startDate).format("MMMM")}`) +
                                        "-" +
                                        dayjs(startDate).format("YYYY")}
                                </h2>

                                <UiButton
                                    className={"mb_20 ml_10"}
                                    style={{ backgroundColor: "var(--color-primary" }}
                                    onClick={() => {
                                        setStartDate(startDate.add(1, "month"));
                                        setEndDate(endDate.add(1, "month"));
                                    }}
                                    disabled={dayjs().isBefore(endDate)}
                                >
                                    {"->"}
                                </UiButton>
                            </div>

                            <div className={"flex_center_center mt_20 mb_40"}>
                                <InvoicesTotalTable />
                            </div>
                            <InvoicesListPage />
                        </div>
                    );
                }}
            />
        </Container>
    );
});
