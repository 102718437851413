import React from "react";
import styles from "./_css/toggle.module.css";
import clsx from "clsx";

type Props = {
    value: boolean;
    label?: string;
    onChange: (value: boolean) => void;
};

export function Toggle(props: Props) {
    return (
        <div
            className={clsx("flex_row_center unselectable", styles.toggleBlock, {
                [styles.toggleBlock_active]: props.value,
            })}
            onClick={() => props.onChange(!props.value)}
        >
            <div className={styles.toggle}>
                <div className={styles["toggle-handle"]} />
                <div className={styles["toggle-label"]}>{props.value ? "ON" : "OFF"}</div>
            </div>
            {props.label}
        </div>
    );
}
