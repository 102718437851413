import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { IPageMdl, IPageShortMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import {
    getInitialStateValue,
    putPromiseResourceResultInInitialState,
    putPromiseResultInInitialState,
} from "_common/_utils/initialStateUtils";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import dayjs from "dayjs";
import { TFilter } from "admin/_common/filters/TFilter";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import i18next from "i18next";

class PagesStore extends BaseResourceStore<IPageMdl> {
    private featuredPagesLoadingState: LoadingStateMdl<IPageShortMdl[]> | undefined;
    private latestArticlesLoadingState: LoadingStateMdl<IPageShortMdl[]> | undefined;

    constructor() {
        super("pages");
        if (__BROWSER__) this.onInit();
    }

    getByUrl(path: string, lang: TLang) {
        const page = this.getByUrlSync(path, lang);
        if (page) return page;
        const pagePromise = fetchUtils
            .get<IPageMdl>(`${this.apiPath}/published?path=${path}&lang=${lang}`)
            .then(({ data }) => this.addItem(data));
        putPromiseResourceResultInInitialState(this.name, pagePromise);
        return pagePromise;
    }

    getByUrlSync(path: string, lang: TLang) {
        const pagePath = path.substr(sharedConfig.languages[lang].basePath.length);
        return this.findOneSync(`localized.${lang}.url`, pagePath);
    }

    getFeaturedPages() {
        if (!this.featuredPagesLoadingState) {
            this.featuredPagesLoadingState = new LoadingStateMdl();
        }
        return this.loadShortPages("pagesStore.featured", `${this.apiPath}/featured`, this.featuredPagesLoadingState);
    }

    getLatestArticles(limit?: number) {
        if (!this.latestArticlesLoadingState) {
            this.latestArticlesLoadingState = new LoadingStateMdl();
        }
        return this.loadShortPages(
            "pagesStore.latestArticles",
            `
            ${this.apiPath}/latestArticles?limit=${limit ?? 10}&lang=${i18next.language}`,
            this.latestArticlesLoadingState,
        );
    }

    list(offset = 0, limit?: number, listId?: string, sort?: { [key: string]: number }, filters?: TFilter[]) {
        if (!filters) filters = [];
        if (listId === "pagesList") {
            filters = [...filters, { type: TFilterType.STRING, id: "type", value: PAGE_TYPE.NORMAL }];
        } else if (listId === "articlesList") {
            filters = [
                ...filters,
                {
                    type: TFilterType.STRING,
                    id: "type",
                    value: PAGE_TYPE.ARTICLE,
                },
            ];
        }
        return super.list(offset, limit, listId, sort, filters);
    }

    protected reformatItem(item: IPageMdl) {
        const formattedItem = super.reformatItem(item);
        if ((item as any).localized.publishedAt) {
            (formattedItem as any).publishedAt = dayjs((formattedItem as any).localized.publishedAt);
        }
        return formattedItem;
    }

    protected onReset() {
        super.onReset();
        this.featuredPagesLoadingState = undefined;
        this.latestArticlesLoadingState = undefined;
    }

    protected onInit(fromRootCtor?: boolean) {
        if (fromRootCtor) return;
        super.onInit();
        const featuredPages = getInitialStateValue<IPageMdl[]>("pagesStore.featured");
        if (featuredPages) {
            this.featuredPagesLoadingState = new LoadingStateMdl<IPageShortMdl[]>();
            this.featuredPagesLoadingState.setSuccess(featuredPages.map((page) => this.reformatItem(page)));
        }

        const latestArticles = getInitialStateValue<IPageMdl[]>("pagesStore.latestArticles");
        if (latestArticles) {
            this.latestArticlesLoadingState = new LoadingStateMdl<IPageShortMdl[]>();
            this.latestArticlesLoadingState.setSuccess(latestArticles.map((page) => this.reformatItem(page)));
        }
    }

    protected duplicateItem(item: Omit<IPageMdl, "_id">) {
        const localized = { ...item.localized };
        Object.values(localized).map((localizedPage) => {
            if (localizedPage) {
                localizedPage.url = localizedPage.url + Math.round(Math.random() * 1000);
            }
        });
        item = { ...item, localized };
        return super.duplicateItem(item);
    }

    private loadShortPages(storageKey: string, apiPath: string, loadingState: LoadingStateMdl<IPageShortMdl[]>) {
        if (loadingState.status === "IDLE") {
            const promise = fetchUtils.get<IPageShortMdl[]>(apiPath).then(
                ({ data }) => {
                    const result = data.map((page) => this.reformatItem(page as IPageMdl));
                    loadingState?.setSuccess(result);
                    return result;
                },
                (error) => {
                    loadingState.setError(error);
                    return [];
                },
            );
            loadingState.startLoading(promise);
            putPromiseResultInInitialState(storageKey, promise);
        }
        return loadingState;
    }
}

const pagesStore = new PagesStore();
export { pagesStore };
