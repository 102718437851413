import React from "react";
import clsx from "clsx";
import styles from "./_css/label.module.css";

type Props = {
    children: string;
    color?: string;
    className?: string;
};

export function Label(props: Props) {
    return (
        <div className={clsx("flex_center_center", styles.default, props.className)}>
            <div style={{ color: props.color }}>{props.children}</div>
        </div>
    );
}
